import React, { useContext } from 'react';
import { AppContext } from '../../../App';

function DeleteModal() {

    const { toggle, dispatch } = useContext(AppContext);

    const func = toggle.displayDeleteModal.func;
    const id = toggle.displayDeleteModal.id;
    let message;

    if (toggle.displayDeleteModal.message) {
        message = toggle.displayDeleteModal.message;
    }

    return (
        <div className="modal__fullscreen-container">
            <div className="deletemodal">
                <h2 className="deletemodal__h2-heading">Are You Sure You Want To Delete?<br />{ message ? <small className="deleteModal__subTitle">{message}</small> : ""}</h2>
                <div className="deletemodal__button-container">
                    <button className="btn--confirm" onClick={() => {func(id); dispatch({type: "displayDeleteModal", data: {open: false}}) }}>Yes</button>
                    <button className="btn--danger" onClick={() => dispatch({type: "displayDeleteModal", data: {open: false}})}>No</button>
                </div>
            </div>
        </div>
    );
}

export default DeleteModal;