import React, { useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { AppContext } from '../App';

function ProtectedRoute({ path, isAdminPage = false, component, exact = true}) {
    const { accountData } = useContext(AppContext);
    return (
        <Route path={path} exact={exact} render={(props) => {
            // To pull off this stroke of genius we are using
            // React.cloneElement(component, props, children)
            // https://reactjs.org/docs/react-api.html#cloneelement
            // It is allowing us to add props to a component that is passed
            // as a reference variable
            if (accountData.isLoggedIn) {
                if (accountData.account_type === 'admin' && isAdminPage) {
                    return <>{React.cloneElement(component, { ...props })}</>;
                }
                if (!isAdminPage) {
                    return <>{React.cloneElement(component, { ...props })}</>;
                }
                return <Redirect to="/login" />
            } else {
                return <Redirect to="/login" />
            }
        }
        } />
    );
};

export default ProtectedRoute;