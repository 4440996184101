let State = {
  accountData: {
    isLoggedIn: false
    // account_company: "Admin FRM",
    // account_created: "2019-09-06 14:44:54",
    // account_email: "admin@frm.com",
    // account_id: "20",
    // account_phone: "208-123-4567",
    // account_type: "admin",
    // api_token: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjoiMjAiLCJleHAiOjE1Njk4NTQwOTIsImlzcyI6ImZsYXRyYXRlbWV0YWwiLCJpYXQiOjE1NjkyNDkyOTJ9.izXZc-s4TTHjasskC5ZPUVS2oD8Vw7APhZ3MvwgqSW0"
  },
  addresses: [],
  products: [],
  materials: [],
  categories: [],
  toggle: {
    notification: { open: false },
    displayDeleteModal: { open: false },
    displayConfirmModal: { open: false }
  },
  account: [],
  currentAccount: {
    currentAccountEditing: false
  },
  currentProduct: {
    product_name: "",
    product_dimension: "",
    product_type: "",
    product_description: "",
    product_bundle_count: "",
    product_pallet_count: "",
    product_id: "",
    product_variants: [],
    material_id: "",
    category_id: ""
  },
  currentProductVariant: {
    product_variant_retail_price: "",
    product_variant_contractor_price: "",
    product_variant_dealer_price: "",
    product_variant_weight: "",
    product_variant_thickness: "",
    product_variant_id: "",
    color_id: "",
    color_hex: "",
    color_name: "",
    product_id: "",
    images: []
  },
  cart: [],
  cartProducts: [],
  lastPurchase: [],
  order: {
    address_id: null,
    order_delivered: null,
    order_type: null,
    order_po: null,
    order_feedback: null,
    order_delivery_date: null,
    order_pickup_date: new Date(),
    order_submitted: new Date()
  },
  currentSpecialPrice: {
    account_has_product_id: null,
    account_has_product_price: null,
    product_name: null
  }
};

export default State;
