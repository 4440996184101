import React, { useState, useEffect, useContext } from "react";
import Service from "../../../../services/service";
import { Link } from "react-router-dom";
import HomeCategoriesItem from "./HomeCategoriesItem";
import PlaceholderImage from "../../../../images/image-placeholder.jpg";
import { AppContext } from "../../../../App";
import CacheManager from "../../../../services/cacheManager";

function HomeCategories() {
  const { categories, dispatch } = useContext(AppContext);
  // const [categories, setCategories] = useState([]);

  useEffect(() => {
    CacheManager.cacheCheck(
      "categories",
      categories,
      "updateCategories",
      dispatch,
      "?controller=category&action=getAll"
    );
  }, []);

  return (
    <>
      <div className="product-carousel__title-cont">
        <h2 className="product-carousel__title">Featured Categories</h2>
      </div>
      <div className="product-carousel">
        {categories.map((category) => {
          const getImage = () => {
            try {
              const variant = category.products.product_variants[0];
              const images = variant.images;
              const image = images[0];
              if (!category.products || !variant || !image) {
                return PlaceholderImage;
              } else {
                return image.image_path;
              }
            } catch {
              return PlaceholderImage;
            }
          };
          const image = getImage();
          return (
            <HomeCategoriesItem
              categoryName={category.category_name}
              image={image}
              key={category.category_id}
            />
          );
        })}
        <Link className="product-carousel__card all-products" to="/products">
          <h3 className="product-carousel__card-title all-products-title">
            All Products
          </h3>
          <p className="product-carousel__card-link all-products-link">
            See more
            <i className="fas fa-angle-right product-carousel__card-icon"></i>
          </p>
        </Link>
      </div>
    </>
  );
}

export default HomeCategories;
