import Service from "./service";

function stateReducer(state, action) {
  switch (action.type) {
    case "updateAccountData":
      return { ...state, accountData: action.data };
    case "updateCurrentProduct":
      return { ...state, currentProduct: action.data };
    case "clearCurrentProduct":
      return {
        ...state,
        currentProduct: {
          product_name: "",
          product_dimension: "",
          product_type: "",
          product_description: "",
          product_bundle_count: "",
          product_pallet_count: "",
          product_id: "",
          product_variants: [],
          material_id: "",
          category_id: ""
        }
      };
    case "updateCurrentSpecialPricing":
      return { ...state, currentSpecialPrice: action.data };
    case "updateCurrentAccount":
      return { ...state, currentAccount: action.data };
    case "updateCurrentProductVariant":
      return { ...state, currentProductVariant: action.data };
    case "clearCurrentProductVariant":
      return {
        ...state,
        currentProductVariant: {
          product_variant_retail_price: "",
          product_variant_contractor_price: "",
          product_variant_dealer_price: "",
          product_variant_weight: "",
          product_variant_thickness: "",
          product_variant_id: "",
          color_id: "",
          color_hex: "",
          color_name: "",
          product_id: "",
          images: []
        }
      };
    case "updateNotification":
      return {
        ...state,
        toggle: {
          ...state.toggle,
          notification: {
            open: action.data.open,
            status: action.data.status,
            message: Service.htmlDecode(action.data.message)
          }
        }
      };
    case "displayDeleteModal":
      return {
        ...state,
        toggle: {
          ...state.toggle,
          displayDeleteModal: action.data
        }
      };
    case "displayConfirmModal":
      return {
        ...state,
        toggle: {
          ...state.toggle,
          displayConfirmModal: action.data
        }
      };
    case "updateCart":
      return { ...state, cart: action.data };
    case "updateCartProducts":
      return { ...state, cartProducts: action.data };
    case "updateOrder":
      return { ...state, order: action.data };
    case "clearOrder":
      return {
        ...state,
        order: {
          address_id: null,
          order_delivered: null,
          order_type: null,
          order_po: null,
          order_feedback: null,
          order_delivery_date: null,
          order_pickup_date: null,
          order_submitted: null
        }
      };
    case "createConfirmation":
      return { ...state, purchase: action.data };
    case "updateImage":
      return { ...state, image: action.data };
    case "updateCategories":
      return { ...state, categories: action.data };
    case "updateProducts":
      return { ...state, products: action.data };
    case "updateAddresses":
      return { ...state, addresses: action.data };
    default:
      throw new Error();
  }
}

export default stateReducer;
