import React, { useState, useEffect, useContext } from "react";
import Service from "../../../services/service";
import { AppContext } from "../../../App";
import { numCommas } from "../../../services/dates";
import Loading from "../../reusable/notification/Loading";

function AdminOrders() {
  const [orders, setOrders] = useState([]);
  const { accountData, dispatch } = useContext(AppContext);
  const [showLoading, setShowLoading] = useState(true);

  useEffect(() => {
    getAllOrders();
    const interval = setInterval(() => {
      getAllOrders();
    }, 300000);
    return () => clearInterval(interval);
  }, []);

  function getAllOrders() {
    Service.get(
      "?controller=order&action=getAll&api_token=" + accountData.api_token,
      (res) => {
        if (res.status === "success") {
          setShowLoading(false);
          setOrders(
            res.data.sort((a, b) =>
              parseInt(a.order_id) < parseInt(b.order_id) ? 1 : -1
            )
          );
        } else {
          console.log("Failed");
        }
      }
    );
  }

  function sendOrderToState(id) {
    console.log("Sent order to state! The id was " + id);
  }

  function updateStatus(id, status) {
    Service.put(
      "order",
      "updateOrderStatus",
      {
        order_id: id,
        order_status: status,
        api_token: accountData.api_token,
      },
      (res) => {
        dispatch({
          type: "updateNotification",
          data: { open: true, message: res.message, status: res.status },
        });
      }
    );
    getAllOrders();
  }

  return (
    <div className="admin-orders col--12 col--lrg--10">
      <h1>ORDERS</h1>
      <div className="admin-orders__table-title">
        <section className="admin-orders__section-title">
          <h3 className="admin-orders__table-titles">Order #</h3>
        </section>
        <section className="admin-orders__section-title">
          <h3 className="admin-orders__table-titles">Date</h3>
        </section>
        <section className="admin-orders__section-title">
          <h3 className="admin-orders__table-titles">Customer</h3>
        </section>
        <section className="admin-orders__section-title">
          <h3 className="admin-orders__table-titles">Order Type</h3>
        </section>
        <section className="admin-orders__section-title">
          <h3 className="admin-orders__table-titles">Product(s)</h3>
        </section>
        <section className="admin-orders__section-title">
          <h3 className="admin-orders__table-titles">Status</h3>
        </section>
        <section className="admin-orders__section-title">
          <h3 className="admin-orders__table-titles">Total</h3>
        </section>
        <section className="admin-orders__section-title">
          <h3 className="admin-orders__table-titles">Actions</h3>
        </section>
      </div>
      {showLoading ? <Loading /> : ""}

      {showLoading ? (
        ""
      ) : (
        <div className="admin-orders__order-list">
          {orders.map((order) => (
            <div
              className={
                order.order_status === "placed"
                  ? "admin-orders__table-new"
                  : "admin-orders__table"
              }
            >
              <section className="admin-orders__section">
                <p className="admin-orders__detail-order">{order.order_id}</p>
              </section>
              <section className="admin-orders__section">
                <p className="admin-orders__detail">
                  {new Date(order.order_submitted).toISOString().split("T")[0]}
                </p>
              </section>
              <section className="admin-orders__section">
                <p className="admin-orders__detail-customer">
                  {order.account_company}
                </p>
              </section>
              <section className="admin-orders__section">
                <p className="admin-orders__detail">{order.order_type}</p>
              </section>
              <section className="admin-orders__section">
                <p className="admin-orders__detail">
                  {order.order_product_count}
                </p>
              </section>
              <section className="admin-orders__section">
                <select
                  defaultValue={order.order_status}
                  className="admin-orders__detail input"
                  onChange={(e) => updateStatus(order.order_id, e.target.value)}
                >
                  <option value="placed">Placed</option>
                  <option value="filled">Filled</option>
                  <option value="shipped">Shipped</option>
                  <option value="returned">Returned</option>
                  <option value="cancelled">Cancelled</option>
                  <option value="complete/unpaid">Complete/Unpaid</option>
                  <option value="complete">Complete</option>
                </select>
              </section>
              <section className="admin-orders__section">
                <p className="admin-orders__detail">
                  ${numCommas(parseFloat(order.order_total).toFixed(2))}
                </p>
              </section>
              <section className="admin-orders__section">
                <button
                  className="admin-orders__action-btn btn--confirm"
                  onClick={() => sendOrderToState(order.order_id)}
                >
                  View
                </button>
              </section>
            </div>
          ))}
        </div>
      )}

      {showLoading ? (
        ""
      ) : (
        <div className="admin-orders__order-list">
          {orders.map((order) => (
            <div className="admin-orders__table-mobile">
              <section className="admin-orders__section-mobile-title">
                <label className="admin-orders__section-mobile-label label">
                  Order #
                </label>
                <p className="admin-orders__detail-mobile">{order.order_id}</p>
              </section>
              <section className="admin-orders__section-mobile">
                <label className="admin-orders__section-mobile-label label">
                  Date
                </label>
                <p className="admin-orders__detail-mobile">
                  {new Date(order.order_submitted).toISOString().split("T")[0]}
                </p>
              </section>
              <section className="admin-orders__section-mobile">
                <label className="admin-orders__section-mobile-label label">
                  Customer
                </label>
                <p className="admin-orders__detail-mobile-customer">
                  {order.account_company}
                </p>
              </section>
              <section className="admin-orders__section-mobile">
                <label className="admin-orders__section-mobile-label label">
                  Order Type
                </label>
                <p className="admin-orders__detail-mobile">
                  {order.order_type}
                </p>
              </section>
              <section className="admin-orders__section-mobile">
                <label className="admin-orders__section-mobile-label label">
                  Products
                </label>
                <p className="admin-orders__detail-mobile">5</p>
              </section>
              <section className="admin-orders__section-mobile">
                <label className="admin-orders__section-mobile-label label">
                  Status
                </label>
                <p className="admin-orders__detail-mobile">
                  {order.order_status}
                </p>
              </section>
              <section className="admin-orders__section-mobile">
                <label className="admin-orders__section-mobile-label label">
                  Total
                </label>
                <p className="admin-orders__detail-mobile">
                  ${numCommas(parseFloat(order.order_total).toFixed(2))}
                </p>
              </section>
              <section className="admin-orders__section-mobile">
                <button
                  className="admin-orders__action-btn-mobile btn--confirm"
                  onClick={() => sendOrderToState(order.order_id)}
                >
                  View
                </button>
              </section>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default AdminOrders;
