import React, { useState, useEffect } from "react";
import Service from "../../../../services/service";
import ProductsNavItem from "./ProductsNavItem";
import { NavLink } from "react-router-dom";
import PlaceholderPath from "../../../../images/image-placeholder.jpg";
import CacheManager from "../../../../services/cacheManager";
import { useContext } from "react";
import { AppContext } from "../../../../App";

function ProductsNav() {
  const { categories, dispatch } = useContext(AppContext);
  useEffect(() => {
    CacheManager.cacheCheck(
      "categories",
      categories,
      "updateCategories",
      dispatch,
      "?controller=category&action=getAll"
    );
  }, []);

  return (
    <div className="products-nav">
      <div className="products-nav__container">
        <NavLink
          className="products-nav__item"
          activeClassName="active__link"
          exact={true}
          to={"/products/"}
        >
          All Products
        </NavLink>
        {categories.map(cat => (
          <NavLink
            key={cat.category_id}
            className="products-nav__item"
            activeClassName="active__link"
            exact={true}
            to={"/products/" + cat.category_name}
          >
            {cat.category_name}
          </NavLink>
        ))}
      </div>
    </div>
  );
}

export default ProductsNav;
