import React, { useState, useEffect, useContext } from 'react';
import Service from '../../../../../../services/service';
import { AppContext } from '../../../../../../App';
import ProductCategoryGroup from '../../../../products/childComp/ProductCategoryGroup';


function AdminAccountSpecialPricingCard({ setToggleModal, specialPrice, deleteSpecialPrice, setIsEditing }) {

    const [productName, setProductName] = useState();
    const {dispatch, accountData} = useContext(AppContext);
    const [product, setProduct] = useState({});

    useEffect(() => {
        getProductByVariantId(specialPrice.product_variant_id);
        
    }, []);

    function getProductByVariantId(id) {
        Service.get("?controller=product&action=getProductByVariantId&product_variant_id=" + id + "&api_token=" + accountData.api_token,
        (res) => {
            if (res.status === "success") {
                setProduct(res.data);
            }
        })
    }

    function sendCurrentSpecialPrice() {
        dispatch({type: "updateCurrentSpecialPricing", data:{
            account_has_product_id: specialPrice.account_has_product_id,
            account_has_product_price: specialPrice.account_has_product_price,
            product_variant_id: specialPrice.product_variant_id
        }})
    }

    function numCommas(x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    return (
        <div className="admin-account-special-pricing-card">
            <h4 className="admin-account-special-pricing-card__h4-product-name">
                {product.product_name} {product.material_name} {Service.htmlDecode(product.product_dimension)} {Service.htmlDecode(product.product_type)} {product.color_name} {product.product_variant_thickness} 
            </h4>
            <p className="admin-account-special-pricing-card__p-price">
                ${numCommas(parseFloat(specialPrice.account_has_product_price).toFixed(2))}
            </p>
            <button className="admin-account-special-pricing-card__button btn--confirm" onClick={() => {setToggleModal(true); setIsEditing(true); sendCurrentSpecialPrice()}}>Edit</button>
            <button className="admin-account-special-pricing-card__button btn--danger" onClick={() => deleteSpecialPrice(specialPrice.account_has_product_id)}>Delete</button>
        </div>
    );
}


export default AdminAccountSpecialPricingCard;