import React, { useEffect, useState, useContext } from "react";
import AccountDetailEdit from "./childComp/AccountDetailEdit";
import AccountAddLocation from "./childComp/AccountAddLocation";
import AccountLocationList from "./childComp/AccountLocationList";
import Service from "../../../services/service";
import { AppContext } from "../../../App";
import { Helmet } from "react-helmet";
import CacheManager from "../../../services/cacheManager";

function AccountSettings({ location }) {
  const { accountData, addresses, dispatch } = useContext(AppContext);
  useEffect(() => {
    document.title = "Account Settings";
    CacheManager.cacheCheck(
      "addresses",
      addresses,
      "updateAddresses",
      dispatch,
      "?controller=address&action=getAll&account_id=" +
        accountData.account_id +
        "&api_token=" +
        accountData.api_token
    );
  }, []);

  return (
    <main className="account-settings">
      <Helmet>
        <title>Account Settings | Flat Rate Metal</title>
      </Helmet>
      <div className="banner__container">
        <h1>ACCOUNT SETTINGS</h1>
      </div>
      <div className="account-settings__body-cont">
        <section className="account-settings__detail-location-cont">
          <AccountDetailEdit />
          <AccountAddLocation />
        </section>
        <section className="account-settings__location-cont">
          <AccountLocationList addresses={addresses} />
        </section>
      </div>
    </main>
  );
}

export default AccountSettings;
