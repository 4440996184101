import React, { useContext, useState, useEffect } from "react";
import Service from "../../../../services/service";
import placeholderImg from "../../../../images/image-placeholder.jpg";
import { AppContext } from "../../../../App";
import { Link } from "react-router-dom";

function ProductCard({ product, categories }) {
  const { accountData } = useContext(AppContext);
  const [lowestCost, setLowestCost] = useState();
  const [highestCost, setHighestCost] = useState();

  useEffect(() => {
    let priceArr = [];

    for (let i = 0; i < product.product_variants.length; i++) {
      priceArr.push(product.product_variants[i].product_variant_retail_price);
    }
    priceArr.sort((price1, price2) => {
      if (price1 > price2) {
        return 1;
      }
      if (price1 < price2) {
        return -1;
      }
      return 0;
    });
    setLowestCost(priceArr[0]);
    setHighestCost(priceArr[priceArr.length - 1]);
  }, [product]);

  function numCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  const getImage = () => {
    try {
      return product.product_variants[0].images[0].image_path
        ? Service.src + product.product_variants[0].images[0].image_path
        : placeholderImg;
    } catch {
      return placeholderImg;
    }
  };

  return (
    <div className="pc">
      <Link className="pc-body" to={"/product/" + product.product_address}>
        <div className="pc-img__cont">
          <img src={getImage()} alt="drip edge" className="pc-img" />
          {/* {product.product_type ? <div className="pc-type">{Service.htmlDecode(product.product_type)}</div> : ''} */}
        </div>
        <div className="pc-info">
          <div className="pc-detail">
            <h3 className="pc-name">{product.product_name} </h3>
            <div className="pc-material">{product.material_name}</div>
            {/* <div className="pc-price">{lowestCost === highestCost
                            ?
                            "$" + lowestCost + " / " + product.bundle_name
                            :
                            "$" + lowestCost + " - $" + highestCost + " / " + product.bundle_name
                        }</div> */}
            <div className="pc-dimension">
              {Service.htmlDecode(product.product_type)}{" "}
              {Service.htmlDecode(product.product_dimension)}
            </div>
          </div>
        </div>
      </Link>
      {accountData.isLoggedIn ? <ProductCardFooter product={product} /> : ""}
    </div>
  );
}
export default ProductCard;

function ProductCardFooter({ product }) {
  const [productVariantId, setProductVariantId] = useState(0);
  const { dispatch, accountData, cart } = useContext(AppContext);

  const [qty, setQty] = useState(1);
  const [select, setSelect] = useState(true);

  useEffect(() => {
    setProductVariantId(product.product_variants[0].product_variant_id);
  }, []);

  function updateQty(command) {
    let newQty;
    if (command === "increment") {
      newQty = qty + 1;
      setQty(newQty);
    } else if (command === "decrement" && qty > 1) {
      newQty = qty - 1;
      setQty(newQty);
    }
  }

  function manualQty(e) {
    let manualQty = parseInt(e.target.value);
    if (!manualQty) manualQty = 1;
    setQty(manualQty);
  }

  function storeItemInCart() {
    let stock = true;
    product.product_variants.forEach((variant) => {
      if (variant.product_variant_id === productVariantId) {
        if (variant.product_variant_stock === "out") {
          dispatch({
            type: "updateNotification",
            data: {
              open: true,
              status: "failure",
              message: "Product Out of Stock",
            },
          });
          stock = false;
        } else {
          stock = true;
        }
      }
    });
    if (!stock) {
      return;
    }
    Service.post(
      "cart",
      "addProduct",
      {
        product_variant_id: productVariantId,
        account_id: accountData.account_id,
        cart_product_quantity: qty,
        api_token: accountData.api_token,
      },
      (res) => {
        dispatch({
          type: "updateNotification",
          data: { open: true, status: res.status, message: res.message },
        });
        if (res.status === "success") {
          setQty(1);
          if (res.data.id) {
            dispatch({ type: "updateCart", data: [...cart, res.data.id] });
          }
        } else {
          dispatch({
            type: "updateNotification",
            data: {
              open: true,
              status: "failure",
              message: "Not Added to Cart",
            },
          });
        }
      }
    );
  }

  function isSelect(e) {
    if (e === "select") {
      setSelect(true);
    } else {
      setSelect(false);
    }
  }

  return (
    <footer className="pc-footer">
      <select
        className="input pc-footer__select full"
        title={`Select a ${product.product_name} variant.`}
        onChange={(e) => {
          setProductVariantId(e.target.value);
          isSelect(e.target.value);
        }}
      >
        <option value="select">- Select -</option>
        {product.product_variants.map((variant) => (
          <option
            key={variant.product_variant_id}
            value={variant.product_variant_id}
          >
            {variant.color_name && variant.product_variant_stock == "out"
              ? "(Out of stock) " + variant.color_name
              : variant.color_name}
            {variant.product_variant_thickness
              ? " " + variant.product_variant_thickness
              : ""}
          </option>
        ))}
      </select>
      <div className="product-card__qty-container">
        <div
          className="product-card__qty-button"
          onClick={() => updateQty("decrement")}
        >
          <i className="fas fa-minus product__qty-icon"></i>
        </div>
        <input
          className="input product-card__qty-input"
          onClick={(e) => Service.selectInputContents(e.target)}
          type="text"
          min="0"
          value={qty}
          required
          onChange={manualQty}
        />
        <div
          className="product-card__qty-button"
          onClick={() => updateQty("increment")}
        >
          <i className="fas fa-plus product__qty-icon"></i>
        </div>

        <button
          title={`Add ${product.product_name} to the cart.`}
          className="pc-footer__add btn--silver"
          onClick={storeItemInCart}
          type="button"
          disabled={select}
        >
          Add&nbsp;
          <span className="product-card__qty-type">
            {product.bundle_name}(s)
          </span>
        </button>
        {/* <span>{product.bundle_name + "(s)"}</span> */}
      </div>
      {/* <div className="product-card__bundle-name">
        Sold by{" "}
        {product.product_bundle_count > 1
          ? product.bundle_name + " of "
          : product.bundle_name}{" "}
        {product.product_bundle_count <= 1 ? "" : product.product_bundle_count}
      </div> */}
    </footer>
  );
}
