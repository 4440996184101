import React, { useContext, useEffect, useRef } from "react";
import ProductDetailOptions from "./ProductDetailOptions";
import { AppContext } from "../../../../App";
import Service from "../../../../services/service";
import ProductDetailAddToCart from "./ProductDetailAddToCart";

function ProductDetailDescription() {
  const { currentProduct, currentProductVariant, accountData } = useContext(
    AppContext
  );

  function numCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  return (
    <div className="product-detail-description">
      <h1 className="product-detail-description__title show-at-med hide">
        {currentProduct.product_name} {currentProduct.material_name}{" "}
        <span className="product-detail-desciption_title_span">
          {currentProduct.product_dimension}{" "}
          {currentProduct.product_type !== ""
            ? "| " + Service.htmlDecode(currentProduct.product_type)
            : ""}{" "}
          | {currentProductVariant.color_name}{" "}
          {currentProductVariant.product_variant_thickness
            ? "| " + currentProductVariant.product_variant_thickness
            : ""}
        </span>
      </h1>
      <ul className="product-detail-description__list">
        {/* This commented code may be used in the future... please leave it here - Matthew */}
        {/* <li className="product-detail-description__list-item"><div className="product-detail-description__color-block" style={{ backgroundColor: currentProductVariant.color_hex }}></div>{currentProductVariant.color_name}</li> */}

        {currentProductVariant.product_variant_stock === "out" ? (
          <>
            <li className="product-detail-description__list-item list-price crossed">
              <span className="product-detail-msrp">MSRP</span> $
              {numCommas(
                parseFloat(
                  currentProductVariant.product_variant_retail_price
                ).toFixed(2)
              )}
              <span className="product-detail-bundle-name">
                &nbsp;/&nbsp;{currentProduct.bundle_name}{" "}
              </span>
            </li>
            <li
              className="product-detail-description__list-item new-price"
              style={{ color: "red" }}
            >
              {currentProductVariant.product_variant_stock === "out" &&
              currentProductVariant.color_name === "Canyon Clay"
                ? "Product Discontinued"
                : "Out of Stock"}
            </li>
          </>
        ) : (
          <>
            {currentProductVariant.product_variant_special_price ? (
              <>
                <li className="product-detail-description__list-item list-price">
                  <span className="product-detail-msrp"> MSRP</span> $
                  {numCommas(
                    parseFloat(
                      currentProductVariant.product_variant_retail_price
                    ).toFixed(2)
                  )}
                  <span className="product-detail-bundle-name">
                    &nbsp;/&nbsp;{currentProduct.bundle_name}{" "}
                  </span>
                </li>
                <li className="product-detail-description__list-item new-price">
                  $
                  {numCommas(
                    parseFloat(
                      currentProductVariant.product_variant_special_price
                    ).toFixed(2)
                  )}
                  <span className="product-detail-bundle-name">
                    &nbsp;/&nbsp;{currentProduct.bundle_name}{" "}
                  </span>{" "}
                  <span className="product-detail-account-price">
                    Discounted Price
                  </span>
                </li>
              </>
            ) : (
              <>
                {accountData.account_type === "dealer" ||
                accountData.account_type === "contractor" ? (
                  <>
                    <li className="product-detail-description__list-item list-price">
                      <span className="product-detail-msrp">MSRP</span> $
                      {numCommas(
                        parseFloat(
                          currentProductVariant.product_variant_retail_price
                        ).toFixed(2)
                      )}
                      <span className="product-detail-bundle-name">
                        &nbsp;/&nbsp;{currentProduct.bundle_name}{" "}
                      </span>
                    </li>
                    <li className="product-detail-description__list-item new-price">
                      <span
                        className="product-detail-account-price"
                        style={{ textTransform: "capitalize" }}
                      >
                        {accountData.account_type} Cost
                      </span>{" "}
                      $
                      {numCommas(
                        parseFloat(
                          currentProductVariant[
                            "product_variant_" +
                              accountData.account_type +
                              "_price"
                          ]
                        ).toFixed(2)
                      )}
                      <span className="product-detail-bundle-name">
                        &nbsp;/&nbsp;{currentProduct.bundle_name}{" "}
                      </span>
                    </li>
                  </>
                ) : (
                  <li className="product-detail-description__list-item list-price">
                    <span className="product-detail-msrp">MSRP</span> $
                    {currentProductVariant.product_variant_retail_price}
                    <span className="product-detail-bundle-name">
                      &nbsp;/&nbsp;{currentProduct.bundle_name}{" "}
                    </span>
                  </li>
                )}
              </>
            )}
          </>
        )}

        {/* <li className="product-detail-description__list-item">{currentProductVariant.product_variant_thickness}</li> */}
        {/* <li className="product-detail-description__list-item">{currentProductVariant.product_variant_weight}&nbsp;<small>Lbs / {currentProduct.bundle_name}</small></li> */}
      </ul>
      <ProductDetailAddToCart />
      <ProductDetailOptions />
      <div>
        {currentProduct.product_bundle_count != 1 ? (
          <div className="product-detail-bundling">
            <span className="product-detail-bundling-count">
              {currentProduct.product_bundle_count} Pieces
            </span>{" "}
            <span className="product-detail-equals">=</span>
            {currentProduct.bundle_name}
          </div>
        ) : (
          ""
        )}
        {currentProduct.product_pallet_count != "" ? (
          <div className="product-detail-bundling">
            <span className="product-detail-bundling-count">
              {currentProduct.product_pallet_count}&nbsp;
              {currentProduct.bundle_name}(s)
            </span>{" "}
            <span className="product-detail-equals">=</span> <span>Pallet</span>
          </div>
        ) : (
          ""
        )}
      </div>
      <div className="product-detail-description__discription-section">
        <p className="product-detail-description__discription-p" dangerouslySetInnerHTML={{ __html: Service.htmlDecode(currentProduct.product_description) }}></p>
      </div>
    </div>
  );
}

export default ProductDetailDescription;
