import React, { useContext } from "react";
import { AppContext } from "../../../../../../App";
import { numCommas } from "../../../../../../services/dates";

export function CheckoutTotals() {
  const { accountData, order } = useContext(AppContext);
  return (
    <ul className="checkout__totals">
      {order.order_tax > 0 ? (
        <>
          <li>Tax: ${numCommas(parseFloat(order.order_tax).toFixed(2))}</li>
          <li>
            Sub-Total: ${numCommas(parseFloat(order.order_subtotal).toFixed(2))}
          </li>
        </>
      ) : (
        ""
      )}
      <li className="checkout__totals-total">
        Total: ${numCommas(parseFloat(order.order_total).toFixed(2))}
      </li>
      {accountData.account_type === "contractor" ||
      accountData.account_type === "dealer" ? (
        <li>
          Saved:{" "}
          <span className="special">
            ${numCommas(parseFloat(order.order_saved).toFixed(2))}
          </span>
        </li>
      ) : (
        ""
      )}
    </ul>
  );
}
