import React from 'react';
import { Switch, Route } from "react-router-dom";
import ProtectedRoute from "./ProtectedRoute";

//404 Page
import NotFound from "../ui/views/notFound/NotFound";

//ADMIN VIEWS
import AdminPortal from "../ui/views/adminPortal/AdminPortal";

//CLIENT VIEWS
import Products from "../ui/views/products/Products";
import ProductDetail from "../ui/views/products/childComp/ProductDetail";
import Cart from "../ui/views/cart/Cart";
import OrderConfirmation from "../ui/views/orderConfirmation/OrderConfirmation";
import AccountSettings from "../ui/views/accountSettings/AccountSettings";

//TEMP VIEW
import AdminProductVariantDetailsModal from "../ui/views/adminProducts/childComp/AdminProductVariantDetailsModal";

//HOME
import Home from "../ui/views/home/Home";

//LOGIN
import Login from "../ui/views/login/Login";


function Routes() {
  return (
    <>
    {/* This component is intentionally left out of the switch so that it can load on top of other loaded views. */}
      <ProtectedRoute
          isAdminPage={true}
          path="/admin-product-variant-details-modal"
          component={<AdminProductVariantDetailsModal />}
        /> 
        
          <Switch>
            <Route exact={true} path="/" component={Home} />
            <Route path="/login" component={Login} />
            <Route exact={true} path="/products" component={Products} />
            <Route path="/products/:category" component={Products} />
            <Route path="/product/:productAddress" component={ProductDetail} />
            <ProtectedRoute path="/cart" component={<Cart />} />
            <ProtectedRoute
              path="/order-confirmation"
              component={<OrderConfirmation />}
            />
            <ProtectedRoute
              path="/account-settings"
              component={<AccountSettings />}
            />
            <ProtectedRoute
              isAdminPage={true}
              path="/admin-portal"
              exact={false}
              component={<AdminPortal />}
            />
            <Route component={NotFound} />
          </Switch>
           </>                
  );
}

export default Routes;