import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../../../../../../App";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

//Get Holiday date function
import { holidays } from "../../../../../../services/setHolidays";

//Dates functions
import {
  isWeekday,
  checkDate,
  addBusinessDays
} from "../../../../../../services/dates";

export function PickupDeliveryType({ receive, setOrderType }) {
  return (
    <>
      {receive === "pickup" ? (
        <div className="field checkout__pickup-option-cont">
          {checkDate ? (
            <div className="checkout__radial-button-sleeve-pickup">
              <input
                className="input--radio"
                id="immed-btn"
                name="pickupType"
                data-recieve-method="pickup"
                data-pickup-method="immediate"
                type="radio"
                onClick={setOrderType}
              />
              <label
                htmlFor="immed-btn"
                className="checkout__button-label-pickup"
              >
                Immediate
              </label>
            </div>
          ) : (
            ""
          )}

          <div className="checkout__radial-button-sleeve-pickup">
            {/* Scheduled pickup avaliable soon */}
            <input
              className="input--radio"
              id="sched-btn"
              name="pickupType"
              data-recieve-method="pickup"
              data-pickup-method="scheduled"
              type="radio"
              onClick={setOrderType}
            />
            <label
              htmlFor="sched-btn"
              className="checkout__button-label-pickup"
            >
              Scheduled
            </label>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
}

// Pick up was set as Immediate
export function PickUpImmediate({ pickup }) {
  return (
    <>
      {pickup === "immediate" ? (
        <div className="field">
          <p>
            Your order will be available for pickup an hour after your order is
            submitted
          </p>
        </div>
      ) : (
        ""
      )}
    </>
  );
}
// Pick up was set as Scheduled
export function PickUpScheduled({ pickup }) {
  const { dispatch, order } = useContext(AppContext);
  return (
    <>
      {pickup === "scheduled" ? (
        <>
          <div className="field">
            <p>
              *Your order will be available for pickup at 9am on the day you
              selected
            </p>
            <label className="label">Select Date</label>

            <DatePicker
              selected={order.order_pickup_date}
              onChange={date => {
                console.log(date);
                dispatch({
                  type: "updateOrder",
                  data: { ...order, order_pickup_date: date }
                });
              }}
              filterDate={isWeekday}
              placeholderText="Select a weekday"
              minDate={addBusinessDays(new Date(), 1)}
              excludeDates={holidays}
              className="input full"
              required
            />
          </div>
        </>
      ) : (
        ""
      )}
    </>
  );
}
