import React, { useState, useContext, useEffect } from 'react';
import { AppContext } from '../../../App';
import { Redirect } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import Service from '../../../services/service';
import logo from '../../../images/FRM-Logo.png';
import Helmet from 'react-helmet';
import { usePageView } from '../../../services/customHooks';

function Login({ history, location }) {
    let emailFormat = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(.\w{2,3})+$/;
    // React Cookie
    const [cookies, setCookie, removeCookie] = useCookies(["apiToken"]);
    const { dispatch, accountData } = useContext(AppContext);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [emessage, setEmessage] = useState("");
    usePageView(location.pathname);
    
    function validation() {
        if (email === "" || password === "") {
            setEmessage("Please fill in both fields");
            dispatch({ type: 'updateNotification', data: { open: true, status: "failure", message: "Please fill in both fields" } })
            return false;
        } else if (!email.match(emailFormat)) {
            setEmessage("Please enter a valid email");
            dispatch({ type: 'updateNotification', data: { open: true, status: "failure", message: "Please enter a valid email" } })
            return false;
        } else {
            setEmessage("");
            return true;
        }
    }
    
    function login(e) {
        e.preventDefault();
        if (validation()) {
            Service.post("account", "accountLogin", { 
                account_email: email, 
                account_password: password 
            }, res => {
                dispatch({ type: 'updateNotification', data: { open: true, status: res.status, message: res.message } })
                if (res.status === "success") {
                    let data = { ...res.data, account_company: Service.htmlDecode(res.data.account_company) };
                    data.isLoggedIn = true;
                    dispatch({ type: "updateAccountData", data: data });
                    // expiration date setup
                    let d = new Date();
                    // milliseconds * 60 = 60 seconds * 60 = 1 hour * 24 = 24 hours * 30 = 30 days
                    d = new Date(d.getTime() + 1000 * 60 * 60 * 24 * 30);
                    // set cookie function
                    setCookie('apiToken', res.data.api_token, { path: '/', expires: d })
                    if (res.data.account_type === 'admin') {
                        history.push('/admin-portal/admin-home');
                    } else {
                        history.goBack();
                    }
                } else {
                    setEmessage("Your email or password was invalid");
                }
            })
        }
    }

    if (accountData.isLoggedIn) {
        if (accountData.account_type === "admin") {
            return <Redirect to="/admin-portal/admin-home" />
        } if (accountData.account_type !== "admin" && accountData.isLoggedIn) {
            return <Redirect to="/products" />
        }
    }

    return (
        <main>
            <Helmet>
                <title>Login | Flat Rate Metal</title>
            </Helmet>
            <div className="login">
                <div className="login-container">
                    <img src={logo} alt="Company Logo" className="login__logo" />
                    <form onSubmit={login}>
                        <section className="login__username-section">
                            <label htmlFor="username" className="login__username-title">Email</label>
                            <input type="email" required placeholder="Enter Email" onChange={e => setEmail(e.target.value)} value={email} className="input" />
                        </section>
                        <section className="login__password-section">
                            <label htmlFor="password" className="login__password-title">Password</label>
                            <input type="password" required placeholder="Enter Password" onChange={e => setPassword(e.target.value)} value={password} className="input" />
                        </section>
                        <span className="login__error-msg">{emessage}</span><br />
                        <button id="myBtn" type="submit" className="btn--confirm product__login-button">Login</button>
                    </form>
                    <h4 className="login__application-title">New to Flat Rate Metal? <br /> <small>Give us a call at <a href="tel:2085164268" className="login__phone">208-516-4268</a> or</small></h4>
                    <a href="https://forms.gle/48nhHRqw52KaTNXHA" target="_blank" className="login__application-link btn--confirm">Apply for Account</a>
                </div>
            </div>
        </main>
    );
};

export default Login;