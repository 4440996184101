import React, { useContext, useState, useEffect, useRef } from 'react';
import { AppContext } from '../../../../App';
import { useStateCallback } from '../../../../services/customHooks';
import Service from '../../../../services/service';

function AccountDetailEdit() {

    const { accountData, dispatch } = useContext(AppContext);
    const [disabled, setDisabled] = useState(true);
    const [company, setCompany] = useStateCallback("", () => {
        if (company !== accountData.account_company) {
            setDisabled(false);
        } else {
            setDisabled(true);
        }
    });
    const [phone, setPhone] = useStateCallback("", () => {
        if (phone !== accountData.account_phone) {
            setDisabled(false);
        } else {
            setDisabled(true);
        }
    });
    const [email, setEmail] = useStateCallback("", () => {
        if (email !== accountData.account_email) {
            setDisabled(false);
        } else {
            setDisabled(true);
        }
    });
    const [password, setPassword] = useStateCallback("", () => {
        if (password !== "") {
            setDisabled(false);
        } else {
            setDisabled(true);
        }
    });

    useEffect(() => {
        setCompany(accountData.account_company);
        setPhone(accountData.account_phone);
        setEmail(accountData.account_email);
    }, []);

    function updateAccount(e) {
        e.preventDefault();
        Service.post(
            "account",
            "update",
            {
                account_id: accountData.account_id,
                account_company: company,
                account_phone: phone,
                account_email: email,
                account_password: password,
                api_token: accountData.api_token,
            }, (res) => {
                if (res.status === "success") {
                    dispatch({ type: 'updateNotification', data: { open: true, status: "success", message: "Account Updated!" } })
                    dispatch({ type: 'updateAccountData', data: { isLoggedIn: true, api_token: accountData.api_token, ...res.data } })
                } else {
                    dispatch({ type: 'updateNotification', data: { open: true, status: "failure", message: "Could not update account." } })
                }
            })
    }

    const [toggle, setToggle] = useState(false);

    function formatPhone(e) {
        console.log(e.target.value);
        if (e.key === "Backspace" || e.key === "Deletes") {
            setPhone(e.target.value);
            return;
        };
        if (e.target.value.length === 3) {
            phoneInput.current.value = e.target.value + "-";
        }
        if (e.target.value.length === 7) {
            phoneInput.current.value = e.target.value + "-";
        }
        console.log(e.target.value);
        setPhone(e.target.value);
    }

    const phoneInput = useRef(null);

    return (
        <div className="account-detail-edit">
            <h3>Account Details</h3>
            <form onSubmit={updateAccount} className="account-detail-edit-form">
                <section className="account-detail-edit-form-section field">
                    <section className="account-detail-edit-form-section-item field">
                        <label htmlFor="" className="account-detail-edit__label label">Company Name</label>
                        <input type="text" className="account-detail-edit__input input" id="company" onChange={(e) => setCompany(e.target.value)} defaultValue={company} />
                    </section>
                    <section className="account-detail-edit-form-section-item field">
                        <label htmlFor="" className="account-detail-edit__label label">Phone <small>(format: 208-123-1234)</small></label>
                        <input type="tel" onKeyUp={formatPhone} ref={phoneInput} pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}" className="account-detail-edit__input input" id="phone" onChange={(e) => setPhone(e.target.value)} defaultValue={phone} />
                    </section>
                </section>
                <section className="account-detail-edit-form-section field">
                    <section className="account-detail-edit-form-section-item field">
                        <label htmlFor="" className="account-detail-edit__label label">Email</label>
                        <input type="text" className="account-detail-edit__input input" id="email" onChange={(e) => setEmail(e.target.value)} defaultValue={email} />
                    </section>
                    <section className="account-detail-edit-form-section-item field">
                        <label htmlFor="" className="account-detail-edit__label label">Password</label>
                        <input type={toggle ? "text" : "password"} className="account-detail-edit__input input" id="password" onChange={(e) => setPassword(e.target.value)} />
                        <span className="btn--copper account-pass-mask" onClick={() => setToggle(!toggle)}>{toggle ? "Hide" : "Show"}</span>
                    </section>
                </section>
                <section className="account-detail-edit-form-section field">
                    <button disabled={disabled} className="account-detail-edit-btn btn--confirm" type="submit">Update</button>
                </section>
            </form>
        </div>
    );
};

export default AccountDetailEdit;