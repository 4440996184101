import React from "react";
import logo from "../../../images/Flat Rate Metal Logo alt (2).png";

//To implement this feature,
//1) Import it
//2) Place it where you want it to appear
//3) Create boolean state that will determine if loading shows or hides. I have been using the name: const [showLoading, setShowLoading] = useState(true);
//4) Go to the promise and when it returns success, set the loading state to false
//5) Use a turnary operator to set the visibility of the loading component
//6) All done! Enjoy.

function Loading() {
  return (
    <div className="loading__cont">
      <img src={logo} alt="logo" />
      <p className="loading-p">Loading</p>
      <div className="loading-bar-cont">
        <div className="loading-bar"></div>
      </div>
    </div>
  );
}

export default Loading;
