import React, { useEffect } from "react";
import AboutUs from "./childComp/AboutUs";
import HomeCategories from "./childComp/HomeCategories";
import video from "../../../video/frmhomepagevideo.mp4";
// import logo from "../../../images/Flat Rate Metal Logo-03.png"
import logo from "../../../images/frmlogonew.png";
import Helmet from "react-helmet";
import { usePageView } from "../../../services/customHooks";
import WSRA from '../../../images/WSRCA_logo.png';

function Home({ location }) {
  usePageView(location.pathname);

  useEffect(() => {
    document.title = "Home | Flat Rate Metal";
  });

  return (
    <main>
      <Helmet>
        <title>Home | Flat Rate Metal</title>
      </Helmet>
      <div className="home__container">
        <div className="home__video-container">
          <video
            className="home-banner__video"
            playsInline
            muted
            autoPlay
            loop
            src={video}
          />
        </div>
        <div className="home__title-container">
          <img className="home__logo" src={logo} alt="Flat Rate Metal Logo" />
          <p className="home__tagline">Supplying Superior Roofing Metal Products</p>
          <p className="home__tagline">to the Mountain West Since 2001</p>
          {/* <img className="home__WSRCA" src={WSRA} alt="Western States Roofing Contractors Association Logo" /> */}
        </div>
      </div>
      <HomeCategories />
      {/* <AboutUs /> */}
    </main>
  );
}

export default Home;
