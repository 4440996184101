import React from 'react';
import ProductCard from './ProductCard';

function ProductCategoryGroup({categories, category, products}) {
    
    return(
        <>
        <h2 className="product-category-group-title">{category}</h2>
        {products.map(product => {
        if (product.category_name === category) {
            return <ProductCard categories={categories} key={product.product_id} product={product} />
        } else {
            return "";
        }
        })}
        </>
    );
}

export default ProductCategoryGroup;