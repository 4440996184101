import Service from "./service";

export default class CacheManager {
  //cache an item for a certain number of days
  static set(key, data, expires) {
    localStorage.setItem(
      key,
      JSON.stringify({ data: data, expires: this.expires(expires) })
    );
  }
  //get cached items and check to make sure they are still valid
  static get(key) {
    let data = JSON.parse(localStorage.getItem(key));
    if (data === null) {
      return [];
    }
    let currentDate = new Date();
    if (data.expires < currentDate) {
      return [];
    }
    return data.data;
  }
  //set a cache expiration date on items based on days
  static expires(days = 7) {
    return new Date(new Date().getTime() + days * 24 * 60 * 60 * 1000);
  }
  static cacheCheck(key, value, updateActionType, dispatch, queryString) {
    localStorage.clear();
    if (value.length < 1) {
      let storedItems = CacheManager.get(key);
      if (storedItems.length < 1 || !storedItems) {
        console.log("Fetching server items for " + key);
        Service.get(queryString, res => {
          if (res.status === "success") {
            dispatch({ type: updateActionType, data: res.data });
            CacheManager.set(key, res.data, 0.0001);
          }
        });
      } else {
        console.log("grabbing local storage items for " + key);
        dispatch({ type: updateActionType, data: storedItems });
      }
    } else {
      console.log("grabbing context items for " + key);
    }
  }
}
