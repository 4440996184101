//Get Holiday function
import { holidays } from "../services/setHolidays";

// configure new Date() to MySQL compatible date
// or any date created by any other date function
export function setDBDate(date) {
  return (
    date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate()
  );
}

// converts DB compatible date YYYY-MM-DD
export function convertDBDate(date) {
  console.log(typeof date);
  if (typeof date === "object") {
    return date.toDateString();
  } else if (typeof date === "string") {
    let dateArr = date.split("-");
    // This will be assigned the converted string date
    let convertedDate;
    // Check if the array equals a DB date [2019, 11, 20];
    if (dateArr.length == 3) {
      convertedDate = new Date(dateArr[0], dateArr[1] - 1, dateArr[2]);
      return convertedDate.toDateString();
    } else {
      return date;
    }
  }
}

export function minDate(days = 1) {
  let currentDate = new Date();
  let newDate = new Date(currentDate.setDate(currentDate.getDate() + days));
  newDate = newDate.toISOString();
  let index = newDate.indexOf("T");
  return newDate.slice(0, index);
}

export function numCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function isWeekday(date) {
  const day = date.getDay();
  return day !== 0 && day !== 6;
}

export function addDays(date, days) {
  return new Date(+date + days * 86400000);
}

export function addBusinessDays(date, days) {
  let realDays = days;
  for (let i = 0; i <= realDays; i++) {
    let fuzzyDate = new Date();
    let currentDate = new Date(+fuzzyDate + i * 86400000).toDateString();
    let currentDay = new Date(+fuzzyDate + i * 86400000).getDay();
    if (
      currentDay === 0 ||
      currentDay === 6 ||
      currentDate === holidays[0].toDateString() ||
      currentDate === holidays[1].toDateString() ||
      currentDate === holidays[2].toDateString() ||
      currentDate === holidays[3].toDateString() ||
      currentDate === holidays[4].toDateString() ||
      currentDate === holidays[5].toDateString() ||
      currentDate === holidays[6].toDateString() ||
      currentDate === holidays[7].toDateString() ||
      currentDate === holidays[8].toDateString() ||
      currentDate === holidays[9].toDateString() ||
      currentDate === holidays[10].toDateString()
    ) {
      realDays++;
    }
  }
  return new Date(+date + realDays * 86400000);
}

export function checkDate() {
  let currentDate = new Date().toDateString();
  let currentDay = new Date().getDay();
  if (
    currentDay === 0 ||
    currentDay === 6 ||
    currentDate === holidays[0].toDateString() ||
    currentDate === holidays[1].toDateString() ||
    currentDate === holidays[2].toDateString() ||
    currentDate === holidays[3].toDateString() ||
    currentDate === holidays[4].toDateString() ||
    currentDate === holidays[5].toDateString() ||
    currentDate === holidays[6].toDateString() ||
    currentDate === holidays[7].toDateString() ||
    currentDate === holidays[8].toDateString() ||
    currentDate === holidays[9].toDateString() ||
    currentDate === holidays[10].toDateString()
  ) {
    return false;
  }
  return true;
}
