import React, {useContext, useState, useRef, useEffect} from 'react';
import {NavLink} from 'react-router-dom';
import {AppContext} from '../../../App';
import Service from '../../../services/service';
import {useDetectOutSideClick}  from '../../../services/customHooks';
// React Cookie
import { useCookies } from 'react-cookie';

function Nav({toggleNav, boolean,}) {
    const navList = useRef(null);
    const [cartProducts, setCartProducts] = useState([]);
    const {accountData, dispatch, cart} = useContext(AppContext);
    const [dropDown, toggleDropdown] = useState(false);
    const [cookies, setCookie, removeCookie] = useCookies(['apiToken']);
    useEffect(() => {
        if (accountData.isLoggedIn) {
            Service.get('?controller=cart&action=getCart&account_id=' + accountData.account_id + '&api_token=' + accountData.api_token,
                res => {
                    dispatch({type: 'updateCart', data:[...res.data]});
                    setCartProducts(res.data.cart_product_id);
                }
            );
        }
    }, [accountData]);

    useDetectOutSideClick(navList, () => {
        toggleDropdown(false);
    });

    function logout() {
        // dispatch({type: 'updateNotification', data: {open: true, message: accountData.account_company + " logout successful", status: 'success'}});
        dispatch({type: 'updateAccountData', data: {isLoggedIn: false}});
        removeCookie('apiToken');
    }

    return(
        <nav className={"nav " + (boolean ? "open" : "")}>
            <ul className="nav__list">
                <li className="nav__list-item"><NavLink onClick={toggleNav} exact={true} to="/" activeClassName="current__link" className="nav__link">Home</NavLink></li>
                <li className="nav__list-item"><NavLink onClick={toggleNav} to="/products" activeClassName="current__link" className="nav__link">Products</NavLink></li>
                {
                    accountData.isLoggedIn 
                    ?
                    <> 
                        <li className="nav__list-item hide-cart" ><NavLink to="/cart" className="nav__link" title="You shopping cart"><i className="fas fa-shopping-cart"></i>{cart.length > 0 ? <span className="count-icon">{cart.length}</span> : ''}</NavLink></li>
                        <li className="nav__list-item" ref={navList}>
                            <div className="nav__sub-list__header" onClick={() => toggleDropdown(!dropDown)}>
                                <i className={`fas fa-angle-right nav__sub-list-icon ${dropDown ? 'open' : ''}`}></i>
                                <div className="nav__sub-list__info"> <span className="nav__sub-list__welcome">Welcome,</span> <span>{Service.htmlDecode(accountData.account_company)}</span></div>
                            </div>  
                            <div className={`nav__sub-list-wrapper ${dropDown ? 'open' : ''}`}>
                                <ul className={`nav__sub-list ${dropDown ? 'open' : ''}`}>
                                    <li className="nav__sub-item"><NavLink onClick={() => {toggleNav(); toggleDropdown(false); }}  activeClassName="current__link" className="nav__sub-link" to="/account-settings">Account Settings</NavLink></li>
                                    { accountData.account_type === 'admin' ? <li className="nav__sub-item"><NavLink onClick={() => {toggleNav(); toggleDropdown(false); }} activeClassName="current__link" className="nav__sub-link" to="/admin-portal/admin-home">Admin Portal</NavLink></li> : ''}
                                    <li className="nav__sub-item"><NavLink onClick={() => {toggleNav(); logout(); toggleDropdown(false);}} activeClassName="current__link" className="nav__sub-link" to="/login">Logout</NavLink></li>
                                </ul>
                            </div>
                        </li>
                    </>
                    : <li className="nav__list-item"><NavLink onClick={toggleNav} activeClassName="current__link" to="/login" className="nav__link">Login / Signup</NavLink></li>
                }
            </ul>
        </nav>
    );
}

export default Nav;