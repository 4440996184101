import React from 'react';
import HomeCategories from "../home/childComp/HomeCategories";
import Helmet from "react-helmet";
import { usePageView } from '../../../services/customHooks';

function NotFound({ location }) {
    usePageView(location.pathname);
    return(
        <main className="col--lrg--12">
            <Helmet>
                <title>Page Not Found | Flat Rate Metal</title>
            </Helmet>
            <div className="NotFound__container">
                <h1 className="NotFound__heading">404 Not Found</h1>
                <p className="NotFound__tagline">We apologize that this link is broken. We are working hard to keep our site updated and relevant. Please look below for more options.</p>
            </div>
            <HomeCategories/>
        </main>
    );
}

export default NotFound;