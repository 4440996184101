/**
 * Constructs Registration Email Template 
 * @param {object} accountInfo
 * @returns { html string} template
 */
const useRegistrationEmailTemplate = (accountInfo) => {
    return `<table cellpadding='0' cellspaceing='0' border='0' style="border-collapse: collapse" style="width: 100%">
    <tr>
        <td>
            <table width="600" style="background-color: #F5F5F5; max-width: 600px;" cellpadding="0" cellspacing="0" border="0" align="center">
                <tr>
                    <td style="background-color: #273130">
                        <img width='200' src='http://staging.flatratemetal.com/serverAssets/FlatRateMetalLogo-03.png' />
                    </td>
                </tr>
                <tr>
                    <td bgcolor="#F5F5F5" style="padding: 10px">
                        <div>
                            <h1>Account Information & Credentials</h1>
                            <h2>Account</h2>
                            <ul style='list-style-type: none; padding: 0;'>
                                <li>Company: ${accountInfo.company}</li>
                                ${
                                    accountInfo.account_type === 'contractor'
                                        || accountInfo.account_type === 'dealer'
                                        ? `<li>Account Type: ${accountInfo.account_type}</li>`
                                        : ""
                                }
                                ${
                                    accountInfo.phone
                                        ? `<li>Phone: ${accountInfo.phone}</li>`
                                        : ""
                                }
                                <li>Email: ${accountInfo.email}</li>
                                <li>Password: ${accountInfo.password}</li>
                            </ul>
                            <p>This contains your account information and your credentials to log in. Save this email for future reference.</p>
                            <p>**This password is a placehoder. We strongly recommend updating your password on your first log in for your security.**</p>

                        </div>
                    </td>
                </tr>
                <tr>
                    <td style="padding: 10px">
                        <h2>For assistance, please contact us:</h2>
                        <ul style='list-style-type: none; padding: 0;'>
                            <li><span style="font-weight: bold">Phone: 208-516-4268</span>: </li>
                            <li><span style="font-weight: bold">Email: orders@flatratemetal.com</span>: </li>
                        </ul>
                    </td>
                </tr>
            </table>
        </td>
    </tr>
</table>`;
}

export default useRegistrationEmailTemplate;