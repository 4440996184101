import React, { useState, useContext } from 'react';
import Service from '../../../../services/service';
import placeholderImg from '../../../../images/image-placeholder.jpg';
import { Link } from 'react-router-dom';
import { AppContext } from '../../../../App';

function AdminProduct({ product, edit, remove }) {

    const [imageIndex, setImageIndex] = useState(0);
    const { dispatch } = useContext(AppContext);

    function replaceMainImage(index) {
        setImageIndex(index);
    }

    return (
        <>
            <div className="admin-product__container" key={product.product_id}>
                <section className="admin-product__section">
                    <img src={Service.src + product.image_path} alt="product schematic" className="admin-product__list-image"/>
                </section>                 
                <section className="admin-product__section">
                    <h3>{Service.htmlDecode(product.product_name)}</h3>
                </section>
                <section className="admin-product__section">
                    <p className="admin-product__product-detail">{product.material_name}</p>
                </section>
                <section className="admin-product__section">
                    <p className="admin-product__product-detail">{Service.htmlDecode(product.product_dimension)}</p>
                </section>
                <section className="admin-product__section">
                    <p className="admin-product__product-detail">{Service.htmlDecode(product.product_type)}</p>
                </section>
                <section className="admin-product__section">
                    <p className="admin-product__product-detail">{product.product_variants.length}</p>
                </section>
                <section className="admin-product__section">
                    <Link className="viewproducts__button btn--confirm" to={"/admin-portal/admin-product-form/" + product.product_address}>Edit</Link>
                    <button className="viewproducts__button btn--danger" onClick={() => dispatch({ type: "displayDeleteModal", data: { func: remove, open: true, id: product.product_id, message: "This will delete all associated variants" } })}>Delete</button>
                </section>
            </div>

            <div className="admin-product__container-sml" key={product.product_id}>
                <section className="admin-product__section-title">
                    <h3>{Service.htmlDecode(product.product_name)}</h3>
                    <img src={Service.src + product.image_path} alt="product schematic" className="admin-product__list-image-sml"/>               
                </section>
                <section className="admin-product__section-sml">
                    <label className="admin-product__label label">Material</label>
                    <p className="admin-product__product-detail-sml">{product.material_name}</p>
                </section>
                <section className="admin-product__section-sml">
                    <label className="admin-product__label label">Dimensions</label>
                    <p className="admin-product__product-detail-sml">{Service.htmlDecode(product.product_dimension)}</p>
                </section>
                <section className="admin-product__section-sml">
                    <label className="admin-product__label label">Type</label>
                    <p className="admin-product__product-detail-sml">{product.product_type ? Service.htmlDecode(product.product_type) : "None"}</p>
                </section>
                <section className="admin-product__section-sml">
                    <label className="admin-product__label label">Variant(s)</label>
                    <p className="admin-product__product-detail-sml">{product.product_variants.length}</p>
                </section>
                <section className="admin-product__section-sml admin-product__btn-section">
                    <Link className="admin-product__button btn--confirm" to={"/admin-portal/admin-product-form/" + product.product_address}>Edit</Link>
                    <button className="admin-product__button btn--danger" onClick={() => dispatch({ type: "displayDeleteModal", data: { func: remove, open: true, id: product.product_id, message: "This will delete all associated variants" } })}>Delete</button>
                </section>
            </div>
        </>
        //     <div className="viewproducts__product-images-wrapper">
        //         {
        //             product.image_path
        //                 ?
        //                 <div className="viewproducts__image-container">
        //                     <img src={Service.src + product.image_path} className="viewproducts__main-image" />
        //                     <div className="viewproducts__images-container">
        //                         {/* We are going to need to loop through variant images */}
        //                         {/* {product.images.map((image, index) => (
        //                                     <img key={index} src={Service.src + product.image_path} className="viewproducts__sub-image" onMouseEnter={() => replaceMainImage(index)} />))} */}
        //                     </div>
        //                 </div>
        //                 :
        //                 <div className="viewproducts__image-container">
        //                     <img src={placeholderImg} className="viewproducts__main-image" />
        //                     <div className="viewproducts__images-container">
        //                         <img src={placeholderImg} className="viewproducts__sub-image" />
        //                     </div>
        //                 </div>
        //         }
        //     </div>
        //     <div className="viewproducts__product-data-wrapper">
        //         <h3 className="viewproducts__h3-product-name">{Service.htmlDecode(product.product_name)}</h3>
        //         <ul className="viewproducts__ul-product-list">
        //             {/* <li className="viewproducts__li-product-attribute"><span className="viewproducts__span-attribute-title">Retail Price: </span>${product.productRetailPrice}</li> */}
        //             <li className="viewproducts__li-product-attribute"><span className="viewproducts__span-attribute-title">Material: </span>{product.material_name}</li>
        //             <li className="viewproducts__li-product-attribute"><span className="viewproducts__span-attribute-title">Dimenions: </span>{Service.htmlDecode(product.product_dimension)}</li>
        //             <li className="viewproducts__li-product-attribute"><span className="viewproducts__span-attribute-title">Category: </span>{product.category_name}</li>
        //             <li className="viewproducts__li-product-attribute"><span className="viewproducts__span-attribute-title">Type: </span>{Service.htmlDecode(product.product_type)}</li>
        //             <li className="viewproducts__li-product-attribute"><span className="viewproducts__span-attribute-title">Bundle Type: </span>{product.bundle_name}</li>
        //             <li className="viewproducts__li-product-attribute"><span className="viewproducts__span-attribute-title">Bundle Count: </span>{product.product_bundle_count}</li>
        //             <li className="viewproducts__li-product-attribute"><span className="viewproducts__span-attribute-title">Pallet Count: </span>{product.product_pallet_count}</li>
        //             <li className="viewproducts__li-product-attribute"><span className="viewproducts__span-attribute-title">Variant(s): </span>{product.product_variants.length}</li>
        //             <li className="viewproducts__li-product-attribute viewproducts__description"><span className="viewproducts__span-attribute-title">Description: </span>{Service.htmlDecode(product.product_description)}</li>
        //             {/* We are going to need to loop through variants for colors */}
        //             {/* <li className="viewproducts__li-product-attribute viewproducts__description"><span className="viewproducts__span-attribute-title">Colors: </span>
        //                         {product.colors.map((color, index) => (
        //                             index === product.colors.length - 1 ? color.color_name : color.color_name + ", "))}</li> */}
        //         </ul>
        //     </div>
        //     <div className="viewproducts__button-container">
        //         <Link className="viewproducts__button btn--confirm" to={"/admin-product-form/" + product.product_address}>Edit</Link>
        //         <button className="viewproducts__button btn--danger" onClick={() => dispatch({ type: "displayDeleteModal", data: { func: remove, open: true, id: product.product_id, message: "This will delete all associated variants" } })}>Delete</button>
        //     </div>
        // </div>

    );
}

export default AdminProduct;