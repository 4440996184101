import React, { useState } from "react";
import AdminProductImage from "../AdminProductImage";
import Service from "../../../../../services/service";
import AdminProductDescriptionInput from "./childComp/AdminProductDescriptionInput";

function AdminProductDetails({
  materials,
  categories,
  bundleTypes,
  createProduct,
  updateProduct,
  alterProduct,
  product,
  updateProductImage,
  uploadImage,
  uploadProductImage,
  image,
  setImage,
  createProductVariant
}) {
  return (
    <div className="admin-product-details">
      <h2>Product Details</h2>
      <div className="admin-product-details__cont">
        <form
          className="admin-product-details-form"
          onSubmit={product.product_id ? updateProduct : createProduct}
        >
          <div className="admin-product-details__sub-cont">
            <AdminProductImage
              updateProductImage={updateProductImage}
              image={product.image_path ? product.image_path : image}
              setImage={setImage}
              id="mainImageExplorer"
              product={product}
              uploadImage={uploadImage}
              uploadProductImage={uploadProductImage}
            />
            <section className="admin-product-details-section-cont">
              <section className="admin-product-details-form-section">
                <label
                  htmlFor=""
                  className="admin-product-details-form-label label"
                >
                  Name
                </label>
                <input
                  type="text"
                  className="admin-product-details-form-input input full"
                  value={Service.htmlDecode(product.product_name)}
                  required
                  name="product_name"
                  onChange={alterProduct}
                />
              </section>
              <section className="admin-product-details-form-section">
                <label
                  htmlFor=""
                  className="admin-product-details-form-label label"
                >
                  Material
                </label>
                <select
                  className="admin-product-details-form-input input full"
                  value={product.material_id}
                  required
                  name="material_id"
                  onChange={alterProduct}
                >
                  <option value="">- Select -</option>
                  {materials.map(mat => (
                    <option key={mat.material_id} value={mat.material_id}>
                      {mat.material_name}
                    </option>
                  ))}
                </select>
              </section>
              <section className="admin-product-details-form-section">
                <label
                  htmlFor=""
                  className="admin-product-details-form-label label"
                >
                  Category
                </label>
                <select
                  className="admin-product-details-form-input input full"
                  value={product.category_id}
                  required
                  name="category_id"
                  onChange={alterProduct}
                >
                  <option value="">- Select -</option>
                  {categories.map(cat => (
                    <option key={cat.category_id} value={cat.category_id}>
                      {cat.category_name}
                    </option>
                  ))}
                </select>
              </section>
              <section className="admin-product-details-form-section">
                <label
                  htmlFor=""
                  className="admin-product-details-form-label label"
                >
                  Dimensions{" "}
                  <small className="admin-product-details-small">
                    ex. 4" x 4" x 10'
                  </small>
                </label>
                <input
                  type="text"
                  className="admin-product-details-form-input input full"
                  value={Service.htmlDecode(product.product_dimension)}
                  required
                  name="product_dimension"
                  onChange={alterProduct}
                />
              </section>
            </section>
            <section className="admin-product-details-section-cont">
              <section className="admin-product-details-form-section">
                <label
                  htmlFor=""
                  className="admin-product-details-form-label label"
                >
                  Type{" "}
                  <small className="admin-product-details-small">
                    ex. Style "D"
                  </small>
                </label>
                <input
                  type="text"
                  className="admin-product-details-form-input input full"
                  value={Service.htmlDecode(product.product_type)}
                  name="product_type"
                  onChange={alterProduct}
                />
              </section>
              <section className="admin-product-details-form-section">
                <label
                  htmlFor=""
                  className="admin-product-details-form-label label"
                >
                  Bundle Type
                </label>
                <select
                  className="admin-product-details-form-input input full"
                  value={product.bundle_id}
                  required
                  name="bundle_id"
                  onChange={alterProduct}
                >
                  <option value="">- Select -</option>
                  {bundleTypes.map(type => (
                    <option key={type.bundle_id} value={type.bundle_id}>
                      {type.bundle_name}
                    </option>
                  ))}
                </select>
              </section>
              <section className="admin-product-details-form-section">
                <label
                  htmlFor=""
                  className="admin-product-details-form-label label"
                >
                  Bundle Count
                </label>
                <input
                  type="number"
                  min="1"
                  className="admin-product-details-form-input input full"
                  value={product.product_bundle_count}
                  required
                  name="product_bundle_count"
                  onChange={alterProduct}
                />
              </section>
              <section className="admin-product-details-form-section">
                <label
                  htmlFor=""
                  className="admin-product-details-form-label label"
                >
                  Pallet Count
                </label>
                <input
                  type="number"
                  min="1"
                  className="admin-product-details-form-input input full"
                  value={product.product_pallet_count}
                  name="product_pallet_count"
                  onChange={alterProduct}
                />
              </section>
            </section>
          </div>
          <div className="admin-product-details__sub-cont2">
            <section className="admin-product-details-form-section">
              <label
                htmlFor=""
                className="admin-product-details-form-label label"
              >
                Description
              </label>
              {/* <textarea
                                className="admin-product-details-form-input-textarea textarea" 
                                value={Service.htmlDecode(product.product_description)} 
                                name="product_description" 
                                onChange={alterProduct} 
                            /> */}
              <AdminProductDescriptionInput
                description={product.product_description}
              />
            </section>
            <section className="admin-product-details-form-section-btn">
              {product.product_id ? (
                <button
                  type="button"
                  className="admin-product-details-form-btn btn--confirm"
                  onClick={createProductVariant}
                >
                  Create Variant
                </button>
              ) : (
                ""
              )}
              <button
                type="submit"
                className="admin-product-details-form-btn btn--confirm"
              >
                {product.product_id ? "Update" : "Create"}
              </button>
            </section>
          </div>
        </form>
      </div>
    </div>
  );
}

export default AdminProductDetails;
