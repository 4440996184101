import React from 'react';

export default function RichTextButton({ command, icon, runCommand }) {
  return (
    <button
      className="product-description-input-btn"
      type="button"
      onClick={runCommand}
      value={command}
    >
      <i className={icon} />
    </button>
  );
}