import React, { useState, useEffect, useContext, useInterval } from 'react';
import placeholderImg from '../../../../images/image-placeholder.jpg';
import Service from '../../../../services/service';
import { AppContext } from '../../../../App';

function AdminProductVariantCard({ colors, updateProductVariant, product, productVariant, deleteProductVariant, alterCurrentProductVariant, setToggleVariantForm, toggleVariantForm }) {

    const [publishedToggle, setPublishedToggle] = useState(true);
    const [stockToggle, setStockToggle] = useState(true);
    const [imageIndex, setImageIndex] = useState(0);
    // const [stockStatus, setStockStatus] = useState();
    // const [displayStatus, setDisplayStatus] = useState();
    const { dispatch, accountData } = useContext(AppContext);

    let displayStatus;
    let stockStatus;

    useEffect(() => {
        stockStatus = productVariant.product_variant_stock;
        displayStatus = productVariant.product_variant_display;
        setStatuses();
    }, [productVariant]);

    function setStatuses() {
        if (stockStatus === "out") {
            setStockToggle(false);
        }
        if (displayStatus === "hide") {
            setPublishedToggle(false);
        }
    };

    function setStock() {
        let toggle = !stockToggle;
        setStockToggle(toggle);
        console.log(productVariant.product_variant_id);
        Service.put('productVariant', 'updateStock', {
            product_variant_stock: toggle ? "in" : "out",
            product_variant_id: productVariant.product_variant_id,
            api_token: accountData.api_token
        }, (res) => {
            if (res.status === "success") {
                console.log("updated stock");
            }
        })
    }

    function setDisplay() {
        let toggle = !publishedToggle;
        setPublishedToggle(toggle);
        Service.put('productVariant', 'updateDisplay', {
            product_variant_display: toggle ? "show" : "hide",
            product_variant_id: productVariant.product_variant_id,
            api_token: accountData.api_token
        }, (res) => {
            if (res.status === "success") {
                console.log("updated display");
            }
        })
    }

    function replaceMainImage(index) {
        setImageIndex(index);
    }

    function sendVariantToEdit() {
        setToggleVariantForm(true);
        dispatch({ type: 'updateCurrentProductVariant', data: productVariant });
    }

    function sendToDelete() {
        deleteProductVariant(productVariant.product_variant_id);
    };

    return (
        <div className="admin-product-variant-card">
            <section className="admin-product-variant-card-options-cont">
                <button className={stockToggle ? "admin-product-cariant-card-option-button-stock" : "admin-product-cariant-card-option-button-stock-selected"} onClick={setStock}>Stock</button>
                <button className={publishedToggle ? "admin-product-cariant-card-option-button-published" : "admin-product-cariant-card-option-button-published-selected"} onClick={setDisplay}>Display</button>
                <button className="admin-product-cariant-card-option-button-delete" value={productVariant.product_variant_id} onClick={() => dispatch({ type: "displayDeleteModal", data: { func: sendToDelete, open: true, id: productVariant.product_variant_id, } })}>Delete</button>
            </section>
            <form action="" className="admin-product-variant-card-form">
                {
                    productVariant.images.length > 0
                        ?
                        <div className="admin-product-variant-card-section-image">
                            <img src={Service.src + productVariant.images[imageIndex].image_path} className="admin-product-variant-card__main-image" />
                            <div className="admin-product-variant-card__sub-images-container">
                                {productVariant.images.map((image, index) => (
                                    <img key={index} src={Service.src + image.image_path} className="admin-product-variant-card__sub-image" onMouseEnter={() => replaceMainImage(index)} />))}
                            </div>
                        </div>
                        :
                        <section className="admin-product-variant-card-section-image">
                            <img src={placeholderImg} className="admin-product-variant-card__main-image" />
                            <div className="admin-product-variant-card__sub-images-container">
                                <img src={placeholderImg} className="admin-product-variant-card__sub-image" />
                            </div>
                        </section>
                }
                <section className="admin-product-variant-card-section">
                    <label className="admin-product-variant-card-label">Color: <span className="admin-product-variant-card-data">{productVariant.color_name}</span></label>
                </section>
                {productVariant.product_variant_thickness ?
                <section className="admin-product-variant-card-section">
                    <label className="admin-product-variant-card-label">Thickness: <span className="admin-product-variant-card-data">{productVariant.product_variant_thickness}</span></label>
                </section>
                :
                ""
                }
                <section className="admin-product-variant-card-section">
                    <label className="admin-product-variant-card-label">Weight: <span className="admin-product-variant-card-data">{productVariant.product_variant_weight} (lb)</span></label>
                </section>
                <section className="admin-product-variant-card-section">
                    <label className="admin-product-variant-card-label">Retail Price: <span className="admin-product-variant-card-data">${productVariant.product_variant_retail_price}</span></label>
                </section>
                <section className="admin-product-variant-card-section">
                    <label className="admin-product-variant-card-label">Contractor Price: <span className="admin-product-variant-card-data">${productVariant.product_variant_contractor_price}</span></label>
                </section>
                <section className="admin-product-variant-card-section">
                    <label className="admin-product-variant-card-label">Dealer Price: <span className="admin-product-variant-card-data">${productVariant.product_variant_dealer_price}</span></label>
                </section>
                <section className="admin-product-variant-card-section-button label">
                    <button type="button" className="admin-product-variant-card-btn btn--confirm" onClick={sendVariantToEdit}>Edit</button>
                </section>
            </form>
        </div>
    );
}

export default AdminProductVariantCard;