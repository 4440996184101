import React, { useContext } from 'react';
import Service from '../../../../services/service';
import { AppContext } from '../../../../App';
/**
 *  
 * @param {HTML String} template 
 * @param {object} options
 * @returns {function} sendEmail
 */
const useEmail = (template, options) => {
    const { dispatch } = useContext(AppContext);
    const sendEmail = () => {
        Service.post('email', 'send', {
            api_token: options.api_token,
            email_sender: options.sender,
            email_recipient: options.recipient,
            email_subject: options.subject,
            email_body: template,
            email_name: options.name
        }, res => {
            if (res.status === 'success') {
                dispatch({ type: 'updateNotification', data: { open: true, message: "Email sent", status: "success" } });
            }
        })
    }
    return sendEmail;
}

export default useEmail;