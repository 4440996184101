    const newYearsDay = new Date(new Date().getFullYear(), 0, 1);
    const newYearsDayNextYear = new Date(new Date().getFullYear() + 1, 0, 1);
    const lutherKingJrDay = getHolidayDate(3, 1, 1);
    const memorialDay = getHolidayDate(4, 1, 5);
    const independenceDay = new Date(new Date().getFullYear(), 6, 4);
    const laborDay = getHolidayDate(1, 1, 9);
    const veteransDay = new Date(new Date().getFullYear(), 10, 11);
    const thanksgivingDay = getHolidayDate(4, 4, 11);
    const christmasEve = new Date(new Date().getFullYear(), 11, 24);
    const christmasDay = new Date(new Date().getFullYear(), 11, 25);
    const newYearsEve = new Date(new Date().getFullYear(), 11, 31);
    
    export const holidays = [newYearsDay, newYearsDayNextYear, lutherKingJrDay, memorialDay, independenceDay, laborDay, veteransDay, thanksgivingDay, christmasEve, christmasDay, newYearsEve];

function getHolidayDate(week, day, month) {
    //PARAMETERS ARE: (Week, Day of Week, Month)
    let year = new Date().getFullYear();
    let currentWeek = 0;
    month = month - 1;
    let monthDays = new Date(year, month, null).getDate();
    for (let i = 0; i <= monthDays; i++) {
        let dayNum = i + 1;
        let currentDate = new Date(year, month, dayNum);
        let currentDay = currentDate.getDay();
        if (day === currentDay) {
            currentWeek += 1;
        }
        if (currentWeek === week && day === currentDay) {
            let holidayDate = new Date(year, month, dayNum);
            return holidayDate;
        }
    }
}  