import React, { useContext } from "react";
import Service from "../../../../services/service";
import { AppContext } from "../../../../App";
import CacheManager from "../../../../services/cacheManager";

function AccountLocationCard({ address }) {
  const { accountData, dispatch, addresses } = useContext(AppContext);

  function deleteAddress(id) {
    Service.delete(
      "address",
      "delete",
      {
        address_id: id,
        account_id: accountData.account_id,
        api_token: accountData.api_token
      },
      res => {
        if (res.status === "success") {
          dispatch({
            type: "updateNotification",
            data: { open: true, status: "success", message: "Address deleted" }
          });
          let newAddresses = addresses.filter(
            address => address.address_id !== id
          );
          CacheManager.set("addresses", newAddresses, 1);
          dispatch({
            type: "updateAddresses",
            data: newAddresses
          });
        } else {
          dispatch({
            type: "updateNotification",
            data: {
              open: true,
              status: "failure",
              message: "Could not delete address."
            }
          });
        }
      }
    );
  }

  return (
    <div className="account-location-card">
      <ul className="account-location-card__list">
        <li className="account-location-card__list-item">
          {address.address_street}
        </li>
        <li className="account-location-card__list-item">
          {address.address_city}, {address.address_state} {address.address_zip}
        </li>
      </ul>
      <button
        className="account-location-card-button btn--danger"
        onClick={() => deleteAddress(address.address_id)}
      >
        Delete
      </button>
    </div>
  );
}

export default AccountLocationCard;
