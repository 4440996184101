import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";

function AdminNav() {
  const [toggle, setToggle] = useState(false);

  function toggleNav() {
    let newToggle = !toggle;
    setToggle(newToggle);
    console.log(newToggle);
  }

  return (
    <>
      <div
        className={`admin-nav__mobile col--12 hide-at-lrg ${
          toggle ? "nav-open" : " nav-closed"
        }`}
        onClick={toggleNav}
      >
        <div className="admin-nav__mobile-title">Menu</div>
      </div>
      <div
        className={`admin-nav__container col--12 col--lrg--2 show-at-lrg ${
          toggle ? "nav-open" : "nav-closed"
        }`}
      >
        <nav className="admin-nav__nav">
          <NavLink
            className="admin-nav__link"
            activeClassName="admin-nav__link-active"
            to="/admin-portal/admin-home"
            onClick={toggleNav}
          >
            <i className="fas fa-home"></i> Home
          </NavLink>
          <NavLink
            className="admin-nav__link"
            activeClassName="admin-nav__link-active"
            to="/admin-portal/admin-products"
            onClick={toggleNav}
          >
            <i className="fab fa-product-hunt"></i> Products
          </NavLink>
          <NavLink
            className="admin-nav__link"
            activeClassName="admin-nav__link-active"
            to="/admin-portal/admin-accounts"
            onClick={toggleNav}
          >
            <i className="fas fa-user-circle"></i> Accounts
          </NavLink>
          <NavLink
            className="admin-nav__link"
            activeClassName="admin-nav__link-active"
            to="/admin-portal/admin-orders"
            onClick={toggleNav}
          >
            <i className="fas fa-list-alt"></i> Orders
          </NavLink>
          <NavLink
            className="admin-nav__link"
            activeClassName="admin-nav__link-active"
            to="/admin-portal/admin-options-form"
            onClick={toggleNav}
          >
            <i className="fas fa-list-ul"></i> Options
          </NavLink>
        </nav>
      </div>
    </>
  );
}

export default AdminNav;
