import React, { useContext } from 'react';
import { AppContext } from '../../../App';
import {Link} from 'react-router-dom';

function ConfirmModal() {

    const { toggle, dispatch } = useContext(AppContext);

    const func = toggle.displayConfirmModal.func;
    const route = toggle.displayConfirmModal.route;
    const message = toggle.displayConfirmModal.message;

    return (
        <div className="modal__fullscreen-container">
            <div className="deletemodal">
                <h2 className="deletemodal__h2-heading">{message}</h2>
                <div className="deletemodal__button-container">
                    <Link className="btn--confirm" to={route} onClick={() => {func(); dispatch({type: "displayConfirmModal", data: {open: false}});}}>Yes</Link>
                    <button className="btn--danger" onClick={() => dispatch({type: "displayConfirmModal", data: {open: false}})}>No</button>
                </div>
            </div>
        </div>
    );
}

export default ConfirmModal;
