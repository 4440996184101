import React, { useContext, useState, useEffect, useRef } from 'react';
import { AppContext } from '../../../App';
import { Link } from 'react-router-dom';
// Child components
import AdminAccountSpecialPricing from './childComp/adminAccountSpecialPricing/AdminAccountSpecialPricing';
import AdminAccountEditAccount from './childComp/adminAccountEditAccount/AdminAccountEditAccount';
import AccountAddressList from './childComp/accountAddressList';

function AdminAccountDetail({ history, location }) {
    const { accountData, currentAccount, dispatch } = useContext(AppContext);
    const [documentTitle, setDocumentTitle] = useState('');
    useEffect(() => {
        if (currentAccount.currentAccountEditing) {
            setDocumentTitle('Edit Account');
        } else {
            setDocumentTitle('Create Account');
        }
    })

    return (
        <div className="add-distributor col--12 col--lrg--10">
            <div className="create__account-container col--12">
                <AdminAccountEditAccount history={history} currentAccount={currentAccount} accountData={accountData} dispatch={dispatch} />
                {currentAccount.currentAccountEditing && currentAccount.account_type !== 'admin' ? <AccountAddressList currentAccount={currentAccount} accountData={accountData} dispatch={dispatch} /> : ""}
                {currentAccount.account_type !== 'admin' ? <AdminAccountSpecialPricing /> : ''}
            </div>
        </div>
    );
}

export default AdminAccountDetail;