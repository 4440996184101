import React, { useState, useRef, useContext, useEffect } from "react";
import AdminProductImage from "./AdminProductImage";
import { Link } from "react-router-dom";
import imagePlaceholder from "../../../../images/image-placeholder.jpg";
import Service from "../../../../services/service";
import Images from "../../../../services/images";
import { AppContext } from "../../../../App";

function AdminProductVariantDetailsModal({
  colors,
  product,
  uploadImage,
  toggleVariantForm,
  setToggleVariantForm,
  getProduct,
  loadProduct,
  deleteProductVariant
}) {
  const imageInput = useRef();
  const [uploadedImages, setUploadedImages] = useState([]);
  const {
    accountData,
    currentProduct,
    dispatch,
    currentProductVariant
  } = useContext(AppContext);

  useEffect(() => {
    if (currentProductVariant.images) {
      setUploadedImages(currentProductVariant.images);
      getProduct(product.product_address);
    }
  }, []);

  function updateCurrentProductVariant(e) {
    dispatch({
      type: "updateCurrentProductVariant",
      data: { ...currentProductVariant, [e.target.name]: e.target.value }
    });
  }

  function deleteSingleVariantImage(id) {
    Service.delete(
      "image",
      "delete",
      {
        image_id: id,
        api_token: accountData.api_token
      },
      res => {
        if (res.status === "success") {
          dispatch({
            type: "updateNotification",
            data: { open: true, status: "success", message: "Image Deleted" }
          });
          Service.get(
            "?controller=image&action=getByProductVariantId&product_variant_id=" +
              currentProductVariant.product_variant_id,
            res => {
              if (res.status === "success") {
                setUploadedImages(res.data);
              }
            }
          );
        }
      }
    );
  }

  function saveCurrentProductVariant(e) {
    e.preventDefault();
    Service.put(
      "productVariant",
      "update",
      {
        ...currentProductVariant,
        account_id: accountData.account_id,
        api_token: accountData.api_token
      },
      res => {
        if (res.status === "success") {
          dispatch({
            type: "updateNotification",
            data: { open: true, status: "success", message: "Variant Updated" }
          });
          Service.get(
            "?controller=product&action=getOne&product_id=" +
              currentProduct.product_id,
            res => dispatch({ type: "updateCurrentProduct", data: res.data })
          );
          clearVariantState();
        } else {
          dispatch({
            type: "updateNotification",
            data: {
              open: true,
              status: "success",
              message: "Nothing else to Update"
            }
          });
          clearVariantState();
        }
      }
    );
  }

  function clearVariantState() {
    let newToggle = false;
    setToggleVariantForm(newToggle);
    console.log(currentProductVariant);
    if (
      currentProductVariant.product_variant_id &&
      !currentProductVariant.product_variant_retail_price &&
      !currentProductVariant.product_variant_contractor_price &&
      !currentProductVariant.product_variant_dealer_price &&
      !currentProductVariant.product_variant_weight &&
      !currentProductVariant.product_variant_thickness &&
      !currentProductVariant.color_id &&
      !currentProductVariant.images
    ) {
      deleteProductVariant(currentProductVariant.product_variant_id);
    }
    dispatch({
      type: "updateCurrentProductVariant",
      data: {
        product_variant_retail_price: "",
        product_variant_contractor_price: "",
        product_variant_dealer_price: "",
        product_variant_weight: "",
        product_variant_thickness: "",
        product_variant_id: "",
        color_id: "",
        product_id: ""
      }
    });
  }

  function dragEnter(e) {
    console.log("enter");
    e.preventDefault();
    e.stopPropagation();
  }

  function dragOver(e) {
    console.log("over");
    e.preventDefault();
    e.stopPropagation();
  }

  function dragLeave(e) {
    console.log("leave");
    e.preventDefault();
    e.stopPropagation();
  }

  function drop(e) {
    console.log(e.dataTransfer);
    e.preventDefault();
    e.stopPropagation();
    let data = e.dataTransfer;
    let files = data.files;
    handleFiles(files);
  }

  function handleFiles(files) {
    const max = 3;
    // uploadImage(files, res => {
    //     Images.uploadVariantImage(res.data.id);
    //     console.log(Images.getLocalImages());
    //     // console.log(res.data.id);
    // });
    if (uploadedImages.length < max) {
      const difference = max - uploadedImages.length;
      if (files.length >= 1) {
        for (let i = 0; i < files.length && i < difference; i++) {
          console.log(files[i]);
          uploadProductVariantImage(files[i]);
          dispatch({
            type: "updateNotification",
            data: { open: true, status: "success", message: "Images Uploaded" }
          });
        }
      } else {
        console.log(files);
        uploadProductVariantImage(files);
        dispatch({
          type: "updateNotification",
          data: { open: true, status: "success", message: "Images Uploaded" }
        });
      }
    }
  }

  function uploadProductVariantImage(image) {
    console.log(currentProductVariant);
    let imageData = new FormData();
    imageData.append("controller", "image");
    imageData.append("action", "upload");
    imageData.append(
      "product_variant_id",
      currentProductVariant.product_variant_id
    );
    imageData.append("fileUpload", image === FileList ? image[0] : image);
    imageData.append("api_token", accountData.api_token);
    imageData.append("account_type", accountData.account_type);
    fetch(Service.domain, { method: "POST", body: imageData })
      .then(res => res.json())
      .then(res => {
        setUploadedImages(res.data);
      });
  }

  return (
    <div className="variant-modal__fullscreen-container">
      <div className="variant-modal">
        <div className="variant-modal_headers">
          <span
            class="close-button-product-variant"
            onClick={() => {
              dispatch({
                type: "displayConfirmModal",
                data: {
                  open: true,
                  message: "Are You Sure You Want To Exit?",
                  route: `/admin-portal/admin-product-form/${product.product_address}`,
                  func: clearVariantState
                }
              });
            }}
          >
            &times;
          </span>
        </div>
        <div className="admin-product-variant-details">
          <form
            className="product-variant-details-form"
            onSubmit={saveCurrentProductVariant}
          >
            <section className="admin-product-variant-details-section">
              <div className="field admin-product-variant-details-field">
                <label htmlFor="" className="label">
                  Color
                </label>
                <select
                  name="color_id"
                  required
                  className="input full"
                  onChange={updateCurrentProductVariant}
                  value={currentProductVariant.color_id}
                >
                  <option value="">- Select -</option>
                  {colors.map(color => (
                    <option key={color.color_id} value={color.color_id}>
                      {color.color_name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="field admin-product-variant-details-field">
                <label htmlFor="" className="label">
                  Weight (lb)
                </label>
                <input
                  type="text"
                  name="product_variant_weight"
                  required
                  className="input full"
                  onChange={updateCurrentProductVariant}
                  value={currentProductVariant.product_variant_weight}
                />
              </div>
              <div className="field admin-product-variant-details-field">
                <label htmlFor="" className="label">
                  Thickness
                </label>
                <input
                  type="text"
                  name="product_variant_thickness"
                  className="input full"
                  onChange={updateCurrentProductVariant}
                  value={currentProductVariant.product_variant_thickness}
                />
              </div>
            </section>
            <section className="admin-product-variant-details-section">
              <div className="field admin-product-variant-details-field">
                <label htmlFor="" className="label">
                  Retail Price
                </label>
                <input
                  type="text"
                  name="product_variant_retail_price"
                  required
                  className="input full"
                  onChange={updateCurrentProductVariant}
                  value={currentProductVariant.product_variant_retail_price}
                />
              </div>
              <div className="field admin-product-variant-details-field">
                <label htmlFor="" className="label">
                  Contractor Price
                </label>
                <input
                  type="text"
                  name="product_variant_contractor_price"
                  required
                  className="input full"
                  onChange={updateCurrentProductVariant}
                  value={currentProductVariant.product_variant_contractor_price}
                />
              </div>
              <div className="field admin-product-variant-details-field">
                <label htmlFor="" className="label">
                  Dealer Price
                </label>
                <input
                  type="text"
                  name="product_variant_dealer_price"
                  required
                  className="input full"
                  onChange={updateCurrentProductVariant}
                  value={currentProductVariant.product_variant_dealer_price}
                />
              </div>
            </section>
            <div className="product-variant-details-modal__image-cont">
              {uploadedImages.map((image, index) => (
                <div
                  style={{
                    backgroundImage: `url(${Service.src + image.image_path})`,
                    backgroundSize: "cover"
                  }}
                  key={index}
                  className="product-variant-details-modal__image"
                >
                  <span className="product-variant-details-modal__image-cover">
                    <i
                      className="fas fa-times product-variant-details-modal__image-cover-icon"
                      onClick={() => deleteSingleVariantImage(image.image_id)}
                    ></i>
                  </span>
                </div>
              ))}
              <label
                className="product-variant-details-modal__image-dnd"
                htmlFor="imageUpload"
                onDragEnter={dragEnter}
                onDragOver={dragOver}
                onDragLeave={dragLeave}
                onDrop={drop}
              >
                <input
                  disabled={uploadedImages.length < 3 ? false : true}
                  type="file"
                  className="product-variant-details-modal__image-input"
                  id="imageUpload"
                  multiple
                  onChange={e => {
                    handleFiles(e.target.files);
                  }}
                />
                <span className="product-variant-details-modal__image-dnd-text">
                  Add Images
                </span>
                <i className="far fa-images"></i>
                <span className="product-variant-details-modal__image-dnd-text">
                  +{3 - uploadedImages.length}
                </span>
                <small
                  className="product-variant-details-modal__image-dnd-text"
                  style={{ color: "red" }}
                >
                  {3 - uploadedImages.length === 0 ? "3 Maximum" : ""}
                </small>
              </label>
            </div>

            <div className="field" style={{ textAlign: "right" }}>
              <button className="btn--confirm" type="submit">
                Update Details
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default AdminProductVariantDetailsModal;
