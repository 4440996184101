import React, { useState, useEffect, useContext } from "react";
import Service from "../../../services/service";
import ProductList from "./childComp/ProductList";
import ProductSearch from "./childComp/ProductSearch";
import { AppContext } from "../../../App";
import ProductsNav from "./childComp/ProductsNav";
import Helmet from "react-helmet";
import { usePageView } from "../../../services/customHooks";
import CacheManager from "../../../services/cacheManager";

function Products({ match, location }) {
  // const [products, setProducts] = useState([]);
  const [visibleProducts, setVisibleProducts] = useState([]);
  const [showLoading, setShowLoading] = useState(true);
  const { accountData, products, dispatch } = useContext(AppContext);
  const [categories, setCategories] = useState([]);
  usePageView(location.pathname);
  useEffect(() => {
    const reqAddress =
      "?controller=product&action=getAvailableProducts" +
      (accountData.isLoggedIn ? "&account_id=" + accountData.account_id : "");
    CacheManager.cacheCheck(
      "products",
      products,
      "updateProducts",
      dispatch,
      reqAddress
    );

    setShowLoading(false);
    products.map((prod1, prod2) => {
      if (prod1.category_id > prod2.category_id) return 1;
      if (prod1.category_id < prod2.category_id) return -1;
      if (prod1.category_id === prod2.category_id) return 0;
    });
    const cats = [];
    products.forEach(prod => {
      if (cats.indexOf(prod.category_name) > -1) {
        return;
      } else {
        cats.push(prod.category_name);
      }
    });
    setCategories(cats);
    setVisibleProducts(products);
  }, [accountData, match.params.category, products]);

  useEffect(() => {
    console.log("loading visible products");
  }, [products]);

  return (
    <main>
      <Helmet>
        <title>Products | Flat Rate Metal</title>
      </Helmet>
      <ProductsNav
        products={products}
        visableProducts={visibleProducts}
        setVisibleProducts={setVisibleProducts}
      />
      <ProductSearch
        products={products}
        currentCategory={match.params.category}
        setProducts={setVisibleProducts}
      />
      {/* <div className="products__nav">
                <button className="products__all-prod-btn btn--copper" onClick={() => setVisibleProducts(products)}>All Products</button>
            </div> */}
      <ProductList
        categories={
          match.params.category ? [match.params.category] : categories
        }
        products={visibleProducts}
        showLoading={showLoading}
      />
    </main>
  );
}

export default Products;
