import React, { useState, useContext, useEffect } from "react";
import { AppContext } from "../../../../../App";
import Service from "../../../../../services/service";
import { calculateCost } from "./CheckoutUtilities";

//Dates functions
import { CheckoutTotals } from "../checkout/childComp/CheckoutTotals";
import CheckoutForm from "./childComp/CheckoutForm";

function Checkout({ history, placeOrder, cartProducts }) {
  const [receive, setReceive] = useState("");
  const [pickup, setPickup] = useState("");
  const [addresses, setAddresses] = useState([]);
  const { dispatch, accountData, order } = useContext(AppContext);

  useEffect(() => {
    dispatch({
      type: "updateOrder",
      data: {
        ...order,
        ...calculateCost(cartProducts, accountData)
      }
    });
  }, [cartProducts]);

  useEffect(() => {
    Service.get(
      "?controller=address&action=getAll&account_id=" +
        accountData.account_id +
        "&api_token=" +
        accountData.api_token,
      res => {
        setAddresses(res.data);
      }
    );
  }, []);

  function confirmCheckout(e) {
    e.preventDefault();
    placeOrder();
    history.push("/order-confirmation");
  }

  return (
    <div className="col--12 col--med--5 col--lrg--4 checkout">
      <section className="checkout__section">
        <h2 className="checkout__title">Checkout</h2>
        <CheckoutTotals />
        <CheckoutForm
          confirmCheckout={confirmCheckout}
          receive={receive}
          setReceive={setReceive}
          addresses={addresses}
          pickup={pickup}
          setPickup={setPickup}
        />
      </section>
    </div>
  );
}

export default Checkout;
