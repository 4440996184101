import React, {useEffect} from 'react';
import AdminNav from '../../static/adminNav/AdminNav';
import ProtectedAdminRoutes from '../../../services/protectedAdminRoutes';




function AdminPortal() {
    useEffect(() => {
        document.title = "Admin Portal";
    })
    return (
        <main className="admin-portal grid">
               <AdminNav />
               <ProtectedAdminRoutes />
        </main>
    );
}

export default AdminPortal;