import React, { useReducer, useEffect } from "react";
import { BrowserRouter } from "react-router-dom";

// Routes
import Routes from './services/routes';

// React Cookie Import
import { useCookies } from "react-cookie";

//STYLES
import "./App.scss";

//NAV / STATIC
import Header from "./ui/static/header/Header";
import Footer from "./ui/static/footer/Footer";

//UTILITIES
import ScrollToTop from "./services/ScrollToTop";
import State from "./services/state";

import Notification from "./ui/reusable/notification/Notification";
import DeleteModal from "./ui/reusable/modals/DeleteModal";
import ConfirmModal from "./ui/reusable/modals/ConfirmModal";
import Service from "./services/service";
import ReactGA from 'react-ga';
//STATE REDUCER FUNCTION
import StateReducer from "./services/stateReducer";

//HOOK TOOLS
export const AppContext = React.createContext(undefined);

// ReactGA.initialize('UA-152424442-2', {debug: true}); 
ReactGA.initialize('UA-187494762-1'); 

function App() {
  const [cookies] = useCookies(["apiToken"]);

  useEffect(() => {
    const apiToken = cookies.apiToken;
    if (apiToken) {
      Service.get(
        "?controller=account&action=checkToken&api_token=" + apiToken,
        res => {
          if (res.status === "success") {
            let data = {
              ...res.data,
              account_company: Service.htmlDecode(res.data.account_company)
            };
            data.isLoggedIn = true;
            dispatch({ type: "updateAccountData", data: data });
          }
        }
      );
    }
  }, []);
  const [appState, dispatch] = useReducer(StateReducer, State);

  return (
    <BrowserRouter>
      <AppContext.Provider value={{ ...appState, dispatch }}>
        {appState.toggle.notification.open ? <Notification /> : ""}
        {appState.toggle.displayDeleteModal.open ? <DeleteModal /> : ""}
        {appState.toggle.displayConfirmModal.open ? <ConfirmModal /> : ""}
        <Header />
        <ScrollToTop>
          <Routes />
          <Footer />
        </ScrollToTop>
      </AppContext.Provider>
    </BrowserRouter>
  );
}

export default App;
