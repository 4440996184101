import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { AppContext } from "../../../../App";
import Cart from "../../cart/Cart";
import ProductDetail from "./ProductDetail";
import Service from "../../../../services/service";

function ProductDetailAddToCart() {
  const {
    dispatch,
    currentProduct,
    currentProductVariant,
    accountData,
    cart,
    productDetail
  } = useContext(AppContext);

  const [total, setTotal] = useState();
  const [saved, setSaved] = useState();
  const [disabledToggle, setdisabledToggle] = useState(false);
  // const [newWidth, setNewWidth] = useState(0 + "%");
  // const [palletCount, setPalletCount] = useState(0);
  const [qty, setQty] = useState(1);
  const [rp, setRp] = useState(0);
  const [dp, setDp] = useState(0);
  const [cp, setCp] = useState(0);
  const [sp, setSp] = useState(0);

  useEffect(() => {
    setRp(currentProductVariant.product_variant_retail_price);
    setDp(currentProductVariant.product_variant_dealer_price);
    setCp(currentProductVariant.product_variant_contractor_price);
    setSp(currentProductVariant.product_variant_special_price);

    if (currentProductVariant.product_variant_special_price) {
      setTotal(currentProductVariant.product_variant_special_price);
      setSaved(
        currentProductVariant.product_variant_retail_price -
          currentProductVariant.product_variant_special_price
      );
    } else if (accountData.account_type === "dealer") {
      setTotal(currentProductVariant.product_variant_dealer_price);
      setSaved(
        currentProductVariant.product_variant_retail_price -
          currentProductVariant.product_variant_dealer_price
      );
    } else if (accountData.account_type === "contractor") {
      setTotal(currentProductVariant.product_variant_contractor_price);
      setSaved(
        currentProductVariant.product_variant_retail_price -
          currentProductVariant.product_variant_contractor_price
      );
    } else {
      setTotal(currentProductVariant.product_variant_retail_price);
      setSaved(
        currentProductVariant.product_variant_retail_price -
          currentProductVariant.product_variant_retail_price
      );
    }

    if (currentProductVariant.product_variant_stock === "out") {
      setdisabledToggle(true);
    } else if (currentProductVariant.product_variant_stock === "in") {
      setdisabledToggle(false);
    }
  }, [currentProductVariant, accountData]);

  // function updateStatusBar(amount) {
  //     let percentage = amount / currentProduct.product_pallet_count;
  //     let percent = Math.floor(percentage * 100);
  //     setNewWidth(percent + "%");
  //     statusBarString(percent)
  // }

  // function statusBarString(percent) {
  //     let arr = percent.toString();
  //     console.log(arr);
  //     if (arr.length >= 3 & arr[0] === 1) {
  //         setPalletCount(1);
  //     }
  // }

  function updateQty(command) {
    let newQty;
    if (command === "increment") {
      newQty = qty + 1;
      setQty(newQty);
      updatePrice(newQty);
      // updateStatusBar(newQty);
    } else if (command === "decrement" && qty > 1) {
      newQty = qty - 1;
      setQty(newQty);
      updatePrice(newQty);
      // updateStatusBar(newQty);
    }
  }

  function manualQty(e) {
    let manualQty = parseInt(e.target.value);
    if (!manualQty) manualQty = 1;
    setQty(manualQty);
    updatePrice(manualQty);
    // updateStatusBar(manualQty);
  }

  function updatePrice(amount) {
    if (sp) {
      setTotal(amount * sp);
      setSaved(rp * amount - amount * sp);
    } else if (accountData.account_type === "dealer") {
      setTotal(amount * dp);
      setSaved(rp * amount - amount * dp);
    } else if (accountData.account_type === "contractor") {
      setTotal(amount * cp);
      setSaved(rp * amount - amount * cp);
    } else {
      setTotal(amount * rp);
      setSaved(rp * amount - amount * rp);
    }
  }

  function numCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  function addToCart() {
    if (accountData.isLoggedIn) {
      Service.post(
        "cart",
        "addProduct",
        {
          product_variant_id: currentProductVariant.product_variant_id,
          account_id: accountData.account_id,
          cart_product_quantity: qty,
          api_token: accountData.api_token
        },
        res => {
          dispatch({
            type: "updateNotification",
            data: { open: true, status: res.status, message: res.message }
          });
          if (res.status === "success") {
            if (res.data.id) {
              dispatch({ type: "updateCart", data: [...cart, res.data.id] });
            }
            setQty(1);
            updatePrice(1);
          } else {
            dispatch({
              type: "updateNotification",
              data: {
                open: true,
                status: "failure",
                message: "Not Added to Cart"
              }
            });
          }
        }
      );
    }
  }

  return (
    <div className="product-detail-add-to-cart">
      <section className="product-detail-add-to-cart__qty-container">
        <div
          className="product-detail-add-to-cart__qty-button"
          onClick={() => updateQty("decrement")}
        >
          <i className="fas fa-minus product__qty-icon"></i>
        </div>
        <input
          className="input product-detail-add-to-cart__qty-input"
          onClick={e => Service.selectInputContents(e.target)}
          type="text"
          min="0"
          value={qty}
          required
          onChange={manualQty}
        />
        <div
          className="product-detail-add-to-cart__qty-button"
          onClick={() => updateQty("increment")}
        >
          <i className="fas fa-plus product__qty-icon"></i>
        </div>
      </section>
      <section className="product-detail-add-to-cart__qty-info-container">
        {accountData.isLoggedIn ? (
          <button
            className="btn--confirm product-detail-add-to-cart__btn add"
            disabled={disabledToggle}
            onClick={addToCart}
            type="button"
          >
            Add To Cart
          </button>
        ) : (
          <Link
            className="btn--confirm product-detail-add-to-cart__btn add"
            to="/login"
          >
            Login to Purchase
          </Link>
        )}
      </section>
    </div>
  );
}

export default ProductDetailAddToCart;
