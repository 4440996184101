import React, {useState, useEffect, useContext} from 'react';
import {Link} from 'react-router-dom';
import Service from '../../../../services/service';
import placeholderImg from '../../../../images/image-placeholder.jpg';
import searchAlgorithm from '../../../../services/search';
import { AppContext } from '../../../../App';
import Fuse from 'fuse.js';

function ProductSearch({products, setProducts, currentCategory}) {
    const [cachedProducts, setCachedProducts] = useState([]);
    const [categories, setCategories] = useState([]);

    useEffect(() => {
        Service.get("?controller=category&action=getAll", res => {
            if(res.status === 'success') setCategories(res.data);
        })
    }, []);

    useEffect(() => {
    if (currentCategory && products !== []) {
        filterProducts(currentCategory);
    }
    }, [products, currentCategory]);

function searchProducts(e) {
    if(e.target.value !== "") {
        const options = {
            keys: ['product_name', 'material_name', 'product_type', 'product_demension'],
            weight: 0.3,
            shouldSort: true
        }
        const fuse = new Fuse(products, options);
        setCachedProducts(fuse.search(e.target.value));
    } else {
        setCachedProducts([]);
    }
}

function filterProducts(category) {
    if(category !== "") {
        const newProducts = products.filter(product => {
                if(product.category_name === category) {
                    return product;
                }
            });
            setProducts(newProducts);
        } else {
            setProducts(products);
        }
    }
    return (
        <div className="col--12 product-search">
            <form className="product-search__form" onSubmit={e => e.preventDefault()}>
                <div className="field product-search__field">
                    <span className="product-search__icon"><i className="fas fa-search"></i></span>
                    <input placeholder="Search..." type="search" autoComplete="on" className="input searchInput" onChange={searchProducts}/>
                </div>
                <div className="product-search__results-area">
                    {cachedProducts.map(product => <ProductSearchResult product={product} key={product.product_id} />)}
                </div>
            </form>
        </div>
    )
}

function ProductSearchResult({product}) {
    const {accountData} = useContext(AppContext);
    const [lowestCost, setLowestCost] = useState();
    const [highestCost, setHighestCost] = useState();
    function numCommas(x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    useEffect(() => {
        let priceArr = [];

        for (let i = 0; i < product.product_variants.length; i++) {
            priceArr.push(product.product_variants[i].product_variant_retail_price);
        }

        priceArr.sort((price1, price2) => {
            if (price1 > price2) {
                return 1;
            } if (price1 < price2) {
                return -1;
            } return 0;
        });
        setLowestCost(priceArr[0]);
        setHighestCost(priceArr[priceArr.length -1]);

     }, [product])
    return (
        <Link className="psr" to={'/product/' + product.product_address}>
            <header className="psr__header">
                <h4 className="psr__title">{Service.htmlDecode(product.product_name)}</h4>
                <h4 className="psr__title">{Service.htmlDecode(product.product_dimension)}</h4>
            </header>
            <section className="psr__body">
                {product.product_variants ? <img className="psr__img" src={ Service.src + product.product_variants[0].images[0].image_path} alt=""/> : <img className="psr__img" src={placeholderImg} alt=""/>}
                <div className="psr__info">
                    <div className="psr__data">{Service.htmlDecode(product.material_name)}</div>
                    <div className="psr__data">{Service.htmlDecode(product.product_type)}</div>
                    {/* {accountData.account_type === "dealer" || accountData.account_type === "contractor" || product.specialPricing
                        ? <div className="psr__data-price">
                            ${
                                !product.specialPricing ? 
                                    product.account_type === "dealer"
                                    ? numCommas(product.productDealerPrice)
                                    : numCommas(product.productContractorPrice)
                                : numCommas(product.specialPricing)
                            }
                         </div> 
                        : <div className="psr__data-price">${product.productRetailPrice}</div>} */}
                        <div className="psr__data-price">{
                            product.product_variants.length > 1
                            ? "$" + lowestCost + " - $" + highestCost
                            : "$" + product.product_variants[0].product_variant_retail_price
                        }</div>
                </div>
            </section>
        </Link>
    );
}

export default ProductSearch;