import React, { useContext, useState, useEffect } from "react";
import Service from "../../../services/service";
import { AppContext } from "../../../App";
import AdminProduct from "./childComp/AdminProduct";
import { Link } from "react-router-dom";
import Loading from "../../reusable/notification/Loading";
import AdminNav from "../../static/adminNav/AdminNav";

function AdminProducts({ history }) {
  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [visibleProducts, setVisibleProducts] = useState([]);
  const [showLoading, setShowLoading] = useState(true);
  const { dispatch, accountData } = useContext(AppContext);

  function getProducts() {
    Service.get("?controller=product&action=getAll", res => {
      if (res.status === "success") {
        setShowLoading(false);
        setProducts(
          res.data.sort((prod1, prod2) => {
            if (prod1.category_name > prod2.category_name) {
              return 1;
            }
            if (prod1.category_name < prod2.category_name) {
              return -1;
            }
            return 0;
          })
        );
        setVisibleProducts(
          res.data.sort((prod1, prod2) => {
            if (prod1.category_name > prod2.category_name) {
              return 1;
            }
            if (prod1.category_name < prod2.category_name) {
              return -1;
            }
            return 0;
          })
        );
      }
    });
  }

  useEffect(() => {
    document.title = "Admin Products View";
    getProducts();
    Service.get("?controller=category&action=getAll", res => {
      if (res.status === "success") {
        setCategories(res.data);
      }
    });
  }, []);

  function selectedCategory(e) {
    if (e.target.value !== "") {
      const newCategories = products.filter(product => {
        if (product.category_name === e.target.value) {
          return product;
        }
      });
      setVisibleProducts(newCategories);
    } else {
      setVisibleProducts(products);
    }
  }

  function sendToEdit(id) {
    dispatch({ type: "updateCurrentProduct", data: { productId: id } });
    history.push("/admin-create-product");
  }

  function deleteProduct(id) {
    Service.delete(
      "product",
      "delete",
      {
        product_id: id,
        account_type: accountData.account_type,
        api_token: accountData.api_token
      },
      res => {
        dispatch({
          type: "updateNotification",
          data: { open: true, status: res.status, message: res.message }
        });
        if (res.status === "success") {
          const newProducts = products.filter(product => {
            if (product.product_id !== id) {
              return product;
            }
          });
          // There is one caveaut with this approach
          // if we have filtered down to a specific product type and we deleate it will no longler be filtering to that product.
          setProducts([...newProducts]);
          setVisibleProducts([...newProducts]);
        }
      }
    );
  }

  return (
    <div className="viewproducts col--12 col--lrg--10">
      <h1 className="viewproducts__title">PRODUCTS</h1>
      <div className="admin-view-products__category-sort">
        <select
          name="Categories"
          className="admin-view-products__category-input input"
          onChange={e => selectedCategory(e)}
        >
          <option value="" className="admin-view-products__category-option">
            - All Products -
          </option>
          {categories.map(category => (
            <option
              value={category.category_name}
              key={category.category_id}
              className="admin-view-products__category-option"
              key={category.category_id}
            >
              {category.category_name}
            </option>
          ))}
        </select>
        <div className="viewproducts__btn-cont">
          <Link className="btn--confirm" to="/admin-portal/admin-product-form">
            <i class="fas fa-plus"></i>Create Product
          </Link>
        </div>
      </div>
      <div className="viewproducts__div-all-products-container">
        <div className="admin-products__table-title">
          <section className="admin-products__section-title"></section>
          <section className="admin-products__section-title">
            <h3 className="admin-products__table-title">Name</h3>
          </section>
          <section className="admin-products__section-title">
            <h3 className="admin-products__table-title">Material</h3>
          </section>
          <section className="admin-products__section-title">
            <h3 className="admin-products__table-title">Dimensions</h3>
          </section>
          <section className="admin-products__section-title">
            <h3 className="admin-products__table-title">Type</h3>
          </section>
          <section className="admin-products__section-title">
            <h3 className="admin-products__table-title">Variant(s)</h3>
          </section>
          <section className="admin-products__section-title">
            <h3 className="admin-products__table-title">Actions</h3>
          </section>
        </div>
        {showLoading ? <Loading /> : ""}
        {showLoading
          ? ""
          : visibleProducts.map(product => (
              <AdminProduct
                key={product.product_id}
                product={product}
                edit={sendToEdit}
                remove={deleteProduct}
              />
            ))}
      </div>
    </div>
  );
}

export default AdminProducts;
