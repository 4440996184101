import React, { useState, useEffect, useContext } from "react";
import AdminProductDetails from "../adminProducts/childComp/adminProductDetails/AdminProductDetails";
import AdminProductVariantDetailsModal from "../adminProducts/childComp/AdminProductVariantDetailsModal";
import AdminProductVariants from "../adminProducts/childComp/AdminProductVariants";
import { AppContext } from "../../../App";
import Service from "../../../services/service";
import { Link } from "react-router-dom";
import Images from "../../../services/images";

function AdminProductForm({ match }) {
  const {
    accountData,
    dispatch,
    currentProduct,
    currentProductVariant
  } = useContext(AppContext);
  const [categories, setCategories] = useState([]);
  const [materials, setMaterials] = useState([]);
  const [bundles, setBundles] = useState([]);
  const [colors, setColors] = useState([]);
  const [toggleVariantForm, setToggleVariantForm] = useState(false);

  useEffect(() => {
    loadProduct();
    // Cleanup function to clear current product from global state
    // this function will execute once the component or admin product
    // form unmounts
    return () => {
      dispatch({ type: "clearCurrentProduct" });
      console.log(currentProduct);
    };
  }, []);

  function loadProduct() {
    Service.get("?controller=material&action=getAll", res =>
      setMaterials(res.data)
    );
    Service.get("?controller=category&action=getAll", res =>
      setCategories(res.data)
    );
    Service.get("?controller=color&action=getAll", res => setColors(res.data));
    Service.get("?controller=bundle&action=getAll", res =>
      setBundles(res.data)
    );
    if (match.params.productAddress) {
      getProduct(match.params.productAddress);
    }
  }

  function getProduct(address) {
    Service.get(
      "?controller=product&action=getOneByAddressForAdmin&product_address=" +
        address,
      res => dispatch({ type: "updateCurrentProduct", data: res.data })
    );
  }

  function alterProduct(e) {
    dispatch({
      type: "updateCurrentProduct",
      data: { ...currentProduct, [e.target.name]: e.target.value }
    });
  }

  function alterProductVariant(e) {
    dispatch({
      type: "updateCurrentProductVariant",
      data: { ...currentProductVariant, [e.target.name]: e.target.value }
    });
  }

  function uploadImage(images, callBack) {
    for (let i = 0; i < images.length; i++) {
      let imageData = new FormData();
      imageData.append("controller", "image");
      imageData.append("action", "upload");
      imageData.append("fileUpload", images[i]);
      imageData.append("api_token", accountData.api_token);
      imageData.append("account_type", accountData.account_type);
      fetch(Service.domain, { method: "POST", body: imageData })
        .then(res => res.json())
        .then(res => {
          callBack(res);
        });
    }
  }

  function uploadProductImage(images, callBack) {
    console.log(images);
    for (let i = 0; i < images.length; i++) {
      let imageData = new FormData();
      imageData.append("controller", "image");
      imageData.append("action", "upload");
      imageData.append("product_id", currentProduct.product_id);
      imageData.append("fileUpload", images[i]);
      imageData.append("api_token", accountData.api_token);
      imageData.append("account_type", accountData.account_type);
      fetch(Service.domain, { method: "POST", body: imageData })
        .then(res => res.json())
        .then(res => {
          callBack(res);
        });
    }
  }

  /**
   *  Fire these events once the
   *  product form is submitted
   *  they need to be fired conditionally based
   *  on the state of the form (editing or creating)
   */
  function updateProduct(e) {
    e.preventDefault();
    Service.put(
      "product",
      "update",
      {
        ...currentProduct,
        api_token: accountData.api_token,
        account_type: accountData.account_type
      },
      res => {
        dispatch({
          type: "updateNotification",
          data: { message: res.message, status: res.status, open: true }
        });
      }
    );
  }

  function createProduct(e) {
    e.preventDefault();
    Service.post(
      "product",
      "create",
      {
        ...currentProduct,
        api_token: accountData.api_token,
        account_type: accountData.account_type
      },
      res => {
        dispatch({
          type: "updateNotification",
          data: { message: res.message, status: res.status, open: true }
        });
        if (res.status === "success") {
          Images.addImageToProduct(res.data.id, accountData.api_token, () => {
            Service.get(
              "?controller=product&action=getOne&product_id=" + res.data.id,
              res => dispatch({ type: "updateCurrentProduct", data: res.data })
            );
          });
        }
      }
    );
  }

  /**
   * Fire these events on the product
   * variant form
   */

  function createProductVariant() {
    Service.post(
      "productVariant",
      "createEmpty",
      {
        api_token: accountData.api_token,
        account_id: accountData.account_id,
        product_id: currentProduct.product_id
      },
      res => {
        let newToggle = true;
        let variant_id = res.data.id;
        dispatch({
          type: "updateCurrentProductVariant",
          data: { product_variant_id: variant_id }
        });
        dispatch({
          type: "updateNotification",
          data: { message: res.message, status: res.status, open: true }
        });
        setToggleVariantForm(newToggle);
        if (res.status === "success") {
          getProduct(match.params.productAddress);
        }
      }
    );
  }

  function deleteProductVariant(id) {
    Service.delete(
      "productVariant",
      "delete",
      {
        product_variant_id: id,
        api_token: accountData.api_token,
        account_id: accountData.account_id,
        account_type: accountData.account_type
      },
      res => {
        if (res.status === "success") {
          getProduct(match.params.productAddress);
          dispatch({
            type: "updateNotification",
            data: { message: res.message, status: res.status, open: true }
          });
        }
      }
    );
  }

  return (
    <div className="col--12 col--lrg--10">
      <div className="admin-product-form">
        <AdminProductDetails
          alterProduct={alterProduct}
          createProduct={createProduct}
          updateProduct={updateProduct}
          createProductVariant={createProductVariant}
          materials={materials}
          categories={categories}
          product={currentProduct}
          bundleTypes={bundles}
          dispatch={dispatch}
          setToggleVariantForm={setToggleVariantForm}
          toggleVariantForm={toggleVariantForm}
          uploadImage={uploadImage}
          uploadProductImage={uploadProductImage}
        />
        {currentProduct.product_id ? (
          <>
            {toggleVariantForm ? (
              <AdminProductVariantDetailsModal
                colors={colors}
                alterProductVariant={alterProductVariant}
                product={currentProduct}
                dispatch={dispatch}
                uploadImage={uploadImage}
                setToggleVariantForm={setToggleVariantForm}
                toggleVariantForm={toggleVariantForm}
                getProduct={getProduct}
                loadProduct={loadProduct}
                deleteProductVariant={deleteProductVariant}
              />
            ) : (
              ""
            )}

            <AdminProductVariants
              createProductVariant={createProductVariant}
              colors={colors}
              alterProductVariant={alterProductVariant}
              product={currentProduct}
              productVariant={currentProductVariant}
              deleteProductVariant={deleteProductVariant}
              dispatch={dispatch}
              setToggleVariantForm={setToggleVariantForm}
              toggleVariantForm={toggleVariantForm}
            />
          </>
        ) : (
          ""
        )}
      </div>
    </div>
  );
}

export default AdminProductForm;
