import Service from './service';

export default class Images {

    static uploadImage(image_id, api_token) {
        let d = new Date();

        let currentImages = [];

        if(window.localStorage.currentImages) {
            console.log(localStorage.currentImages);
            this.deleteAllImages(api_token, res => {
                console.log(res);
                    currentImages.push({ imageId: image_id, imageDate: d.getDate() });
                    window.localStorage.currentImages = JSON.stringify(currentImages);
            });
        } else {
            currentImages.push({ imageId: image_id, imageDate: d.getDate() });
            window.localStorage.currentImages = JSON.stringify(currentImages);
        }
    }

    static uploadVariantImage(image_id) {
        let d = new Date();
        let currentImages = window.localStorage.currentImages ? JSON.parse(window.localStorage.currentImages) : [];
        
        currentImages.push({ imageId: image_id, imageDate: d.getDate() })

        window.localStorage.currentImages = JSON.stringify(currentImages);
    }

    //single image
    static addImageToProduct(prodId, api_token, callback = () => {}) {
        let assignableImageId = JSON.parse(window.localStorage.currentImages)[0].imageId;
        Service.put('image', 'assign', {
            product_id: prodId,
            image_id: assignableImageId,
            api_token: api_token
        }, (res) => {
            if (res.status === "success") {
                console.log("images added successfully!")
                window.localStorage.currentImages = "";
                callback();
            } else {
                console.log("CATOSTROPHIC FAILURE!")
            }
        })
    }

    //multiimage
    static addImagesToProductVariant(prodVarId) {
        let assignableImageIds = JSON.parse(window.localStorage.currentImages).map((image, index) => { return image.imageId })
        Service.put('image', 'assign', {
            product_variant_id: prodVarId,
            assignable_images: assignableImageIds
        }, (res) => {
            if (res.status === "success") {
                console.log("images added successfully!")
            } else {
                console.log("CATOSTROPHIC FAILURE!")
            }
        })
    }

    static deleteImage(imageId, apiToken, callback) {
        let localImages = JSON.parse(window.localStorage.currentImages);
        let removeImageByIndex = localImages.filter((image, index) => {if(image.imageId = imageId){ return index}});
        

        Service.delete('image', 'delete', {
            image_id: imageId,
            api_token: apiToken
        }, (res) => {
            if (res.status === "success") {
                console.log("images added successfully!");
                localImages.splice(removeImageByIndex, 1);
                window.localStorage.currentImages = JSON.stringify(localImages);
            } else {
                console.log("CATOSTROPHIC FAILURE!" + res.message)
            }
            callback(res);
        })
    }

    static deleteAllImages(api_token, callback = ()=>{}) {
        let localImages = JSON.parse(window.localStorage.currentImages);
        let imageIds = localImages.map(image => { return image.imageId; });
        imageIds.forEach(imageId => this.deleteImage(imageId, api_token, callback));
    }

    static deleteImagesByDate(apiToken) {
        let d = new Date();
        let currentDate = d.getDate();
        let currentImages = JSON.parse(window.localStorage.currentImages)
        let i;
        for (i = 0; i < currentImages.length - 1; i++) {
            if (currentImages[i].imageDate < currentDate) {
                currentImages.splice(currentImages[i], 1);
                Service.delete('image', 'delete', {
                    image_id: currentImages[i].imageId,
                    api_token: apiToken
                }, (res) => {
                    if (res.status === "success") {
                        console.log("images added successfully!")
                    } else {
                        console.log("CATOSTROPHIC FAILURE!")
                    }
                })
            }
        }
        window.localStorage.currentImages = JSON.stringify(currentImages);
    }

    static getLocalImages() {
        let promise = init();
        promise.then((res) => {
            return res;
        });
    }
}

function init() {
    return new Promise((resolve, reject) => {
        let localImages = JSON.parse(window.localStorage.currentImages);
        let imageIds = localImages.map(image => { return image.imageId; });
        let images = [];
        imageIds.forEach((imageId, index) => Service.get("?controller=image&action=getOne&image_id=" + imageId, res => {
            images.push(res.data);
            if(index === imageIds.length -1) {
                resolve(images);
            }
        }));
    });
}