import React, { useEffect, useState } from "react";
import CartProduct from "./CartProduct";

function CartProductList({ cartProducts, setProducts }) {
  const [sortedProducts, setSortedProducts] = useState([]);

  useEffect(() => {
    setSortedProducts(() => {
      let productSort = cartProducts.sort(productSorter);
      let categorySort = productSort.sort(categorySorter);
      return categorySort;
    });
  }, [cartProducts]);

  function categorySorter(prod1, prod2) {
    if (prod1.category_id > prod2.category_id) return 1;
    if (prod1.category_id < prod2.category_id) return -1;
    if (prod1.category_id === prod2.category_id) return 0;
  }

  function productSorter(prod1, prod2) {
    if (prod1.product_id > prod2.product_id) return 1;
    if (prod1.product_id < prod2.product_id) return -1;
    if (prod1.product_id === prod2.product_id) return 0;
  }

  return (
    <section className=" cart-product-list col--12 col--med--7 col--lrg--8">
      {sortedProducts.map((cartProduct, i) => (
        <CartProduct cartProduct={cartProduct} key={i} />
      ))}
      {cartProducts.length === 0 ? <span>No products in your cart</span> : ""}
    </section>
  );
}

export default CartProductList;
