import {useRef, useEffect, useState, useReducer} from 'react';
import ReactGA from 'react-ga';
/**
 * Credit to this function/hook: 
 * author: Dan Abramov
 * https://overreacted.io/making-setinterval-declarative-with-react-hooks/
 */
export function useInterval(callback, delay) {
    const savedCallBack = useRef();

    useEffect(() => {
        savedCallBack.current = callback;
    }, [callback]);

    useEffect(() => {
        function tick() {
            savedCallBack.current();
        }
        if(delay !== null) {
            let id = setInterval(tick, delay);
            return () => clearInterval(id);
        }
    }, [delay]);
}

export function useStateCallback(newState, callback) {
    const [state, updateState] = useState(newState);
    useEffect(() => {
        return callback();
    }, [state]);
    return [state, updateState];
}

export function useOnWindowClose(callback) {
    useEffect(() => {
        window.onbeforeunload = () => {callback(); return null;};
    }, []);
}

// The purpose of this custome hook is to detect a click ouside
// of the reference that is passed. Its current usage is in the navigation
// for the drop down navigation menu it was retrieved from stack overflow at
// the following address:
// https://stackoverflow.com/questions/32553158/detect-click-outside-react-component#answer-42234988
export function useDetectOutSideClick(ref, callBack) {
    function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          callBack();
        }
      }
    
    useEffect(() => {
        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    });
}

function advStateReducer(state, action) {
    switch(action.type) {
        case 'addState':
            return action.data;
        default:
            throw new Error();
    }
}

export function useAdvState(initState) {
    const [data, dispatch] = useReducer(advStateReducer, initState);
    function updateState(newState) {
        dispatch({type: 'addState', data: newState});
    }
    return [data, updateState];
}

/**
 * Credit to Gabe Ragland
 * https://dev.to/gabe_ragland/debouncing-with-react-hooks-jci
 * 
 */
export function useDebounce(value, delay) {
    const [debouncedValue, setDebouncedValue] = useState(value);

    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedValue(value);
        }, delay);
        return () => clearTimeout(handler);
    }, [value])

    return debouncedValue;
}

export function usePageView(path) {
    useEffect(() => {
        ReactGA.pageview(path);
    }, [])
}