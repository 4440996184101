import React, { useRef, useEffect, useContext, useState } from 'react';
import RichTextToolbar from './childComp/RichTextToolbar';
import { AppContext } from '../../../../../../App';
import Service from '../../../../../../services/service';

function AdminProductDescriptionInput({ description }) {
  const [desc, setDesc] = useState("");
  const ProductDescription = useRef();
  const { dispatch, currentProduct } = useContext(AppContext);
  function alterProductDescription(e) {
    dispatch({
      type: "updateCurrentProduct",
      data: { ...currentProduct, product_description: Service.htmlEntities(e.target.innerHTML) }
    });
  }
  useEffect(() => {
    if(description !== null && desc === "") {
      ProductDescription.current.innerHTML = Service.htmlDecode(description);
      setDesc(description);
    }
  }, [description]);
  return (
    <div className="admin-product-description-input-container">
      <RichTextToolbar />
      <div
        ref={ProductDescription}
        className="admin-product-details-form-input-textarea textarea"
        name={"product_description"}
        onInput={alterProductDescription}
        contentEditable={true}
      />
    </div>
  );
}

export default AdminProductDescriptionInput;