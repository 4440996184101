import React, { useContext, useState, useEffect } from "react";
import { AppContext } from "../../../../App";
import Service from "../../../../services/service";
import placeholderImge from "../../../../images/image-placeholder.jpg";

function ProductDetailOptions() {
  const { dispatch, currentProduct, currentProductVariant } =
    useContext(AppContext);
  const [stateId, setStateId] = useState();

  function loadVariant(index) {
    dispatch({
      type: "updateCurrentProductVariant",
      data: currentProduct.product_variants[index],
    });
  }

  useEffect(() => {
    setStateId(currentProductVariant.product_variant_id);
  }, [currentProductVariant]);

  const getImage = (prodVar) => {
    try {
      return Service.src + prodVar.images[0].image_path;
    } catch {
      return placeholderImge;
    }
  };

  return (
    <div className="product-detail-options">
      <label className="product-detail-options__label">
        <span className="product-detail-options__label-title">
          Product Options:
        </span>{" "}
        {currentProductVariant.color_name}
      </label>
      <div className="product-detail-options__cont">
        {currentProduct.product_variants.map((prodVar, index) =>
          prodVar.product_variant_display === "show" ? (
            <div
              className={
                prodVar.product_variant_id === stateId
                  ? "product-detail-option option-selected"
                  : "product-detail-option"
              }
              key={index}
              title={`${prodVar.color_name} ${
                prodVar.product_variant_thickness
                  ? prodVar.product_variant_thickness
                  : ""
              }`}
              onClick={() => loadVariant(index)}
            >
              <img
                src={getImage(prodVar)}
                width="50px"
                height="50px"
                style={{ backgroundSize: "contain" }}
              />
              {prodVar.product_variant_stock === "out" ? (
                <p className="product-detail-option__p">
                  <i className="fas fa-ban" />
                </p>
              ) : (
                ""
              )}
              <p className="product-detail-option__span">
                {prodVar.color_name}
              </p>
            </div>
          ) : (
            ""
          )
        )}
      </div>
      {currentProduct.product_name === "Step Shingles" ||
      currentProduct.product_name === "Roof-To-Wall 110°" ||
      currentProduct.product_name === "Roof-To-Wall 90°" ||
      currentProduct.product_name === "W Valley" ? (
        <>
          <p style={{ margin: "5px 0px 0px 5px" }}>
            <strong>Please Note:</strong> Chosen color = <i>inside</i> fold.
          </p>
          <p>*Outside fold may differ in color.</p>
        </>
      ) : (
        ""
      )}
    </div>
  );
}

export default ProductDetailOptions;
