import React from 'react';
import { Switch, Route } from "react-router-dom";
import ProtectedRoute from "./ProtectedRoute";

// 404 NOT FOUND
import NotFound from "../ui/views/notFound/NotFound";

//ADMIN VIEWS
import NewProductForm from "../ui/views/adminProductForm/AdminProductForm";
import AdminOptionsForm from "../ui/views/adminOptionsForm/AdminOptionsForm";
import AdminProducts from "../ui/views/adminProducts/AdminProducts";
import AdminAccountDetail from "../ui/views/adminAccountDetail/AdminAccountDetail";
import AdminHome from '../ui/views/adminHome/AdminHome';

//TEMP VIEW
import AdminProductVariantDetailsModal from "../ui/views/adminProducts/childComp/AdminProductVariantDetailsModal";
import AdminAccounts from "../ui/views/adminAccounts/AdminAccounts";
import AdminOrders from '../ui/views/adminOrder/AdminOrders';

function ProtectedAdminRoutes() {
  return (
    <>
      <Switch>
        <ProtectedRoute
        isAdminPage={true}
        path="/admin-portal/admin-product-variant-details-modal"
        component={<AdminProductVariantDetailsModal />}
      /> 
        <ProtectedRoute
          isAdminPage={true}
          path="/admin-portal/admin-product-form/:productAddress"
          component={<NewProductForm />}
        />
        <ProtectedRoute
          isAdminPage={true}
          path="/admin-portal/admin-product-form"
          component={<NewProductForm />}
        />
        <ProtectedRoute
          isAdminPage={true}
          path="/admin-portal/admin-options-form"
          component={<AdminOptionsForm />}
        />
        <ProtectedRoute
          isAdminPage={true}
          path="/admin-portal/admin-create-account"
          component={<AdminAccountDetail />}
        />
        <ProtectedRoute
          isAdminPage={true}
          path="/admin-portal/admin-accounts"
          component={<AdminAccounts />}
        />
        <ProtectedRoute
          isAdminPage={true}
          path="/admin-portal/admin-products"
          component={<AdminProducts />}
        />
        <ProtectedRoute
          isAdminPage={true}
          path="/admin-portal/admin-home"
          component={<AdminHome />}
        />
        <ProtectedRoute
          isAdminPage={true}
          path="/admin-portal/admin-orders"
          component={<AdminOrders />}
        />
        <Route component={NotFound} />
      </Switch>
    </>                
  );
}

export default ProtectedAdminRoutes;