import React from 'react';
import ProductDetailDescription from './ProductDetailDescription';

function ProductDetailInfo({ match }) {

    return (
        <div className="product-detail-info">
            <ProductDetailDescription match={match} />
        </div>
    );
}

export default ProductDetailInfo;