import React, { useState, useContext, useEffect } from "react";
import Nav from "../nav/Nav";
import { AppContext } from "../../../App";
import { Link } from "react-router-dom";
// import logo from '../../../images/Flat Rate Metal Logo alt.svg';
import logo from "../../../images/frmlogonewsml.png";
import Service from "../../../services/service";
import { useOnWindowClose, useInterval } from "../../../services/customHooks";
import HeaderAlert from "./HeaderAlert";

function Header() {
  const [toggle, setToggle] = useState(false);
  const { cart, accountData, dispatch } = useContext(AppContext);

  useEffect(() => {
    if (accountData.isLoggedIn === false) {
      if (cart.length > 0) {
        dispatch({ type: "updateCart", data: [] });
      }
      // Service.get("?controller=cart&action=getAllByUser&account_id=" + accountData.account_id + "&api_token=" + accountData.api_token,
      // res => {
      //     if(res.status === 'success' && res.data.length > 0) {
      //         let newCart = [];
      //         res.data.forEach(product => {
      //             product.purchaseDetails = {
      //                 purchaseColor: {colorName: product.colorName, colorId: product.colorId},
      //                 purchaseCost: product.cartProductCost,
      //                 purchaseCount: product.cartProductQuantity,
      //             }
      //             newCart.push(product);
      //         });
      //         dispatch({type: 'updateCart', data: newCart});
      //     }
      // });
    }
  }, [accountData]);

  // useInterval(() => {
  //     if(accountData.isLoggedIn) {
  //         Service.post('cart', 'add', {
  //             cart: cart || [],
  //             api_token: accountData.api_token,
  //             account_id: accountData.account_id,
  //         }, res => {
  //             console.log(res);
  //         });
  //     }
  // }, 15000);

  function toggleNav() {
    setToggle(!toggle);
  }

  return (
    <>
      <HeaderAlert />
      <header className="header">
        <div className="header__container">
          <Link
            className="home__anchor"
            to="/"
            onClick={() => setToggle(false)}
          >
            <img src={logo} className="header__icon" alt="" />
            <span className="header__title show-hide">Flat Rate Metal</span>
          </Link>

          <div className="header__details">
            {accountData.isLoggedIn ? (
              <div className="nav__list-item show-cart">
                <Link
                  to="/cart"
                  onClick={() => setToggle(false)}
                  className="nav__link nav__link--dark"
                  title="You shopping cart"
                >
                  <i className="fas fa-shopping-cart"></i>
                  {cart.length > 0 ? (
                    <span className="count-icon">{cart.length}</span>
                  ) : (
                    ""
                  )}
                </Link>
              </div>
            ) : (
              ""
            )}
            <div className="menu__container" onClick={toggleNav}>
              <span className={"nav__menu " + (toggle ? "open" : "")}></span>
            </div>
          </div>
        </div>
        <Nav toggleNav={toggleNav} setToggle={setToggle} boolean={toggle} />
      </header>
    </>
  );
}

export default Header;
