import React from 'react';
import RichTextButton from './RichTextButton';

export default function RichTextToolbar() {

  const runCommand = (e) => {
    document.execCommand(e.target.value, false, e.target.value === "heading" ? "h3" : "");
  }

  const buttons = [
    {command: "justifyLeft", icon: "fas fa-align-left"},
    {command: "justifyCenter", icon: "fas fa-align-center"},
    {command: "justifyRight", icon: "fas fa-align-right"},
    {command: "bold", icon: "fas fa-bold"},
    {command: "italic", icon: "fas fa-italic"},
    {command: "insertOrderedList", icon:"fas fa-list-ol"},
    {command: "insertUnorderedList", icon: "fas fa-list"},
    // {command: "heading", icon: "fas fa-heading"},
    // {command: "insertHtml", icon: "fas fa-table"},
    // {command: "createLink", icon: "fas fa-link"},
    {command: "undo", icon: "fas fa-undo"}
  ];
  return(
    <div className="admin-product-description-input-toolbar">
      {buttons.map((btn, i) => (
        <RichTextButton key={i} command={btn.command} icon={btn.icon} runCommand={runCommand}/>    
      ))}
    </div>
  );
}