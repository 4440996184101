import React, { useEffect, useState } from 'react';

function AccountAddressCreation({ history, setInitialAddress }) {
    const [newAddress, setNewAddress] = useState({ address_street: '', address_city: '', address_state: '', address_zip: '' });
    function updateAddress(e) {
        const value = e.target.value;
        const name = e.target.name;
        setNewAddress({ ...newAddress, [name]: value });
    }

    useEffect(() => {
        setInitialAddress(newAddress);
    }, [newAddress])

    return (
        <>
            <div className="field col--12">
                <label className="label">Street</label>
                <input required type="text" name="address_street" value={newAddress.address_street} onChange={updateAddress} className="input full" />
            </div>
            <div className="field col--12 col--med--4">
                <label className="label">City</label>
                <input required type="text" name="address_city" value={newAddress.address_city} onChange={updateAddress} className="input full" />
            </div>
            <div className="field col--6 col--med--4">
                <label className="label">State</label>
                <select required name="address_state" value={newAddress.address_state} onChange={updateAddress} className="input full">
                    <option value="">- Select -</option>
                    <option value="idaho">Idaho</option>
                    <option value="wyoming">Wyoming</option>
                    <option value="montana">Montana</option>
                    <option value="utah">Utah</option>
                </select>
            </div>
            <div className="field col--6 col--med--4">
                <label className="label">Zipcode</label>
                <input required onChange={updateAddress} value={newAddress.address_zip} name="address_zip" type="text" className="input full" />
            </div>
            <div className="field col--12">
                <button className="btn--danger" type="button" onClick={() => history.goBack()}>Back</button>
                <button className="btn--confirm" type="submit">Submit</button>
            </div>
        </>
    )
}
export default AccountAddressCreation;