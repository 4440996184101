import React, { useEffect, useState, useContext } from "react";
import Service from "../../../../services/service";
import { AppContext } from "../../../../App";
import { NavLink, Link } from "react-router-dom";
import ProductDetailImage from "./ProductDetailImage";
import ProductDetailInfo from "./ProductDetailInfo";
import Helmet from "react-helmet";
import { usePageView } from "../../../../services/customHooks";
import ProductsNav from "./ProductsNav";

function ProductDetail({ match, location }) {
  const {
    dispatch,
    currentProduct,
    accountData,
    currentProductVariant
  } = useContext(AppContext);
  const [categories, setCategories] = useState();
  usePageView(location.pathname);
  useEffect(() => {
    getProduct(match.params.productAddress);

    Service.get("?controller=category&action=getAll", res => {
      if (res.status === "success") {
        setCategories(res.data);
      }
    });
    // Cleanup function to clear current product from global state
    // this function will execute once the component or product
    // detail unmounts
    return () => {
      dispatch({ type: "clearCurrentProduct" });
      console.log(currentProduct);
    };
  }, [accountData]);

  function getProduct(address) {
    const queryString =
      "?controller=product&action=getOneByAddress&product_address=" +
      address +
      (accountData.account_id ? "&account_id=" + accountData.account_id : "");
    Service.get(queryString, res => {
      if (res.status === "success") {
        //Get the product and store in Global current product state
        dispatch({ type: "updateCurrentProduct", data: res.data });
        //Get the product variants and store the first one in global product variant state
        dispatch({
          type: "updateCurrentProductVariant",
          data: res.data.product_variants[0]
        });
      }
    });
  }

  return (
    <main>
      <Helmet>
        <title>{currentProduct.product_name} | Flat Rate Metal</title>
      </Helmet>
      <ProductsNav />
      <div className="product-detail">
        <h1 className="product-detail-description__title hide-at-med">
          {currentProduct.product_name} {currentProduct.material_name}{" "}
          <span className="product-detail-desciption_title_span">
            {currentProduct.product_dimension}{" "}
            {currentProduct.product_type !== ""
              ? "| " + Service.htmlDecode(currentProduct.product_type)
              : ""}{" "}
            | {currentProductVariant.color_name}{" "}
            {currentProductVariant.product_variant_thickness
              ? "| " + currentProductVariant.product_variant_thickness
              : ""}
          </span>
        </h1>
        <div className="product-detail__product-cont">
          <ProductDetailImage />
          <ProductDetailInfo match={match} />
        </div>
      </div>
    </main>
  );
}

export default ProductDetail;
