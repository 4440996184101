import React from "react";
import image from "../../../images/construction.jpg";

function AdminHome() {
  return (
    <div className="admin-home col--12 col--lrg--10">
      <h1 className="admin-home__h1">Admin Home Page</h1>
      <p className="admin-home__p">
        Welcome to the Admin Home Page. Please select from the menu to get started.
      </p>
    </div>
  );
}

export default AdminHome;
