import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../../../App";
import Service from "../../../services/service";
import { convertDBDate, numCommas } from "../../../services/dates";
import Checkout from "./childComp/checkout/Checkout";
import CartProductList from "./childComp/CartProductList";
import { usePageView } from "../../../services/customHooks";
import { Helmet } from "react-helmet";
import { attachProductTotalToProducts } from "../../../services/calculateCartProducts";
import Loading from "../../reusable/notification/Loading";

function Cart({ history, location }) {
  const { cart, dispatch, accountData, order, cartProducts } =
    useContext(AppContext);
  const [address, setAddress] = useState({});
  const [showLoading, setShowLoading] = useState(true);
  usePageView(location.pathname);

  useEffect(() => {
    document.title = "Cart";
    Service.get(
      "?controller=cart&action=getAll&account_id=" +
        accountData.account_id +
        "&api_token=" +
        accountData.api_token,
      (res) => {
        if (res.status === "success") {
          setShowLoading(false);
          let products = attachProductTotalToProducts(
            accountData.account_type,
            res.data
          );
          dispatch({ type: "updateCartProducts", data: products });
        }
      }
    );
  }, [cart]);

  useEffect(() => {
    if (order.address_id) {
      Service.get(
        "?controller=address&action=getOne&account_id=" +
          accountData.account_id +
          "&api_token=" +
          accountData.api_token +
          "&address_id=" +
          order.address_id,
        (res) => {
          setAddress(res.data);
        }
      );
    }
  }, [order]);

  function placeOrder() {
    let purchase;
    console.log({
      ...order,
      products: cartProducts,
      account_id: accountData.account_id,
      api_token: accountData.api_token,
    });
    Service.post(
      "order",
      "create",
      {
        ...order,
        products: cartProducts,
        account_id: accountData.account_id,
        api_token: accountData.api_token,
        address_id: order.address_id,
      },
      (res) => {
        console.log(res);
      }
    );
    const email = `<table cellpadding='0' cellspaceing='0' border='0' style="border-collapse: collapse" style="width: 100%">
                            <tr>
                                <td>
                                    <table width="600" style="background-color: #F5F5F5; max-width: 600px;" cellpadding="0" cellspacing="0" border="0" align="center">
                                        <tr>
                                            <td style="background-color: #273130">
                                                <img width='200' src='http://staging.flatratemetal.com/serverAssets/FlatRateMetalLogo-03.png' />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td bgcolor="#F5F5F5" style="padding: 10px">
                                                <div>
                                                    <h1>Order Confirmation</h1>
                                                    <h2>Account</h2>
                                                    <ul style='list-style-type: none; padding: 0;'>
                                                        <li>Company: ${
                                                          accountData.account_company
                                                        }</li>
                                                        <li>Email: ${
                                                          accountData.account_email
                                                        }</li>
                                                        ${
                                                          accountData.account_phone
                                                            ? `<li>Phone: ${accountData.account_phone}</li>`
                                                            : ""
                                                        }
                                                        <li>Account Type: ${
                                                          accountData.account_type
                                                        }</li>
                                                        
                                                        ${
                                                          order.order_po
                                                            ? `<li>PO: ${order.order_po}</li>`
                                                            : ""
                                                        }
                                                    </ul>
                                                </div>
                                                
                                                <div>
                                                    <h2>Order Info</h2>
                                                    <ul style='list-style-type: none; padding: 0;'>
                                                    <li><span style="font-weight: bold">Order Placed</span>: ${new Date().toDateString()}</li>
                                                        <li><span style="font-weight: bold">Receive Method</span>: ${order.order_type.toUpperCase()}</li>
                                                        <li><span style="font-weight: bold">${
                                                          order.order_delivery_date
                                                            ? "Delivery By"
                                                            : "Pickup Available"
                                                        }</span>: ${convertDBDate(
      order.order_delivery_date || order.order_pickup_date
    )}</li>
                                                        ${
                                                          order.order_type ===
                                                          "delivery"
                                                            ? `<li><span style="font-weight: bold">Street Address:</span> ${address.address_street}</li>
                                                            <li><span style="font-weight: bold">City / State / Zip:</span> ${address.address_city} ${address.address_state}, ${address.address_zip}</li>`
                                                            : ``
                                                        }
                                                    </ul>
                                                </div>
                            
                                                <table align="center">
                                                    <tr>
                                                        <th style="padding: 10px">Product</th>
                                                        <th style="padding: 10px">Quantity</th>
                                                        <th style="padding: 10px">Cost</th>
                                                    </tr>
                                                    ${cartProducts
                                                      .map((product) => {
                                                        let productTotal;
                                                        if (
                                                          product.product_variant_special_price
                                                        ) {
                                                          productTotal =
                                                            product.product_variant_special_price *
                                                            product.cart_product_quantity;
                                                        } else if (
                                                          accountData.account_type ===
                                                          "dealer"
                                                        ) {
                                                          productTotal =
                                                            product.product_variant_dealer_price *
                                                            product.cart_product_quantity;
                                                        } else if (
                                                          accountData.account_type ===
                                                          "contractor"
                                                        ) {
                                                          productTotal =
                                                            product.product_variant_contractor_price *
                                                            product.cart_product_quantity;
                                                        } else {
                                                          productTotal =
                                                            product.product_variant_retail_price *
                                                            product.cart_product_quantity;
                                                        }
                                                        console.log(
                                                          numCommas(
                                                            parseFloat(
                                                              productTotal
                                                            ).toFixed(2)
                                                          )
                                                        );
                                                        return `<tr>
                                                                <td style="padding: 10px">${Service.htmlDecode(
                                                                  product.product_name
                                                                )} ${Service.htmlDecode(
                                                          product.material_name
                                                        )} ${Service.htmlDecode(
                                                          product.product_dimension
                                                        )} ${
                                                          product.product_type
                                                            ? Service.htmlDecode(
                                                                product.product_type
                                                              )
                                                            : ""
                                                        } ${
                                                          product.color_name
                                                            ? ", " +
                                                              product.color_name
                                                            : ""
                                                        } ${
                                                          product.product_variant_thickness
                                                            ? product.product_variant_thickness
                                                            : ""
                                                        }</td>
                                                                <td style="padding: 10px">${
                                                                  product.cart_product_quantity
                                                                } ${
                                                          product.bundle_name
                                                        }(s)</td>
                                                                <td style="padding: 10px">$${numCommas(
                                                                  parseFloat(
                                                                    productTotal
                                                                  ).toFixed(2)
                                                                )}</td>
                                                            </tr>`;
                                                      })
                                                      .join("")}
                                                    <tr>
                                                        <td></td>
                                                        <th>Subtotal:</th>
                                                        <td>$${numCommas(
                                                          parseFloat(
                                                            order.order_subtotal
                                                          ).toFixed(2)
                                                        )}</td>
                                                    </tr>
                                                    ${
                                                      order.order_tax
                                                        ? `<tr>
                                                        <td></td>
                                                        <th>Tax:</th>
                                                        <td>$${numCommas(
                                                          parseFloat(
                                                            order.order_tax
                                                          ).toFixed(2)
                                                        )}</td>
                                                    </tr>`
                                                        : ""
                                                    }
                                                    <tr>
                                                        <td></td>
                                                        <th>Total:</th>
                                                        <td>$${numCommas(
                                                          parseFloat(
                                                            order.order_total
                                                          ).toFixed(2)
                                                        )}</td>
                                                    </tr>
                                                </table>
                                            </td>
                                        </tr>                                        
                                            ${
                                              order.order_feedback
                                                ? `<tr>
                                                    <td style="padding: 10px">
                                                    <h2>Order Comments:</h2>
                                                    <p style="white-space: pre-line;
                                                    white-space: pre-wrap;
                                                    max-width: 320px;">${order.order_feedback}</p>
                                                </td>
                                            </tr>`
                                                : ""
                                            }                                                
                                            <tr>
                                            <td style="padding: 10px">
                                                <h2>For Order Inquires Contact Us:</h2>
                                                <ul style='list-style-type: none; padding: 0;'>
                                                    <li><span style="font-weight: bold">Phone: </span>208-516-4268</li>
                                                    <li><span style="font-weight: bold">Email: </span>orders@flatratemetal.com</li>
                                                </ul>
                                            </td>
                                        </tr>
                                    </table>
                                </td>
                            </tr>
                        </table>`;

    const feedbackEmail = `<table cellpadding='0' cellspaceing='0' border='0' style="border-collapse: collapse" style="width: 100%">
                            <tr>
                                <td>
                                    <table width="600" style="background-color: #F5F5F5; max-width: 600px;" cellpadding="0" cellspacing="0" border="0" align="center">
                                        <tr>
                                            <td style="background-color: #273130">
                                                <img width='200' src='http://staging.flatratemetal.com/serverAssets/FlatRateMetalLogo-03.png' />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td bgcolor="#F5F5F5" style="padding: 10px">
                                                <div>
                                                    <h1>Feedback Requested</h1>
                                                    <p>Thank you, ${Service.htmlDecode(
                                                      accountData.account_company
                                                    )} for your recent order. We appreciate your business!</p>
                                                    <p>We are constantly striving to improve our online services to meet your needs. Feedback about your experience is critical for making that happen. Please respond to this email with anything that you think we could do to improve, as well as anything you really like about the application. The more detailed you can be, the better. We thank you in advance for helping us to improve our online services.</p>
                                                    <p>We look forward to serving you again soon! If you would like to give your feedback over the phone, please contact us below.</p>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style="padding: 10px">
                                                <h2>For Contact Us:</h2>
                                                <ul style='list-style-type: none; padding: 0;'>
                                                    <li><span style="font-weight: bold">Phone: </span>208-516-4268</li>
                                                    <li><span style="font-weight: bold">Email: </span>orders@flatratemetal.com</li>
                                                </ul>
                                            </td>
                                        </tr>
                                    </table>
                                </td>
                            </tr>
                        </table>`;

    dispatch({ type: "updateCart", data: [] });
    Service.post(
      "email",
      "send",
      {
        api_token: accountData.api_token,
        email_name: "Flat Rate Metal",
        email_sender: "orders@flatratemetal.com",
        email_recipient: accountData.account_email,
        email_subject: "Your Order Has been placed",
        email_body: email,
      },
      (res) => {
        console.log(email);
        if (res.status === "success") {
          dispatch({
            type: "updateNotification",
            data: { open: true, message: "Email sent", status: "success" },
          });
        }
      }
    );

    Service.post(
      "email",
      "send",
      {
        api_token: accountData.api_token,
        email_name: Service.htmlDecode(accountData.account_company),
        email_sender: "orders@flatratemetal.com",
        email_recipient: "orders@flatratemetal.com",
        email_subject: `New Order - ${
          order.order_type +
          " " +
          Service.htmlDecode(accountData.account_company)
        }`,
        email_body: email,
      },
      (res) => {
        if (res.status === "success") {
          dispatch({
            type: "updateNotification",
            data: { open: true, message: "Email sent", status: "success" },
          });
        }
      }
    );

    // Service.post(
    //   "email",
    //   "send",
    //   {
    //     api_token: accountData.api_token,
    //     email_name: "Flat Rate Metal",
    //     email_sender: "orders@flatratemetal.com",
    //     email_recipient: accountData.account_email,
    //     email_subject: "Feedback Request - Flat Rate Metal",
    //     email_body: feedbackEmail
    //   },
    //   res => {
    //     if (res.status === "success") {
    //       dispatch({
    //         type: "updateNotification",
    //         data: { open: true, message: "Email sent", status: "success" }
    //       });
    //     }
    //   }
    // );
  }

  return (
    <main>
      <Helmet>
        {/* Would not work with javascript expression inside title */}
        <title>Cart {/* cart.length */} | Flat Rate Metal</title>
      </Helmet>
      <section className="cart__container">
        <div className="col--12">
          <div className="cart__price__disclaimer">
            Due to Market Volatility, prices and availability are subject to
            change. Order now to lock in your price.
          </div>
          <h1 className="cart__title">Shopping Cart</h1>
          {showLoading ? <Loading /> : ""}
        </div>
        {showLoading ? (
          ""
        ) : (
          <>
            <CartProductList
              cartProducts={cartProducts}
              showLoading={showLoading}
            />
            <Checkout
              cartProducts={cartProducts}
              history={history}
              placeOrder={placeOrder}
              showLoading={showLoading}
            />
          </>
        )}
      </section>
    </main>
  );
}

export default Cart;
