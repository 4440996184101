import React, { useState, useEffect } from 'react';
import Service from '../../../../services/service';

function AccountAddressList({ currentAccount, accountData, dispatch }) {
    const [addresses, setAddresses] = useState([]);
    const [newAddress, setNewAddress] = useState({ address_street: '', addressLine2: '', address_city: '', address_state: '', address_zip: '' });
    useEffect(() => {
        getAddresses();
    }, []);
    function getAddresses() {
        Service.get("?controller=address&action=getAll&account_id=" + currentAccount.account_id + "&api_token=" + accountData.api_token,
            res => {
                if (res.status === 'success') {
                    setAddresses(res.data);
                }
            });
    }
    function updateAddress(e) {
        const value = e.target.value;
        const name = e.target.name;
        setNewAddress({ ...newAddress, [name]: value });
    }
    function addAddress(e) {
        e.preventDefault();
        Service.post('address', 'create', {
            ...newAddress,
            account_id: currentAccount.account_id,
            api_token: accountData.api_token
        }, res => {
            dispatch({ type: 'updateNotification', data: { open: true, message: res.message, status: res.status } });
            getAddresses();
            if (res.status = 'success') {
                setNewAddress({ address_street: '', address_city: '', address_state: '', address_zip: '' });
            }
        });
    }
    function deleteAddress(e) {
        Service.delete('address', 'delete', {
            address_id: e.target.value,
            api_token: accountData.api_token,
            account_id: currentAccount.account_id
        }, res => {
            dispatch({ type: 'updateNotification', data: { open: true, message: res.message, status: res.status } });
            if (res.status === 'success') {
                getAddresses();
            }
        });
    }
    return (
        <section>
            <form className="form--grid" onSubmit={addAddress}>
                <div className="field col--12">
                    <h2>Add Address</h2>
                </div>
                <div className="field col--12">
                    <label className="label">Address Line 1</label>
                    <input type="text" required name="address_street" value={newAddress.address_street} onChange={updateAddress} className="input full" />
                </div>
                <div className="field col--12 col--med--4">
                    <label className="label">City</label>
                    <input type="text" required name="address_city" value={newAddress.address_city} onChange={updateAddress} className="input full" />
                </div>
                <div className="field col--6 col--med--4">
                    <label className="label">State</label>
                    <select required name="address_state" value={newAddress.address_state} onChange={updateAddress} className="input full">
                        <option value="">- Select -</option>
                        <option value="idaho">Idaho</option>
                        <option value="wyoming">Wyoming</option>
                        <option value="montana">Montana</option>
                        <option value="utah">Utah</option>
                    </select>
                </div>
                <div className="field col--6 col--med--4">
                    <label className="label">Zipcode</label>
                    <input required onChange={updateAddress} value={newAddress.address_zip} name="address_zip" type="text" className="input full" />
                </div>
                <div className="field col--12">
                    <button type="submit" className="btn--confirm">Add</button>
                </div>
            </form>

            <div className="account-address__list-cont">
            <h2>Current Addresses</h2>
            <div className="account-address__list">
                {addresses.map(address => (
                    <address className="account-address" key={address.address_id}>
                        <div className="account-address__line">{address.address_street}</div>
                        <div className="account-address__line">{address.address_city + " " + address.address_state + ", " + address.address_zip}</div>
                        <div className="field account-address__field">
                            <button value={address.address_id} onClick={deleteAddress} className="btn--danger">Delete</button>
                        </div>
                    </address>
                ))}
            </div>
            </div>

        </section>
    );
}

export default AccountAddressList;