import React from 'react';

function AboutUs() {
    
    return(
        <div className="about-us">
            <div className="about-us__container">
            <h2 className="about-us__heading">About Us</h2>
            <p className="about-us__paragraph">Flat Rate Metal has quality roofing materials.</p>
            </div>
        </div>
    );

}

export default AboutUs;