import React from 'react';
import AdminProductVariantCard from './AdminProductVariantCard';

function AdminProductVariants({colors, createProductVariant, alterProductVariant, updateProductVariant, product, productVariant, deleteProductVariant, alterCurrentProductVariant, setToggleVariantForm, toggleVariantForm}) {

    // console.log(product.product_variants);

    return(
       <div className="admin-product-variants">
           <h2>Product Variants</h2>
           <div className="admin-product-variants-cont">
           {
               product.product_variants.length === 0
               ?
               <div style={{textAlign: "center"}}>
               <h3>No Product Variants Created!</h3>
               <p style={{color: "dodgerBlue", cursor: "pointer"}} onClick={createProductVariant}>Click Here to Create One</p>
               </div>
               :
               product.product_variants.map(prodVar => 
                <AdminProductVariantCard
                key={prodVar.product_variant_id}
                colors={colors}
                alterProductVariant={alterProductVariant}
                updateProductVariant={updateProductVariant}
                product={product}
                productVariant={prodVar}
                deleteProductVariant={deleteProductVariant}
                alterCurrentProductVariant={alterCurrentProductVariant}
                setToggleVariantForm = {setToggleVariantForm}
                toggleVariantForm = {toggleVariantForm}
                />
               )
           }
        </div>
        </div>
    );
}

export default AdminProductVariants;