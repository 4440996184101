import React from "react";
import AccountLocationCard from "./AccountLocationCard";

function AccountLocationList({ addresses }) {
  return (
    <div className="account-location-list">
      <div className="account-location-title-cont">
        <h3 className="account-location-title">Your Locations</h3>
      </div>
      {addresses[0] ? (
        addresses.map(address => (
          <AccountLocationCard address={address} key={address.addressId} />
        ))
      ) : (
        <>
          <p className="account-location-list__p">
            There are no locations assigned to your account.
          </p>
          <p className="account-location-list__p">Please add a location.</p>
        </>
      )}
    </div>
  );
}

export default AccountLocationList;
