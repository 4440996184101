import React, { useEffect, useState, useContext } from 'react';
import Service from '../../../../../../services/service';
import {AppContext} from '../../../../../../App';

function AdminAccountSpecialPricingModal({ setToggleModal, isEditing, setIsEditing, updateSpecialPrice, setChange, change, getSpecialPricing }) {
    
    const [categories, setCategories] = useState([]);
    const [products, setProducts] = useState([]);
    const [product, setProduct] = useState([]);
    const [price, setPrice] = useState("");
    const [variantId, setVariantId] = useState();

    const {accountData, currentAccount, currentSpecialPrice} = useContext(AppContext);
    
    function saveSpecialPrice(e) {
        e.preventDefault();
        setToggleModal(false);
        Service.post("specialPrice", "create", {
            account_id: currentAccount.account_id,
            api_token: accountData.api_token,
            product_variant_id: variantId,
            account_has_product_price: price
        }, (res) => {
            console.log(res);
            if (res.status === "success") {
                getSpecialPricing();
                setToggleModal(false);
            }
        })
    }

    useEffect(() => {
        Service.get("?controller=category&action=getAll", (res) => {
            if (res.status === "success") {
                setCategories(res.data);
            }
        })

        getProductByVariantId(currentSpecialPrice.product_variant_id);

    }, []);

    function getProductByVariantId(id) {
        Service.get("?controller=product&action=getProductByVariantId&product_variant_id=" + id + "&api_token=" + accountData.api_token,
        (res) => {
            if (res.status === "success") {
                setProduct(res.data);
            }
        })
    }


    function getProducts(catId) {
        Service.get("?controller=product&action=getVariantsByCategory&category_id=" + catId + "&api_token=" + accountData.api_token, (res) => {
         if (res.status === "success") {
             console.log(res.data);
             setProducts(res.data);
         }
        })
    }

    return (
        <div className="admin-account-special-pricing-modal">
            <div className="admin-account-special-pricing-modal__card">
            <form onSubmit={(e) => {isEditing ? updateSpecialPrice(e, currentSpecialPrice.account_has_product_id, price) : saveSpecialPrice(e)}} className="admin-account-special-pricing-modal__form">
                {
                    isEditing ?
                    <h2 className="admin-account-special-pricing-modal__h4-product-name"> {product.product_name} {product.material_name} {Service.htmlDecode(product.product_dimension)} {Service.htmlDecode(product.product_type)} {product.color_name} {product.product_variant_thickness}</h2>
                    :
                    <>
                    <section className="admin-account-special-pricing-modal__section field">
                        <label className="admin-account-special-pricing-modal__label label">Category:</label>
                        <select name="" id="" className="admin-account-special-pricing-modal__select input" disabled={isEditing} onChange={(e) => getProducts(e.target.value)}>
                            <option value="" className="admin-account-special-pricing-modal__option">- Select -</option>
                            {
                                categories.map((category, index) =>
                                    <option value={category.category_id} key={index} className="admin-account-special-pricing-modal__option">{category.category_name}</option>
                                )
                            }
                        </select>
                    </section>
                    <section className="admin-account-special-pricing-modal__section field">
                        <label className="admin-account-special-pricing-modal__label label">Product:</label>
                        <select name="" id="" className="admin-account-special-pricing-modal__select input" disabled={isEditing} onChange={(e) => setVariantId(e.target.value)}>
                            <option value="" className="admin-account-special-pricing-modal__option">- Select -</option>
                            {
                                products.map((product, index) =>
                                    product.product_variants.map((variant, index) =>
                                        <option value={variant.product_variant_id} key={index} className="admin-account-special-pricing-modal__option">{product.product_name} {product.material_name} {Service.htmlDecode(product.product_dimension)} {product.product_type ? Service.htmlDecode(product.product_type) : ""}, {variant.color_name} {variant.product_variant_thickness}</option>
                                    )
                                )
                            }
                        </select>
                    </section>
                    </>
                }
                <section className="admin-account-special-pricing-modal__section field">
                    <label className="admin-account-special-pricing-modal__label label">Custom Price:</label>
                    <input type="text" className="admin-account-special-pricing-modal__input input" defaultValue={isEditing ? currentSpecialPrice.account_has_product_price : ""} onChange={(e) => setPrice(e.target.value)} />
                </section>
                <section className="admin-account-special-pricing-modal__section field">
                    <button type="submit" className="admin-account-special-pricing-modal__button btn--confirm">Save</button>
                    <button type="button" onClick={() => {setToggleModal(false); setIsEditing(false)}} className="admin-account-special-pricing-modal__button btn--danger">Cancel</button>
                </section>
            </form>
            </div>
        </div>
    );
}

export default AdminAccountSpecialPricingModal;