import React, { useContext, useState } from "react";
import { AppContext } from "../../../App";

export default function HeaderAlert() {
  const [display, setDisplay] = useState(true);
  const { accountData } = useContext(AppContext);
  if (display && accountData.isLoggedIn) {
    return (
      <div className="header-alert">
        <div className="header-alert__text">
          Prices are subject to change. Order now to lock in your price.
        </div>
        <button
          className="header-alert__close"
          onClick={() => setDisplay(false)}
        >
          <i className="header-alert__close-icon fas fa-times" />
        </button>
      </div>
    );
  } else {
    return <></>;
  }
}
