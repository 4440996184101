import React, { useContext } from "react";
import { Link } from "react-router-dom";

//Dates functions
import {
  numCommas,
  checkDate,
  isWeekday,
  addDays,
  addBusinessDays,
  setDBDate,
  convertDBDate
} from "../../../../../../services/dates";
import { AppContext } from "../../../../../../App";

//Displays the Delivery informations and Address selection
export function DeliveryOption({ receive, addresses }) {
  const { dispatch, order } = useContext(AppContext);

  return (
    <>
      {receive === "delivery" ? (
        <div className="field checkout__delivery-option-cont">
          <div className="checkout__existing-address-cont">
            <label className="label">Select an Address</label>
            {addresses.map((address, index) => {
              const area =
                address.address_state === "idaho" &&
                (address.address_zip === "83401" ||
                  address.address_zip === "83402" ||
                  address.address_zip === "83404" ||
                  address.address_zip === "83406" ||
                  address.address_zip === "83427" ||
                  address.address_zip === "83274");
              return (
                <div
                  className="checkout__address-container"
                  key={address.address_id}
                >
                  <div className="checkout__radio-address-cont">
                  <input
                    type="radio"
                    required
                    className="checkout__address-radio-input"
                    value={address.address_id}
                    checked={order.address_id === address.address_id ? true : false}
                    id={"address__radio" + address.address_id}
                    name="address-select"
                    onChange={e => {
                      dispatch({
                        type: "updateOrder",
                        data: {
                          ...order,
                          address_id: e.target.value,
                          order_delivery_date: area
                            ? setDBDate(addBusinessDays(new Date(), 1))
                            : setDBDate(addBusinessDays(new Date(), 10))
                        }
                      });
                    }}
                  />
                  <label htmlFor={"address__radio" + address.address_id} className="checkout__address-radio-btn"></label>
                  <label className="checkout__address-input">
                    {address.address_street}, {address.address_city}
                  </label>
                  </div>
                  {area ? (
                    <p className="checkout__address-delivery-time">
                      Delivery: Next Business Day by 4:00pm
                    </p>
                  ) : (
                    <p className="checkout__address-delivery-time">
                      Delivery: Within 3-10 Business Days
                    </p>
                  )}
                  <br />
                </div>
              );
            })}
            <div className="checkout__add-address">
              <Link to="/account-settings">Add an address</Link>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
}
