import React, { useContext } from "react";
import { AppContext } from "../../../../../../App";
import { DeliveryOption } from "./DeliveryOption";
import {
  PickupDeliveryType,
  PickUpImmediate,
  PickUpScheduled
} from "./PickupOptions";
import { addBusinessDays } from "../../../../../../services/dates";

export default function CheckoutForm({
  confirmCheckout,
  setReceive,
  setPickup,
  receive,
  pickup,
  addresses
}) {
  const { dispatch, order, cart } = useContext(AppContext);

  /**
   * Set Order Type has replaced 4 other methods
   * it is executed onClick and updates the order
   * according to the options selected
   * @param {object} e
   */
  function setOrderType(e) {
    let recieveMethod = e.target.dataset.recieveMethod;
    let pickupMethod = e.target.dataset.pickupMethod;
    let pickupDate = null;
    setReceive(recieveMethod);
    if (recieveMethod !== "pickup") {
      setPickup("");
    }
    if (pickupMethod) {
      setPickup(pickupMethod);
      if (pickupMethod === "immediate") {
        pickupDate = new Date();
      } else {
        pickupDate = addBusinessDays(new Date(), 1);
      }
    }
    dispatch({
      type: "updateOrder",
      data: {
        ...order,
        order_type: recieveMethod,
        order_delivery_date:
          recieveMethod === "pickup" ? null : order.order_delivery_date,
        order_pickup_date: pickupDate
      }
    });
  }

  //check Checkout and Cart
  function checkCartFields() {
    if (
      cart.length > 0 &&
      (receive !== "" || pickup !== "") &&
      (pickup === "immediate" ||
        pickup === "scheduled" ||
        order.address_id !== null)
    ) {
      return false;
    } else {
      return true;
    }
  }

  return (
    <form action="" className="form" onSubmit={confirmCheckout}>
      <span>Select Option:</span>
      <br />
      <div className="field checkout__radial-button-cont">
        <div className="checkout__radial-button-sleeve">
          <input
            type="radio"
            id="pickup-btn"
            data-recieve-method="pickup"
            className="input--radio"
            name="deliveryType"
            onClick={setOrderType}
          />
          <label htmlFor="pickup-btn" className="checkout__button-label">
            Pickup
          </label>
        </div>
        <div className="checkout__radial-button-sleeve">
          <input
            type="radio"
            id="delivery-btn"
            className="input--radio"
            name="deliveryType"
            onClick={setOrderType}
            data-recieve-method="delivery"
          />
          <label htmlFor="delivery-btn" className="checkout__button-label">
            Delivery
          </label>
        </div>
      </div>
      <PickupDeliveryType
        receive={receive}
        setOrderType={setOrderType}
        setPickup={setPickup}
      />
      <PickUpImmediate pickup={pickup} />
      <PickUpScheduled pickup={pickup} />
      <DeliveryOption receive={receive} addresses={addresses} />
      <div className="field">
        <label htmlFor="" className="label">
          PO Number:
        </label>
        <input
          className="input full"
          onChange={e =>
            dispatch({
              type: "updateOrder",
              data: { ...order, order_po: e.target.value }
            })
          }
          type="text"
        />
      </div>
      <div className="field">
        <label htmlFor="" className="label">
          Order Comments:
        </label>
        <textarea
          className="input full checkout__feedback-textarea"
          onChange={e =>
            dispatch({
              type: "updateOrder",
              data: { ...order, order_feedback: e.target.value }
            })
          }
          type="text"
          placeholder="Have a product you would like us to carry?"
        />
      </div>
      <div className="field">
        <button
          disabled={checkCartFields()}
          className="btn--confirm"
          type="submit"
        >
          Checkout
        </button>
      </div>
    </form>
  );
}
