import React, { useState, useEffect, useRef } from "react";
import Service from "../../../../../services/service";
// Email hooks import
import useRegistrationEmailTemplate from "../../services/useRegistrationEmailTemplate.hook";
import useEmail from "../../services/useEmail.hook";
// Child Component
import AccountAddressCreation from "./childComp/accountAddressCreation";

function AdminAccountEditAccount({
  currentAccount,
  accountData,
  history,
  dispatch
}) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [company, setCompany] = useState("");
  const [phone, setPhone] = useState("");
  const [initialAddress, setInitialAddress] = useState({});
  const [account_type, setAccountType] = useState("retail");
  const [toggle, setToggle] = useState(false);
  const filter = Service.htmlDecode;
  const phoneInput = useRef(null);
  // Email Hooks
  const emailTemplate = useRegistrationEmailTemplate({
    email: email,
    account_type: account_type,
    phone: phone,
    password: password,
    company: company
  });
  const sendEmail = useEmail(emailTemplate, {
    api_token: accountData.api_token,
    sender: "orders@flatratemetal.com",
    name: "Flat Rate Metal",
    subject: "Account Confirmation",
    recipient: email
  });

  const sendAdminEmail = useEmail(emailTemplate, {
    api_token: accountData.api_token,
    sender: "orders@flatratemetal.com",
    name: "New Account Registered",
    subject: "Account Confirmation",
    recipient: "orders@flatratemetal.com"
  });

  useEffect(() => {
    if (currentAccount.currentAccountEditing) {
      setEmail(currentAccount.account_email);
      setCompany(filter(currentAccount.account_company));
      setPhone(currentAccount.account_phone);
      setAccountType(currentAccount.account_type);
    }
    return () =>
      dispatch({
        type: "updateCurrentAccount",
        data: { currentAccountEditing: false }
      });
  }, [
    currentAccount.account_email,
    currentAccount.account_company,
    currentAccount.account_phone,
    currentAccount.account_type,
    currentAccount.currentAccountEditing,
    dispatch,
    filter
  ]);

  function registerDistributor(e) {
    e.preventDefault();
    if (account_type === "admin") {
      Service.post(
        "account",
        "create",
        {
          account_company: company,
          account_email: email,
          account_phone: phone,
          account_password: password,
          account_type: account_type,
          api_token: accountData.api_token
        },
        res => {
          dispatch({
            type: "updateNotification",
            data: {
              open: true,
              status: res.status,
              message: res.message
            }
          });
          sendEmail();
          history.push("/admin-portal/admin-accounts");
        }
      );
    } else {
      Service.post(
        "account",
        "createWithAddress",
        {
          account_company: company,
          account_email: email,
          account_phone: phone,
          account_password: password,
          account_type: account_type,
          api_token: accountData.api_token,
          ...initialAddress
        },
        res => {
          dispatch({
            type: "updateNotification",
            data: {
              open: true,
              status: res.status,
              message: res.message
            }
          });
          sendEmail();
          sendAdminEmail();
          history.push("/admin-portal/admin-accounts");
        }
      );
    }
  }

  function updateDistributor(e) {
    e.preventDefault();
    fetch(Service.domain, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json"
      },
      body: JSON.stringify({
        controller: "account",
        action: "update",
        payload: {
          account_company: company,
          account_id: currentAccount.account_id,
          account_email: email,
          account_phone: phone,
          account_password: password,
          account_type: account_type,
          api_token: accountData.api_token
        }
      })
    })
      .then(res => res.json())
      .then(res => {
        setPassword("");
        dispatch({
          type: "updateNotification",
          data: { open: true, status: res.status, message: res.message }
        });
        if (res.status === "success") {
          setEmail("");
          history.push("/admin-portal/admin-accounts");
          dispatch({
            type: "updateNotification",
            data: { open: true, status: res.status, message: res.message }
          });
        }
      });
  }

  function formatPhone(e) {
    if (e.key === "Backspace" || e.key === "Deletes") {
      setPhone(e.target.value);
      return;
    }
    if (e.target.value.length === 3) {
      phoneInput.current.value = e.target.value + "-";
    }
    if (e.target.value.length === 7) {
      phoneInput.current.value = e.target.value + "-";
    }
    setPhone(e.target.value);
  }

  return (
    <form
      className="form--grid"
      onSubmit={
        currentAccount.currentAccountEditing
          ? updateDistributor
          : registerDistributor
      }
    >
      <h2 className="col--12">
        {currentAccount.currentAccountEditing ? "Edit " : "Create "}Account
      </h2>
      <div className="field col--12 col--sml--6 col--med--4">
        <label className="label" htmlFor="">
          Company Name
        </label>
        <input
          className="input full"
          required={currentAccount.currentAccountEditing ? false : true}
          defaultValue={company}
          onChange={e => setCompany(e.target.value)}
          type="text"
        />
      </div>
      <div className="field col--12 col--sml--6 col--med--4">
        <label className="label" htmlFor="">
          Email
        </label>
        <input
          className="input full"
          required={currentAccount.currentAccountEditing ? false : true}
          defaultValue={email}
          onChange={e => setEmail(e.target.value)}
          type="email"
        />
      </div>
      <div className="field col--12 col--sml--6 col--med--4 password__container">
        <label className="label" htmlFor="">
          {currentAccount.currentAccountEditing ? "Update " : ""}Password
          <input
            className="input full"
            required={currentAccount.currentAccountEditing ? false : true}
            defaultValue={password}
            onChange={e => setPassword(e.target.value)}
            type={toggle ? "text" : "password"}
          />
        </label>
        <span
          className="btn--copper pass__mask"
          onClick={() => setToggle(!toggle)}
        >
          {toggle ? "Hide" : "Show"}
        </span>
      </div>
      <div className="field col--12 col--sml--6 col--med--6">
        <label className="label" htmlFor="">
          Phone <small>(format: 208-123-1234)</small>
        </label>
        <input
          className="input full"
          ref={phoneInput}
          maxLength="12"
          defaultValue={phone}
          onKeyUp={formatPhone}
          type="tel"
          pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
        />
      </div>
      <div className="field col--12 col--sml--6 col--med--6">
        <label className="label" htmlFor="">
          Account Type
        </label>
        <select
          required
          className="input full"
          value={account_type}
          name=""
          id=""
          onChange={e => setAccountType(e.target.value)}
        >
          <option value="retail">Retail</option>
          <option value="contractor">Contractor</option>
          <option value="dealer">Dealer</option>
          <option value="admin">Admin</option>
        </select>
      </div>

      {!currentAccount.currentAccountEditing && account_type !== "admin" ? (
        <AccountAddressCreation
          history={history}
          setInitialAddress={setInitialAddress}
        />
      ) : (
        <div className="field col--12">
          <button
            className="btn--danger"
            type="button"
            onClick={() => history.goBack()}
          >
            Back
          </button>
          <button className="btn--confirm" type="submit">
            Submit
          </button>
        </div>
      )}
    </form>
  );
}

export default AdminAccountEditAccount;
