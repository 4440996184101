import React, { useState, useContext, useEffect } from 'react';
import { AppContext } from "../../../../../App";
import AdminAccountSpecialPricingCard from './childComp/AdminAccountSpecialPricingCard';
import Service from '../../../../../services/service';
import AdminAccountSpecialPricingModal from './childComp/AdminAccountSpecialPricingModal';

function AdminAccountSpecialPricing() {

    const { accountData, currentAccount } = useContext(AppContext);
    const [specialPrices, setSpecialPrices] = useState([]);
    const [toggleModal, setToggleModal] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [change, setChange] = useState(0);

    useEffect(() => {
        getSpecialPricing();
    }, []);

    function getSpecialPricing() {
        Service.get("?controller=specialPrice&action=getAll&account_id=" + currentAccount.account_id + "&api_token=" + accountData.api_token,
            (res) => {
                if (res.status === "success") {
                    setSpecialPrices(res.data);
                    console.log(res.data);
                } else {
                    setSpecialPrices([]);
                }
            });
    }

    function deleteSpecialPrice(id) {
        Service.delete('specialPrice', 'delete', {
            account_has_product_id: id,
            api_token: accountData.api_token
        }, (res) => {
            if (res.status === "success") {
                getSpecialPricing();
            }
        })
    }

    function updateSpecialPrice(e, id, price) {
        if (price) {
            e.preventDefault();
            Service.put('specialPrice', 'update', {
                account_has_product_id: id,
                account_has_product_price: price,
                api_token: accountData.api_token
            }, (res) => {
                console.log(res);
                if (res.status === "success") {
                    getSpecialPricing();
                    setToggleModal(false);
                }
            })
        } else {
            setToggleModal(false);
        }

    }

    return (
        <div className="admin-account-special-pricing">
            <section className="admin-account-special-pricing__title">
                <h2 className="admin-account-special-pricing__h2">Account Special Pricing</h2>
            </section>
            <section className="admin-account-special-pricing__cont">
                {
                    specialPrices.map(specialPrice =>
                        <AdminAccountSpecialPricingCard
                            key={specialPrice.account_has_product_id}
                            setToggleModal={setToggleModal}
                            specialPrice={specialPrice}
                            deleteSpecialPrice={deleteSpecialPrice}
                            updateSpecialPrice={updateSpecialPrice}
                            setIsEditing={setIsEditing} />
                    )
                }
            </section>
            {toggleModal ? <AdminAccountSpecialPricingModal getSpecialPricing={getSpecialPricing} change={change} setChange={setChange} setToggleModal={setToggleModal} isEditing={isEditing} setIsEditing={setIsEditing} updateSpecialPrice={updateSpecialPrice} setSpecialPrices={setSpecialPrices} /> : ''}
            <button className="admin-account-special-pricing__btn btn--confirm" onClick={() => setToggleModal(true)}>Add</button>
        </div>

    );

}

export default AdminAccountSpecialPricing;