import React, { useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import CacheManager from "../../../services/cacheManager";
import { AppContext } from "../../../App";
import WSRA from '../../../images/WSRCA_logo.png';
function Footer() {
  const { categories, dispatch } = useContext(AppContext);
  useEffect(() => {
    CacheManager.cacheCheck(
      "categories",
      categories,
      "updateCategories",
      dispatch,
      "?controller=category&action=getAll"
    );
  }, []);
  return (
    <footer className="footer">
      <section className="footer__section">
        <FooterLinkGroup title="Flat Rate Metal">
          <FooterLink title="Products" path="/products" />
          <FooterLink title="Login" path="/dist-login" />
        </FooterLinkGroup>
        <FooterLinkGroup title="Product Categories">
          {categories.map((cat) => (
            <FooterLink
              key={cat.category_id}
              title={cat.category_name}
              path={`/products/${cat.category_name}`}
            />
          ))}
        </FooterLinkGroup>
        <FooterLinkGroup title="Contact Us">
          <FooterIconLink
            title="orders@flatratemetal.com"
            path="mailto:orders@flatratemetal.com"
            external={true}
          >
            <i className="fas fa-envelope" />
          </FooterIconLink>
          <FooterIconLink
            title="(208) 516-4268"
            path="tel:2085164268"
            external={true}
          >
            <i className="fas fa-phone-alt" />
          </FooterIconLink>
          {/*<FooterIconLink title="Facebook" path="mailto:orders@flatratemetal.com" external={true}>
                        <i className="fab fa-facebook-square"/>
                    </FooterIconLink>*/}
        </FooterLinkGroup>
        <FooterGroup title="Locations">
          <a
            className="footer-address__link"
            href="https://www.google.com/maps/place/570+W+19th+St,+Idaho+Falls,+ID+83402/@43.481142,-112.044883,16z/data=!4m5!3m4!1s0x5354593fb46301ab:0xbdf3fa29dd00c3e3!8m2!3d43.4811421!4d-112.0448833?hl=en"
          >
            <address className="footer-address">
              570 W 19th St. <br />
              Idaho Falls, ID 83402
            </address>
          </a>
        </FooterGroup>
        <div className="footer__wsra-cont">
          <img src={WSRA} alt="Western States Roofing Association Logo" className="footer__wsra-image"/>
        </div>
      </section>

      <ul className="footer__fine-print">
        <li className="footer__fine-print-item">
          {" "}
          Copyright &copy; {new Date().getFullYear()}{" "}
        </li>
        <li className="footer__fine-print-seperator"> | </li>
        <li className="footer__fine-print-item"> Flat Rate Metal </li>
        <li className="footer__fine-print-seperator"> | </li>
        <li className="footer__fine-print-item"> All Rights Reserved </li>
      </ul>
    </footer>
  );
}

function FooterLinkGroup({ title, children }) {
  return (
    <div className="footer-link-group">
      <h3 className="footer-link-group__h3">{title}</h3>
      <ul className="footer-link-group__ul">{children}</ul>
    </div>
  );
}

function FooterGroup({ title, children }) {
  return (
    <div className="footer-link-group">
      <h3 className="footer-link-group__h3">{title}</h3>
      <div className="footer-group">{children}</div>
    </div>
  );
}

function FooterLink({ path, title }) {
  return (
    <li className="footer-link">
      <Link className="footer-link__link" to={path}>
        {title}
      </Link>
    </li>
  );
}

function FooterIconLink({ path, title, children, external = false }) {
  return (
    <li className="footer-link">
      {external ? (
        <a className="footer-link__link" href={path}>
          {children} {title}
        </a>
      ) : (
        <Link className="footer-link__link" to={path}>
          {children} {title}
        </Link>
      )}
    </li>
  );
}

export default Footer;
