import React, { useContext, useState, useEffect } from "react";
import { AppContext } from "../../../App";
import Service from "../../../services/service";
import { Link } from "react-router-dom";

function AdminOptionsForm() {
  const { dispatch, accountData } = useContext(AppContext);

  const [material_name, setMaterialName] = useState("");
  const [category_name, setCategoryName] = useState("");
  const [bundle_name, setBundleName] = useState("");
  const [color_name, setColorName] = useState("");
  const [color_hex, setColorHex] = useState("");
  const [colors, setColors] = useState([]);
  const [categories, setCategories] = useState([]);
  const [materials, setMaterials] = useState([]);
  const [bundles, setBundles] = useState([]);

  const message1 = "All products with that material will be deleted!";
  const message2 = "All products with that category will be deleted!";
  const message4 = "All products with that bundle type will be deleted!";
  const message3 =
    "All product variants with that bundle type will be deleted!";

  useEffect(() => {
    document.title = "Materials Categories and Colors";
  });

  function AddMaterial(e) {
    e.preventDefault();
    if (material_name !== "") {
      fetch(Service.domain, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json"
        },
        body: JSON.stringify({
          controller: "material",
          action: "create",
          payload: {
            material_name: material_name,
            api_token: accountData.api_token
          }
        })
      })
        .then(res => res.json())
        .then(res => {
          dispatch({
            type: "updateNotification",
            data: { open: true, status: res.status, message: res.message }
          });
          if (res.status === "success") {
            setMaterialName("");
            getAllMaterials();
          }
        });
    }
  }

  function AddCategory(e) {
    e.preventDefault();
    if (category_name !== "") {
      fetch(Service.domain, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json"
        },
        body: JSON.stringify({
          controller: "category",
          action: "create",
          payload: {
            category_name: category_name,
            api_token: accountData.api_token
          }
        })
      })
        .then(res => res.json())
        .then(res => {
          dispatch({
            type: "updateNotification",
            data: { open: true, status: res.status, message: res.message }
          });
          if (res.status === "success") {
            setCategoryName("");
            getAllCategories();
          }
        });
    }
  }

  function AddBundle(e) {
    e.preventDefault();
    if (bundle_name !== "") {
      fetch(Service.domain, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json"
        },
        body: JSON.stringify({
          controller: "bundle",
          action: "create",
          payload: {
            bundle_name: bundle_name,
            api_token: accountData.api_token
          }
        })
      })
        .then(res => res.json())
        .then(res => {
          dispatch({
            type: "updateNotification",
            data: { open: true, status: res.status, message: res.message }
          });
          if (res.status === "success") {
            setBundleName("");
            getAllBundles();
          }
        });
    }
  }

  function AddColor(e) {
    e.preventDefault();
    if (color_name !== "" && color_hex !== "") {
      fetch(Service.domain, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json"
        },
        body: JSON.stringify({
          controller: "color",
          action: "create",
          payload: {
            color_name: color_name,
            color_hex: "#" + color_hex,
            api_token: accountData.api_token
          }
        })
      })
        .then(res => res.json())
        .then(res => {
          dispatch({
            type: "updateNotification",
            data: { open: true, status: res.status, message: res.message }
          });
          if (res.status === "success") {
            setColorHex("");
            setColorName("");
            getAllColors();
          }
        });
    }
  }

  function deleteMaterial(id) {
    fetch(Service.domain, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json"
      },
      body: JSON.stringify({
        controller: "material",
        action: "delete",
        payload: {
          material_id: id,
          api_token: accountData.api_token,
          account_type: accountData.account_type
        }
      })
    })
      .then(res => res.json())
      .then(res => {
        dispatch({
          type: "updateNotification",
          data: { open: true, message: res.message, status: res.status }
        });
        if (res.status === "success") {
          let newMaterials = materials.filter(material => {
            if (material.material_id !== id) {
              return material;
            }
          });
          setMaterials(newMaterials);
        }
      });
  }

  function deleteCategory(id) {
    fetch(Service.domain, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json"
      },
      body: JSON.stringify({
        controller: "category",
        action: "delete",
        payload: {
          category_id: id,
          api_token: accountData.api_token,
          account_type: accountData.account_type
        }
      })
    })
      .then(res => res.json())
      .then(res => {
        dispatch({
          type: "updateNotification",
          data: { open: true, message: res.message, status: res.status }
        });
        if (res.status === "success") {
          let newCategories = categories.filter(category => {
            if (category.category_id !== id) {
              return category;
            }
          });
          setCategories(newCategories);
        }
      });
  }

  function deleteBundle(id) {
    Service.delete(
      "bundle",
      "delete",
      {
        bundle_id: id,
        api_token: accountData.api_token,
        account_type: accountData.account_type
      },
      res => {
        dispatch({
          type: "updateNotification",
          data: { open: true, message: res.message, status: res.status }
        });
        if (res.status === "success") {
          let newBundles = bundles.filter(bundle => {
            if (bundle.bundle_id !== id) {
              return bundle;
            }
          });
          setBundles(newBundles);
        }
      }
    );
  }

  function deleteColor(id) {
    fetch(Service.domain, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json"
      },
      body: JSON.stringify({
        controller: "color",
        action: "delete",
        payload: {
          color_id: id,
          api_token: accountData.api_token,
          account_type: accountData.account_type
        }
      })
    })
      .then(res => res.json())
      .then(res => {
        dispatch({
          type: "updateNotification",
          data: { open: true, message: res.message, status: res.status }
        });
        if (res.status === "success") {
          let newColors = colors.filter(color => {
            if (color.color_id !== id) {
              return color;
            }
          });
          setColors(newColors);
        }
      });
  }

  function getAllMaterials() {
    fetch(Service.domain + "?controller=material&action=getAll")
      .then(res => res.json())
      .then(res => {
        if (res.status === "success") {
          setMaterials(res.data);
        }
      });
  }

  function getAllCategories() {
    fetch(Service.domain + "?controller=category&action=getAll")
      .then(res => res.json())
      .then(res => {
        if (res.status === "success") {
          setCategories(res.data);
        }
      });
  }

  function getAllColors() {
    fetch(Service.domain + "?controller=color&action=getAll")
      .then(res => res.json())
      .then(res => {
        if (res.status === "success") {
          setColors(res.data);
        }
      });
  }

  function getAllBundles() {
    Service.get("?controller=bundle&action=getAll", res =>
      setBundles(res.data)
    );
  }

  useEffect(() => {
    getAllMaterials();
    getAllCategories();
    getAllColors();
    getAllBundles();
  }, []);

  return (
    <div className="col--12 col--lrg--10">
      <div className="matcat__container">
        <section className="matcat__forms-container">
          <form
            className="matcat__form col--12 col--sml--6 col--xlrg--3"
            onSubmit={AddMaterial}
          >
            <div className="matcat__input-form">
              <h2 className="matcat__form-heading">Material</h2>
              <div className="field">
                <label className="label">Material Name</label>
                <input
                  className="input"
                  required
                  value={material_name}
                  onChange={e => setMaterialName(e.target.value)}
                  type="text"
                />
              </div>
              <div className="btn--confirm-container">
                <button className="btn--confirm" type="submit">
                  Add
                </button>
              </div>
            </div>
            <div className="matcat__items-list">
              <div className="matcat__color-list">
                {materials.map(material => (
                  <div
                    className="matcat__color-item"
                    key={material.material_id}
                  >
                    <i
                      className="matcat__icon fas fa-times"
                      onClick={() =>
                        dispatch({
                          type: "displayDeleteModal",
                          data: {
                            open: true,
                            func: deleteMaterial,
                            id: material.material_id,
                            message: message1
                          }
                        })
                      }
                    ></i>
                    <p className="matcat__color-name">
                      {material.material_name}
                    </p>
                  </div>
                ))}
              </div>
            </div>
          </form>
          <form
            className="matcat__form col--12 col--sml--6 col--xlrg--3"
            onSubmit={AddCategory}
          >
            <div className="matcat__input-form">
              <h2 className="matcat__form-heading">Category</h2>
              <div className="field">
                <label className="label">Category Name</label>
                <input
                  className="input"
                  required
                  value={category_name}
                  onChange={e => setCategoryName(e.target.value)}
                  type="text"
                />
              </div>
              <div className="btn--confirm-container">
                <button className="btn--confirm" type="submit">
                  Add
                </button>
              </div>
            </div>
            <div className="matcat__items-list">
              <div className="matcat__color-list">
                {categories.map(category => (
                  <div
                    className="matcat__color-item"
                    key={category.category_id}
                  >
                    <i
                      className="matcat__icon fas fa-times"
                      onClick={() =>
                        dispatch({
                          type: "displayDeleteModal",
                          data: {
                            open: true,
                            func: deleteCategory,
                            id: category.category_id,
                            message: message2
                          }
                        })
                      }
                    ></i>
                    <p className="matcat__color-name">
                      {category.category_name}
                    </p>
                  </div>
                ))}
              </div>
            </div>
          </form>
          <form
            className="matcat__form col--12 col--sml--6 col--xlrg--3"
            onSubmit={AddBundle}
          >
            <div className="matcat__input-form">
              <h2 className="matcat__form-heading">Bundle</h2>
              <div className="field">
                <label className="label">Bundle Name</label>
                <input
                  className="input"
                  required
                  value={bundle_name}
                  onChange={e => setBundleName(e.target.value)}
                  type="text"
                />
              </div>
              <div className="btn--confirm-container">
                <button className="btn--confirm" type="submit">
                  Add
                </button>
              </div>
            </div>
            <div className="matcat__items-list">
              <div className="matcat__color-list">
                {bundles.map(bundle => (
                  <div className="matcat__color-item" key={bundle.bundle_id}>
                    <i
                      className="matcat__icon fas fa-times"
                      onClick={() =>
                        dispatch({
                          type: "displayDeleteModal",
                          data: {
                            open: true,
                            func: deleteBundle,
                            id: bundle.bundle_id,
                            message: message4
                          }
                        })
                      }
                    ></i>
                    <p className="matcat__color-name">{bundle.bundle_name}</p>
                  </div>
                ))}
              </div>
            </div>
          </form>
          <form
            className="matcat__form col--12 col--sml--6 col--xlrg--3"
            onSubmit={AddColor}
          >
            <div className="matcat__input-form">
              <h2 className="matcat__form-heading">Color</h2>
              <div className="field">
                <label className="label">Color Name</label>
                <input
                  className="input"
                  required
                  value={color_name}
                  onChange={e => setColorName(e.target.value)}
                  type="text"
                />
              </div>
              <div className="field">
                <label className="label">Color Hex #</label>
                <input
                  className="input"
                  required
                  maxLength="6"
                  minLength="6"
                  value={color_hex}
                  onChange={e => setColorHex(e.target.value)}
                  type="text"
                />
              </div>
              <div className="btn--confirm-container">
                <button className="btn--confirm" type="submit">
                  Add
                </button>
              </div>
            </div>
            <div className="matcat__items-list">
              <div className="matcat__color-list">
                {colors.map(color => (
                  <div className="matcat__color-item" key={color.color_id}>
                    <i
                      className="matcat__icon fas fa-times"
                      onClick={() =>
                        dispatch({
                          type: "displayDeleteModal",
                          data: {
                            open: true,
                            func: deleteColor,
                            id: color.color_id,
                            message: message3
                          }
                        })
                      }
                    ></i>
                    <p className="matcat__color-name">{color.color_name}</p>
                    <div
                      className="matcat__color-square"
                      style={{ backgroundColor: color.color_hex }}
                    ></div>
                  </div>
                ))}
              </div>
            </div>
          </form>
        </section>
      </div>
    </div>
  );
}

export default AdminOptionsForm;
