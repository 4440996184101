/**
 * Calculate Order Cost
 *  - gets the total, subtotal, tax, and saved from
 *  cart products
 * @param {Array} products
 * @param {Object} accountData
 */
export function calculateCost(products, accountData) {
  const TaxPercentage = 0.06;
  let total = 0,
    subTotal = 0,
    tax = 0,
    saved = 0,
    retailTotal = 0;

  // loop through each product
  products.forEach(prod => {
    let dealerPrice, contractorPrice, retailPrice;

    // Calculate totals for each account type
    dealerPrice =
      prod.product_variant_dealer_price * prod.cart_product_quantity;
    contractorPrice =
      prod.product_variant_contractor_price * prod.cart_product_quantity;
    retailPrice =
      prod.product_variant_retail_price * prod.cart_product_quantity;
    retailTotal += retailPrice;

    // Calculate special pricing if it exists
    if (prod.product_variant_special_price) {
      total += prod.product_variant_special_price * prod.cart_product_quantity;
      return;
    }

    // Increment the total based on the account type
    if (accountData.account_type === "dealer") total += dealerPrice;
    if (accountData.account_type === "contractor") total += contractorPrice;
    if (
      accountData.account_type !== "dealer" &&
      accountData.account_type !== "contractor"
    ) {
      total += retailPrice;
    }
  });

  // Get the total before tax
  subTotal = total;

  // If the account is an admin or retail apply tax
  if (
    accountData.account_type !== "dealer" &&
    accountData.account_type !== "contractor"
  ) {
    tax = subTotal * TaxPercentage;
  }

  // Get grand total
  total = subTotal + tax;
  saved = retailTotal - subTotal;

  // Return an order object
  return {
    order_total: total,
    order_subtotal: subTotal,
    order_tax: tax,
    order_saved: saved
  };
}
