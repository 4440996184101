import { numCommas } from "../services/dates";
/**
 * Calculate Total for Cart Products
 * It takes into account account_type if special pricing is available
 * @param {object} account_type
 * @param {object} cartProducts
 */
export function calculateCartProductsTotal(cartProducts) {
  let total = 0;
  cartProducts.forEach(product => {
    total += product.product_total;
  });
  return total;
}
/**
 * Calculates the Total for Product
 * - Takes into account special pricing based on account_type
 * @param {object} account_type
 * @param {object} cartProduct
 */
export function calculateTotalProduct(account_type, cartProduct) {
  let total = 0;
  if (cartProduct.product_variant_special_price) {
    total +=
      cartProduct.product_variant_special_price *
      cartProduct.cart_product_quantity;
  } else if (account_type === "retail" || account_type === "admin") {
    total +=
      cartProduct.product_variant_retail_price *
      cartProduct.cart_product_quantity;
  } else if (account_type === "contractor") {
    total +=
      cartProduct.product_variant_contractor_price *
      cartProduct.cart_product_quantity;
  } else if (account_type === "dealer") {
    total +=
      cartProduct.product_variant_dealer_price *
      cartProduct.cart_product_quantity;
  }
  return total;
}
/**
 * Calculates the total retail price for cartProducts
 * @param {object} cartProducts
 */
export function calculateRetailProductsTotal(cartProducts) {
  let retailTotal = 0;
  cartProducts.forEach(product => {
    retailTotal +=
      product.product_variant_retail_price * product.cart_product_quantity;
  });
  return retailTotal;
}

export function attachProductTotalToProducts(account_type, cartProducts) {
  cartProducts.forEach(product => {
    product.product_total = calculateTotalProduct(account_type, product);
  });
  return cartProducts;
}
