import React, { useState, useEffect, useContext } from "react";
import { AppContext } from "../../../App";
import Service from "../../../services/service";
import { Link } from "react-router-dom";
import AdminAccountCard from "./childComp/AdminAccountCard";
import Loading from "../../reusable/notification/Loading";

function AdminAccounts({ history, location }) {
  const { accountData, dispatch } = useContext(AppContext);
  const [accounts, setAccounts] = useState([]);
  const [cachedAccounts, setCachedAccounts] = useState([]);
  const [showLoading, setShowLoading] = useState(true);

  useEffect(() => {
    console.log(
      Service.domain +
        "?controller=account&action=getAll&api_token=" +
        accountData.api_token +
        "&account_type=" +
        accountData.account_type
    );
    document.title = "View Accounts";
    getAccounts();
  }, []);

  function getAccounts() {
    fetch(
      Service.domain +
        "?controller=account&action=getAll&api_token=" +
        accountData.api_token +
        "&account_type=" +
        accountData.account_type
    )
      .then(res => res.json())
      .then(res => {
        if (res.status === "success") {
          setShowLoading(false);
          setAccounts(
            res.data.sort((acc1, acc2) => {
              if (
                acc1.account_company.toLowerCase() <
                acc2.account_company.toLowerCase()
              )
                return -1;
              if (
                acc1.account_company.toLowerCase() >
                acc2.account_company.toLowerCase()
              )
                return 1;

              return 0;
            })
          );
          setCachedAccounts(
            res.data.sort((acc1, acc2) => {
              if (
                acc1.account_company.toLowerCase() <
                acc2.account_company.toLowerCase()
              )
                return -1;
              if (
                acc1.account_company.toLowerCase() >
                acc2.account_company.toLowerCase()
              )
                return 1;

              return 0;
            })
          );
        }
      });
  }

  function deleteAccount(id) {
    fetch(Service.domain, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json"
      },
      body: JSON.stringify({
        controller: "account",
        action: "delete",
        payload: {
          account_id: id,
          account_type: accountData.account_type,
          api_token: accountData.api_token
        }
      })
    })
      .then(res => res.json())
      .then(res => {
        dispatch({
          type: "updateNotification",
          data: { open: true, status: res.status, message: res.message }
        });
        if (res.status === "success") {
          getAccounts();
        }
      });
  }

  function editAccount(e) {
    Service.get(
      "?controller=account&action=getOne&api_token=" +
        accountData.api_token +
        "&account_type=" +
        accountData.account_type +
        "&account_id=" +
        e.target.value,
      res => {
        if (res.status === "success") {
          dispatch({
            type: "updateCurrentAccount",
            data: { ...res.data, currentAccountEditing: true }
          });
          history.push("/admin-portal/admin-create-account");
        }
      }
    );
  }

  function filterAccounts(e) {
    if (e.target.value !== "") {
      const newAccounts = cachedAccounts.filter(account => {
        if (account.account_type === e.target.value) {
          return account;
        }
      });
      setAccounts(newAccounts);
    } else {
      setAccounts(cachedAccounts);
    }
  }

  function searchAccounts(e) {
    if (e.target.value !== "") {
      const quieriedAccounts = cachedAccounts.filter(account => {
        if (account.account_company.includes(e.target.value)) {
          return account;
        }
      });
      setAccounts(quieriedAccounts);
    } else {
      setAccounts(cachedAccounts);
    }
  }

  return (
    <main className="viewdistributors col--12 col--lrg--10">
      {/* <div className="banner__container col--12">
                <h1 className="adist__heading">ACCOUNTS</h1>
            </div>
            <nav className="viewdistprod__nav col--12">
                <Link to="/admin-portal" className="vdp"><i className="fas fa-home"></i> Portal</Link>
                <Link to="/admin-create-account" className="vdp"><i className="fas fa-plus"></i>Create</Link>
            </nav> */}
      <form className="viewdistributors__search-bar">
        <div className="field viewdistributors__search hide show-flex-at-smlmed">
          <label className="label" htmlFor="">
            Search:
          </label>
          <input
            type="search"
            onChange={searchAccounts}
            className="input viewdistributors__search-input"
          />
        </div>
        <div className="field viewdistributors__filter hide show-flex-at-smlmed">
          <label htmlFor="" className="label">
            Filter By:
          </label>
          <select name="" id="" className="input" onChange={filterAccounts}>
            <option value="">- Select -</option>
            <option value="retail">Retail</option>
            <option value="contractor">Contractor</option>
            <option value="dealer">Dealer</option>
            <option value="admin">Admin</option>
          </select>
        </div>
        <div className="viewdistributors__add-account">
          <Link
            className="btn--confirm"
            to="/admin-portal/admin-create-account"
          >
            <i className="fas fa-plus"></i>Create
          </Link>
        </div>
      </form>
      {showLoading ? <Loading /> : ""}
      {showLoading ? (
        ""
      ) : (
        <section className="viewdistributors__section-container col--12 col--sml--6 col--med--4">
          {accounts
            ? accounts.map(account => (
                <AdminAccountCard
                  key={account.account_id}
                  account={account}
                  edit={editAccount}
                  remove={deleteAccount}
                />
              ))
            : ""}
        </section>
      )}
    </main>
  );
}

export default AdminAccounts;
