import React, { useContext, useState, useEffect } from "react";
import Service from "../../../../services/service";
import { Link } from "react-router-dom";
import placeholderImg from "../../../../images/image-placeholder.jpg";
import { AppContext } from "../../../../App";
import { useDebounce } from "../../../../services/customHooks";

function CartProduct({ cartProduct }) {
  const { cart, dispatch, accountData } = useContext(AppContext);
  const [quantity, setQuantity] = useState(
    parseInt(cartProduct.cart_product_quantity)
  );
  const [price, setPrice] = useState();
  const quantityDebounce = useDebounce(quantity, 1000);

  useEffect(() => {
    if (cartProduct.cart_product_quantity !== quantity) {
      Service.put(
        "cart",
        "updateProduct",
        {
          cart_product_id: cartProduct.cart_product_id,
          cart_product_quantity: quantity,
          api_token: accountData.api_token,
        },
        (res) => {
          if (res.status === "success") {
            dispatch({ type: "updateCart", data: [...cart] });
          }
        }
      );
    }
  }, [quantityDebounce]);

  useEffect(() => {
    let tempPrice;
    if (cartProduct.product_variant_special_price) {
      setPrice(
        cartProduct.product_variant_special_price *
          cartProduct.cart_product_quantity
      );
    } else if (
      accountData.account_type === "retail" ||
      accountData.account_type === "admin"
    ) {
      tempPrice =
        cartProduct.product_variant_retail_price *
        cartProduct.cart_product_quantity;
      setPrice(tempPrice);
    } else if (accountData.account_type === "contractor") {
      tempPrice =
        cartProduct.product_variant_contractor_price *
        cartProduct.cart_product_quantity;
      setPrice(tempPrice);
    } else if (accountData.account_type === "dealer") {
      tempPrice =
        cartProduct.product_variant_dealer_price *
        cartProduct.cart_product_quantity;
      setPrice(tempPrice);
    }
  }, [cartProduct]);

  function updateQty(command) {
    let newQty;
    if (command === "increment") {
      newQty = quantity + 1;
      setQuantity(newQty);
      updateCart(newQty);
    } else if (command === "decrement" && quantity > 1) {
      newQty = quantity - 1;
      setQuantity(newQty);
      updateCart(newQty);
    }
  }

  function manualQty(e) {
    let manualQty = parseInt(e.target.value);
    if (!manualQty) manualQty = 1;
    setQuantity(manualQty);
    updateCart(manualQty);
  }

  function updateCart(quantity) {
    let tempPrice;
    if (cartProduct.product_variant_special_price) {
      setPrice(cartProduct.product_variant_special_price * quantity);
    } else if (
      accountData.account_type === "retail" ||
      accountData.account_type === "admin"
    ) {
      tempPrice = cartProduct.product_variant_retail_price * quantity;
      setPrice(tempPrice);
    } else if (accountData.account_type === "contractor") {
      tempPrice = cartProduct.product_variant_contractor_price * quantity;
      setPrice(tempPrice);
    } else if (accountData.account_type === "dealer") {
      tempPrice = cartProduct.product_variant_dealer_price * quantity;
      setPrice(tempPrice);
    }
  }

  function deleteFromCart() {
    Service.delete(
      "cart",
      "removeProduct",
      {
        cart_product_id: cartProduct.cart_product_id,
        api_token: accountData.api_token,
      },
      (res) => {
        if (res.status === "success") {
          Service.get(
            "?controller=cart&action=getCart&account_id=" +
              accountData.account_id +
              "&api_token=" +
              accountData.api_token,
            (res) => {
              if (res.status === "success") {
                dispatch({ type: "updateCart", data: res.data });
              }
            }
          );
        }
      }
    );
  }

  function numCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  const getImage = () => {
    try {
      return cartProduct.images
        ? Service.src + cartProduct.images[0].image_path
        : placeholderImg;
    } catch {
      return placeholderImg;
    }
  };

  return (
    <div className="cart-product">
      <div className="cart-product__body">
        <img
          src={getImage()}
          alt={cartProduct.product_name + " image"}
          className="cart-product__image"
        />
        <div className="cart-product__details">
          <div className="cart-product__info">
            <Link
              className="cart-product__product-title-link"
              to={"/product/" + cartProduct.product_address}
            >
              <h3 className="cart-product__title">
                {Service.htmlDecode(cartProduct.product_name) + " "}
                <span className="cart-product__subtitle cart-product__material">
                  {Service.htmlDecode(cartProduct.material_name) + " "}
                </span>
              </h3>
              <div className="cart-product__subtitles">
                <span className="cart-product__subtitle">{`${Service.htmlDecode(
                  cartProduct.product_dimension
                )}`}</span>
                <span className="cart-product__subtitle">{`${
                  cartProduct.product_type
                    ? " " + Service.htmlDecode(cartProduct.product_type)
                    : ""
                }`}</span>
                <span className="cart-product__subtitle">{`${
                  cartProduct.color_name !== cartProduct.material_name
                    ? ", " + cartProduct.color_name
                    : ""
                }`}</span>
                <span className="cart-product__subtitle">{`${
                  cartProduct.product_variant_thickness
                    ? ", " + cartProduct.product_variant_thickness
                    : ""
                }`}</span>
              </div>
            </Link>
            <div className="cart-product__quantity">
              <div className="cart-product__field">
                <div
                  className="cart-product__qty-button"
                  onClick={() => updateQty("decrement")}
                >
                  <i className="fas fa-minus cart-product__qty-icon"></i>
                </div>
                <input
                  type="text"
                  min="1"
                  required
                  onChange={manualQty}
                  onClick={(e) => Service.selectInputContents(e.target)}
                  value={quantity}
                  className="input cart-product__input"
                />
                <div
                  className="cart-product__qty-button"
                  onClick={() => updateQty("increment")}
                >
                  <i className="fas fa-plus cart-product__qty-icon"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <footer className="cart-product__footer">
        <section className="cart-product__section-btn-cat">
          <button
            title={`Remove all ${cartProduct.color_name} ${cartProduct.product_name} from cart`}
            type="button"
            onClick={deleteFromCart}
            className="btn--danger cart-product__remove-btn"
          >
            Remove
          </button>
        </section>
        <span className="cart-product__footer-price">
          ${numCommas(parseFloat(price).toFixed(2))}
        </span>
      </footer>
    </div>
  );
}

export default CartProduct;
