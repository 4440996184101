import React, { useState, useContext } from "react";
import Service from "../../../../services/service";
import { AppContext } from "../../../../App";

function AccountAddLocation() {
  const [address, setAddress] = useState({
    address_street: "",
    address_city: "",
    address_zip: "",
    address_state: "",
    address_id: ""
  });
  const { accountData, dispatch, addresses } = useContext(AppContext);
  function updateAddress(e) {
    setAddress({ ...address, [e.target.name]: e.target.value });
  }
  function addAddress(e) {
    e.preventDefault();
    Service.post(
      "address",
      "create",
      {
        address_street: address.address_street,
        address_city: address.address_city,
        address_zip: address.address_zip,
        address_state: address.address_state,
        account_id: accountData.account_id,
        api_token: accountData.api_token
      },
      res => {
        if (res.status === "success") {
          dispatch({
            type: "updateAddresses",
            data: [...addresses, { ...address, address_id: res.status.id }]
          });

          setAddress({
            address_street: "",
            address_city: "",
            address_zip: "",
            address_state: "",
            address_id: ""
          });
          dispatch({
            type: "updateNotification",
            data: { open: true, message: res.message, status: res.status }
          });
        }
      }
    );
  }
  return (
    <div className="account-add-location">
      <h3>Add Location</h3>
      <form onSubmit={addAddress} className="form--grid">
        <div className="field col--12">
          <label htmlFor="" className="label">
            Street Address
          </label>
          <input
            required
            onChange={updateAddress}
            value={address.address_street}
            type="text"
            name="address_street"
            className="input full"
          />
        </div>
        <div className="field col--12 col--sml--4">
          <label htmlFor="" className="label">
            City
          </label>
          <input
            required
            onChange={updateAddress}
            value={address.address_city}
            type="text"
            name="address_city"
            className="input full"
          />
        </div>
        <div className="field col--12 col--sml--4">
          <label htmlFor="" className="label">
            State
          </label>
          <select
            onChange={updateAddress}
            required
            value={address.address_state}
            type="text"
            name="address_state"
            className="input full"
          >
            <option value="">- Select -</option>
            <option value="idaho">Idaho</option>
            <option value="wyoming">Wyoming</option>
            <option value="montana">Montana</option>
            <option value="utah">Utah</option>
          </select>
        </div>
        <div className="field col--12 col--sml--4">
          <label htmlFor="" className="label">
            Zipcode
          </label>
          <input
            onChange={updateAddress}
            required
            value={address.address_zip}
            type="text"
            name="address_zip"
            className="input full"
          />
        </div>
        <div className="field col--12">
          <button type="submit" className="btn--confirm">
            Add
          </button>
        </div>
      </form>
    </div>
  );
}

export default AccountAddLocation;
