import React, { useContext } from 'react';
import { AppContext } from '../../../../App';
import Service from '../../../../services/service';

function AdminAccountCard({ account, edit, remove }) {

    const { dispatch } = useContext(AppContext);
    const filter = Service.htmlDecode;

    return (
        <div className="account">
            <div className={`account__div-type--${account.account_type}`}>{account.account_type}</div>
            <h3 className="account__h3">{filter(account.account_company)}</h3>
            <div className="account__div-email"><strong>Email:</strong> {account.account_email}</div>
            <div className="account__div-phone"><strong>Phone:</strong> {account.account_phone}</div>
            <div className="account__div-container">
                <button className="btn--confirm" type="button" value={account.account_id} onClick={edit}>Edit</button>
                <button className="btn--danger" type="button" value={account.account_id} onClick={() => dispatch({ type: "displayDeleteModal", data: { func: remove, open: true, id: account.account_id } })}>Delete</button>
            </div>
        </div>
    );
}

export default AdminAccountCard;
