import React, { useEffect, useState, useContext } from "react";
import imagePlaceholder from "../../../../images/image-placeholder.jpg";
import Service from "../../../../services/service";
import { AppContext } from "../../../../App";
import Images from "../../../../services/images";

function AdminProductImage({
  id,
  product = {},
  uploadImage,
  uploadProductImage
}) {
  const [imageMarkup, setImageMarkup] = useState(imagePlaceholder);
  const { dispatch, accountData } = useContext(AppContext);
  const [image, setImage] = useState("");

  useEffect(() => {
    console.log(product);
    if (product.product_id === "" || product.product_image === "") {
      setImage("");
    }
  }, []);

  useEffect(() => {
    if (product.product_id !== "" && product.product_id) {
      setImage(product.image_path);
    }
  }, [product]);

  console.log(product);

  return (
    <div className="admin-product-image">
      <img
        src={image ? Service.src + image : imagePlaceholder}
        alt="no image"
        className="admin-product-image-main"
      />
      {id === "mainImageExplorer" ? (
        <small className="admin-product-image__sub-message">
          Schematic Only
        </small>
      ) : (
        ""
      )}
      <div className="admin-product-image__button-cont">
        {/* <label className="admin-product-image__button btn--confirm" htmlFor={id}>Browse</label> */}
        {product.product_id ? (
          <input
            className="admin-product-image__input"
            size="1200"
            type="file"
            onChange={e => {
              let newImage = e.target.files;
              Service.delete(
                "image",
                "delete",
                {
                  image_id: product.image_id,
                  api_token: accountData.api_token
                },
                res => {
                  if (res.status === "success") {
                    uploadProductImage(newImage, res => {
                      // Service.get("?controller=image&action=getOne&image_id=" + res.data.id, res => {
                      //     setImage(res.data.image_path);
                      // });
                      Service.get(
                        "?controller=product&action=getOne&product_id=" +
                          product.product_id,
                        res => {
                          dispatch({
                            type: "updateCurrentProduct",
                            data: res.data
                          });
                          setImage(res.data.image_path);
                        }
                      );
                    });
                  }
                }
              );
            }}
          />
        ) : (
          <input
            className="admin-product-image__input"
            type="file"
            id={id}
            required
            onChange={e => {
              uploadImage(e.target.files, res => {
                if (res.status === "success") {
                  Images.uploadImage(res.data.id, accountData.api_token);
                  Service.get(
                    "?controller=image&action=getOne&image_id=" + res.data.id,
                    res => {
                      setImage(res.data.image_path);
                    }
                  );
                }
              });
            }}
          />
        )}

        {/* <button className="admin-product-image__button btn--danger" type="button" onClick={() => setImage("")}>Delete</button> */}
      </div>
    </div>
  );
}

export default AdminProductImage;
