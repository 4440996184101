export default class Service {
  // static domain = process.env.REACT_APP_ALPHA_DOMAIN;
  // static src = process.env.REACT_APP_ALPHA_SRC;
  // Local
  // static domain = "http://site1/server.php";
  // static src = "http://site1/";
  // static domain = "https://staging.flatratemetal.com/server.php";
  // static src = "https://staging.flatratemetal.com";
  static domain = "/server.php";
  static src = "";
  static htmlDecode(input) {
    let e = document.createElement("div");
    e.innerHTML = input;
    return e.childNodes.length === 0 ? "" : e.childNodes[0].nodeValue;
  }
  static post(controller, action, payload, callback = () => {}) {
    fetch(Service.domain, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        controller: controller,
        action: action,
        payload: payload,
      }),
    })
      .then((res) => res.json())
      .then((res) => callback(res));
  }
  static put(controller, action, payload, callback = () => {}) {
    fetch(Service.domain, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        controller: controller,
        action: action,
        payload: payload,
      }),
    })
      .then((res) => res.json())
      .then((res) => callback(res));
  }
  static delete(controller, action, payload, callback = () => {}) {
    fetch(Service.domain, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        controller: controller,
        action: action,
        payload: payload,
      }),
    })
      .then((res) => res.json())
      .then((res) => callback(res));
  }
  static get(queryString, callback) {
    fetch(Service.domain + queryString)
      .then((res) => res.json())
      .then((res) => callback(res));
  }
  static createId(length = 7) {
    let id = "";
    let possibleCharacters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    for (let i = 0; i < length; i++) {
      id += possibleCharacters.charAt(
        Math.floor(Math.random() * possibleCharacters.length)
      );
    }
    return id;
  }
  static minDate(days = 1) {
    let currentDate = new Date();
    let newDate = new Date(currentDate.setDate(currentDate.getDate() + days));
    newDate = newDate.toISOString();
    let index = newDate.indexOf("T");
    return newDate.slice(0, index);
  }
  static htmlEntities(str) {
    return String(str)
      .replace(/&/g, "&amp;")
      .replace(/</g, "&lt;")
      .replace(/>/g, "&gt;")
      .replace(/"/g, "&quot;");
  }
  // Only works with text inputs
  static selectInputContents(input) {
    input.focus();
    input.setSelectionRange(0, 99999);
  }
}
