import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../../../App";
import Service from "../../../services/service";
import { convertDBDate } from "../../../services/dates";
import Helmet from "react-helmet";
import { usePageView } from "../../../services/customHooks";

function OrderConfirmation({ location }) {
  const { accountData, lastPurchase, order, dispatch } = useContext(AppContext);
  const [cartProducts, setCartProducts] = useState([]);
  const [total, setTotal] = useState(0);
  const [address, setAddress] = useState({
    address_street: "",
    address_city: "",
    address_state: "",
    address_zip: ""
  });

  // Convert date string ie yyyy/11/18 to more readable date
  // used for displaying dates to confirmation screen
  const [date, setDate] = useState();
  useEffect(() => {
    if (order.order_type == "pickup") {
      setDate(convertDBDate(order.order_pickup_date));
    } else {
      setDate(convertDBDate(order.order_delivery_date));
    }
  });

  usePageView(location.pathname);
  useEffect(() => {
    document.title = "Checkout Confirmation";
    Service.get(
      "?controller=cart&action=getAll&account_id=" +
        accountData.account_id +
        "&api_token=" +
        accountData.api_token,
      res => {
        if (res.status === "success") {
          setCartProducts(res.data);
          Service.delete(
            "cart",
            "clear",
            {
              account_id: accountData.account_id,
              api_token: accountData.api_token
            },
            res => {
              if (res.status === "success") {
                dispatch({ type: "updateCart", data: [] });
              }
            }
          );
        }
      }
    );
    if (order.address_id) {
      Service.get(
        "?controller=address&action=getOne&account_id=" +
          accountData.account_id +
          "&api_token=" +
          accountData.api_token +
          "&address_id=" +
          order.address_id,
        res => {
          setAddress(res.data);
        }
      );
    }
    /**
     * Once this component has been closed we want to erase all traces of the
     * order from local state and the database.
     */
    return () => {
      dispatch({ type: "clearOrder" });
    };
  }, []);

  useEffect(() => {
    switch (accountData.account_type) {
      case "dealer":
        let dealerTotal = 0;
        cartProducts.forEach(product => {
          if (product.product_variant_special_price) {
            dealerTotal +=
              product.product_variant_special_price *
              product.cart_product_quantity;
          } else {
            dealerTotal +=
              product.product_variant_dealer_price *
              product.cart_product_quantity;
          }
        });
        setTotal(numCommas(parseFloat(dealerTotal).toFixed(2)));
        break;
      case "contractor":
        let contractorTotal = 0;
        cartProducts.forEach(product => {
          if (product.product_variant_special_price) {
            contractorTotal +=
              product.product_variant_special_price *
              product.cart_product_quantity;
          } else {
            contractorTotal +=
              product.product_variant_contractor_price *
              product.cart_product_quantity;
          }
        });
        setTotal(numCommas(parseFloat(contractorTotal).toFixed(2)));
        break;
      default:
        let retailTotal = 0;
        cartProducts.forEach(product => {
          if (product.product_variant_special_price) {
            retailTotal +=
              product.product_variant_special_price *
              product.cart_product_quantity;
          } else {
            retailTotal +=
              product.product_variant_retail_price *
              product.cart_product_quantity;
          }
        });
        setTotal(numCommas(parseFloat(retailTotal).toFixed(2)));
        break;
    }
  }, [cartProducts]);

  function productTotal(product) {
    switch (accountData.account_type) {
      case "dealer":
        let dealerTotal = 0;
        if (product.product_variant_special_price) {
          dealerTotal +=
            product.product_variant_special_price *
            product.cart_product_quantity;
        } else {
          dealerTotal +=
            product.product_variant_dealer_price *
            product.cart_product_quantity;
        }
        return numCommas(parseFloat(dealerTotal).toFixed(2));
        break;
      case "contractor":
        let contractorTotal = 0;
        if (product.product_variant_special_price) {
          contractorTotal +=
            product.product_variant_special_price *
            product.cart_product_quantity;
        } else {
          contractorTotal +=
            product.product_variant_contractor_price *
            product.cart_product_quantity;
        }
        return numCommas(parseFloat(contractorTotal).toFixed(2));
        break;
      default:
        let retailTotal = 0;
        if (product.product_variant_special_price) {
          retailTotal +=
            product.product_variant_special_price *
            product.cart_product_quantity;
        } else {
          retailTotal +=
            product.product_variant_retail_price *
            product.cart_product_quantity;
        }
        return numCommas(parseFloat(retailTotal).toFixed(2));
        break;
    }
  }

  function numCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  return (
    <main className="conf">
      <Helmet>
        <title>Order Confirmation | Flat Rate Metal</title>
      </Helmet>
      <div className="conf__cont">
        {/* TITLE SECTION */}
        <div className="conf__title-cont">
          <h1 className="conf__title">Order Confirmation</h1>
          <p className="conf__sub-title">
            Thank you,{" "}
            <span className="conf__account-detail">
              {Service.htmlDecode(accountData.account_company)}
            </span>
            ! Your order has been placed. An email copy of this order was sent
            to{" "}
            <span className="conf__account-detail">
              {accountData.account_email}
            </span>{" "}
            for your convenience.
          </p>
        </div>
        <h2 className="conf__order-title">Order Details</h2>
        {/* ORDER SECTION */}
        <div className="conf__order-detail-cont">
          {/* ACCOUNT SECTION */}
          <div className="conf__account-product-cont">
            <div className="conf__account-info-cont">
              <h3 className="conf__account-title">Account</h3>
              <ul className="conf__account-list">
                <li className="conf__account-list-item">
                  <span className="conf__account-list-item-title">
                    Company:{" "}
                  </span>
                  {Service.htmlDecode(accountData.account_company)}
                </li>
                <li className="conf__account-list-item">
                  <span className="conf__account-list-item-title">Email: </span>
                  {accountData.account_email}
                </li>

                {accountData.account_phone ? (
                  <li className="conf__account-list-item">
                    <span className="conf__account-list-item-title">
                      Phone:{" "}
                    </span>
                    {accountData.account_phone}
                  </li>
                ) : (
                  ""
                )}

                {accountData.account_type !== "retail" ? (
                  <li className="conf__account-list-item">
                    <span className="conf__account-list-item-title">
                      Account Type:{" "}
                    </span>
                    {accountData.account_type}
                  </li>
                ) : (
                  ""
                )}
                <li className="conf__account-list-item">
                  <span className="conf__account-list-item-title">PO: </span>
                  {order.order_po}
                </li>
                <li className="conf__account-list-item">
                  <span className="conf__account-list-item-title">
                    Order Placed:{" "}
                  </span>
                  {new Date().toDateString()}
                </li>
              </ul>
            </div>
            {/* PRODUCT SECTION */}
            <div className="conf__product-cont">
              <h3 className="conf__product-title">Product List</h3>
              {cartProducts.map((product, index) => (
                <div key={index}>
                  <ul className="conf__product-list">
                    <li className="conf__product-list-item">
                      <strong>
                        {product.product_name} {product.material_name}{" "}
                        {Service.htmlDecode(product.product_dimension)}{" "}
                        {Service.htmlDecode(product.product_type)}{" "}
                        {product.color_name !== product.material_name
                          ? product.color_name
                          : ""}{" "}
                        {product.product_variant_thickness
                          ? product.product_variant_thickness
                          : ""}
                      </strong>
                    </li>
                    <li className="conf__product-list-item">
                      {product.cart_product_quantity +
                        " " +
                        product.bundle_name +
                        "(s)"}
                    </li>
                    <li className="conf__product-list-item">
                      ${productTotal(product)}
                    </li>
                  </ul>
                </div>
              ))}
            </div>
            {/* FEEDBACK SECTION */}
            {order.order_feedback ? (
              <>
                <div className="conf__feedback-cont">
                  <h3 className="conf__feedback-title">Feedback</h3>
                  <p className="conf__feedback">{order.order_feedback}</p>
                </div>
              </>
            ) : (
              ""
            )}
          </div>
          <div className="conf__receipt-price-cont">
            {/* RECEIPT TYPE SECTION */}
            <div className="conf__receipt-container">
              <h3 className="conf__receipt-title">Receipt Type</h3>
              <ul className="conf__receipt-list">
                <li className="conf__receipt-list-item">
                  <span className="conf__receipt-list-item-title">
                    Method:{" "}
                  </span>
                  {order.order_type === "pickup" ? "Pickup" : "Deliver"}
                </li>
                {order.order_type === "pickup" ? (
                  <>
                    <li className="conf__receipt-list-item">
                      <span className="conf__receipt-list-item-title">
                        Pickup Available:{" "}
                      </span>
                      {date}
                    </li>
                  </>
                ) : (
                  <>
                    <li className="conf__receipt-list-item">
                      <span className="conf__receipt-list-item-title">
                        Delivery By:{" "}
                      </span>
                      {date}
                    </li>
                    <li className="conf__receipt-list-item">
                      <span className="conf__receipt-list-item-title">
                        Street:{" "}
                      </span>
                      {address.address_street}
                    </li>
                    <li
                      className="conf__receipt-list-item"
                      style={{ textTransform: "capitalize" }}
                    >
                      <span className="conf__receipt-list-item-title">
                        City / State / Zip:{" "}
                      </span>
                      {address.address_city}, {address.address_state}{" "}
                      {address.address_zip}
                    </li>
                  </>
                )}
              </ul>
            </div>
            {/* PRICE SECTION */}
            <div className="conf__price-cont">
              <h3 className="conf__price-title">Price</h3>
              <ul className="conf__price-list">
                {/* <li className="conf__price-list-item"><span className="conf__price-list-item-title">Subtotal: </span>${numCommas(parseFloat(purchase.subTotal).toFixed(2))}</li>
                                {
                                    accountData.account_type === 'dealer' || accountData.account_type === 'contractor'
                                        ?
                                        <li className="conf__price-list-item"><span className="conf__price-list-item-title special">Saved: </span>${numCommas(parseFloat(purchase.savedTotal).toFixed(2))}</li>
                                        :
                                        ""
                                } */}
                {accountData.account_type === "retail" ? (
                  <div>
                    <li className="conf__price-list-item">
                      <span className="conf__price-list-item-title">Tax: </span>
                      ${numCommas(parseFloat(order.order_tax).toFixed(2))}
                    </li>
                    <li className="conf__price-list-item">
                      <span className="conf__price-list-item-title">
                        Sub-Total:{" "}
                      </span>
                      ${numCommas(parseFloat(order.order_subtotal).toFixed(2))}
                    </li>
                  </div>
                ) : (
                  ""
                )}
                <li className="conf__price-list-item conf__total">
                  <span className="conf__price-list-item-title">Total: </span>$
                  {numCommas(parseFloat(order.order_total).toFixed(2))}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}

export default OrderConfirmation;
