import React, { useContext, useEffect, useState } from "react";
import placeholderImg from "../../../../images/image-placeholder.jpg";
import { AppContext } from "../../../../App";
import Service from "../../../../services/service";

function ImageZoom({ imgSrc, setZoomIn }) {
  return (
    <div onClick={() => setZoomIn(false)} className="product-zoomed-div">
      <div className="product-zoomed__header">
        <span class="closeButton">&times;</span>
      </div>
      <img src={imgSrc} className="product-zoomed-image" />
    </div>
  );
}

function ProductDetailImage() {
  const { currentProduct, currentProductVariant, accountData } = useContext(
    AppContext
  );
  const [imageIndex, setImageIndex] = useState(0);
  const [imageArray, setImageArray] = useState([]);
  const [zoomIn, setZoomIn] = useState(false);

  useEffect(() => {
    console.log(currentProductVariant);
    // let arr = [...currentProductVariant.images, {image_path: currentProduct.image_path}];
    setImageArray(
      currentProductVariant.images
        ? [
            ...currentProductVariant.images,
            { image_path: currentProduct.image_path }
          ]
        : []
    );
    // console.log(arr);
    replaceMainImage(0);
  }, [currentProductVariant]);

  function replaceMainImage(index) {
    console.log(index);
    setImageIndex(index);
  }

  return (
    <>
      {zoomIn ? (
        <ImageZoom
          imgSrc={Service.src + imageArray[imageIndex].image_path}
          setZoomIn={setZoomIn}
        />
      ) : (
        ""
      )}
      {imageArray[imageIndex] ? (
        <div className="product-detail-image">
          <div className="product-detail-image__container">
            <img
              alt={`${currentProduct.product_name} ${currentProductVariant.color_name}`}
              src={Service.src + imageArray[imageIndex].image_path}
              className="product-detail-image__main-image"
              onClick={() => setZoomIn(true)}
            />
            <div className="product-detail-image__color">
              {currentProductVariant.color_name}
            </div>
            <div className="product-detail-image__sub-images-container">
              {imageArray.map((image, index) => (
                <img
                  key={index}
                  alt={`${currentProduct.product_name} ${currentProductVariant.color_name}`}
                  src={Service.src + image.image_path}
                  className="product-detail-image__sub-image"
                  onMouseEnter={() => replaceMainImage(index)}
                />
              ))}
            </div>
          </div>
        </div>
      ) : (
        <div className="product-detail-image">
          <div className="product-detail-image__container">
            <img
              alt={`${currentProduct.product_name} ${currentProductVariant.color_name}`}
              src={placeholderImg}
              className="product-detail-image__main-image"
            />
            <div className="product-detail-image__sub-images-container">
              <img
                alt={`${currentProduct.product_name} ${currentProductVariant.color_name}`}
                src={placeholderImg}
                className="product-detail-image__sub-image"
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default ProductDetailImage;
