import React from "react";
import ProductCategoryGroup from "./ProductCategoryGroup";
import Loading from "../../../reusable/notification/Loading";

function ProductList({ products, categories, showLoading }) {
  return (
    <>
      {showLoading ? <Loading /> : ""}
      {showLoading ? (
        ""
      ) : (
        <section className="productlist">
          {categories.map((category, index) => (
            <ProductCategoryGroup
              category={category}
              categories={categories}
              products={products}
              key={index}
            />
          ))}
        </section>
      )}
    </>
  );
}

export default ProductList;
